import React, { forwardRef, memo, useCallback, useImperativeHandle, useMemo, useState } from "react";

import PropTypes from "prop-types";
import { Modal } from "@mui/material";

import AppIcon from "components/app-icon";
import AppButton from "components/app-button";

import closeIcon from "assets/images/close-icon.png";

const AppWorkInspectionConfirmDeleteMemberModal = (props, ref) => {
	const onHandleRemoveTeamMember = useMemo(() => props.onHandleRemoveTeamMember, [props.onHandleRemoveTeamMember]);
	const [visible, setVisible] = useState(false);
	const [data, setData] = useState(null);

	const onHandleConfirm = useCallback(async () => {
		onHandleRemoveTeamMember(data);

		setVisible(false);
	}, [data, onHandleRemoveTeamMember]);

	const onHandleShow = useCallback((values) => {
		setData(values);

		setVisible(true);
	}, []);

	const onHandleDismiss = useCallback(() => {
		setVisible(false);
	}, []);

	useImperativeHandle(ref, () => ({
		onHandleShow: onHandleShow,
		onHandleDismiss: onHandleDismiss
	}));

	return (
		<Modal classes={{ root: "app-work-inspection-confirm-delete-member-modal" }} open={visible}>
			<div className="work-inspection-confirm-delete-member-modal">
				<button type="button" className="work-inspection-confirm-delete-member-modal__close" onClick={onHandleDismiss}>
					<AppIcon src={closeIcon} />
				</button>

				<h1 className="work-inspection-confirm-delete-member-modal__title">Are you sure?</h1>

				<p className="work-inspection-confirm-delete-member-modal__description">
					After clicking confirm, the Team Member <b>{data?.personalInfo?.fullName}</b> will be removed from this Work Inspection Report.
				</p>

				<div className="work-inspection-confirm-delete-member-modal__button-container">
					<AppButton outline type="button" label="Cancel" onClick={onHandleDismiss} />

					<AppButton type="button" label="Confirm" onClick={onHandleConfirm} />
				</div>
			</div>
		</Modal>
	);
};

export default memo(forwardRef(AppWorkInspectionConfirmDeleteMemberModal));

AppWorkInspectionConfirmDeleteMemberModal.propTypes = {
	ref: PropTypes.object.isRequired
};
