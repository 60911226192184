import React, { forwardRef, memo, useCallback, useContext, useEffect, useImperativeHandle, useMemo, useRef, useState } from "react";

import * as yup from "yup";
import { useDispatch } from "react-redux";
import { useBeforeUnload, useParams } from "react-router-dom";
import { AxiosContext } from "contexts/with-interceptor-provider";
import { FieldArray, FormikProvider, setNestedObjectValues, useFormik } from "formik";

import api from "services/api";
import getSparePartListing from "services/get-spare-part-listing";

import { promptLayoutAlertMessage } from "store/slices/layout-alert";

import validateFileSize from "common/validate-file-size";
import { serveLayoutRequestErrors } from "common/serve-request-errors";

import PAGE from "constants/page";
import ERRORS from "constants/errors";
import STATUS from "constants/status";
import ENDPOINT_PATH from "constants/end-point-path";
import WORK_ORDER_ATTACHMENT_TYPE from "constants/work-order-attachment-type";

import AppInput from "components/app-input";
import AppButton from "components/app-button";
import AppSelectInput from "components/app-select-input";
import AppInputDragAndDrop from "components/app-input-drag-and-drop";

import trashIcon from "assets/images/trash-icon.png";
import addIcon from "assets/images/blue-add-icon.svg";
import chevronUpIcon from "assets/images/chevron-up-icon.png";
import chevronDownIcon from "assets/images/chevron-down-grey-icon.png";

import AppWorkOrderHoldOnModal from "./app-work-order-hold-on-modal";
import AppWorkOrderConfirmDeleteRecordModal from "./app-work-order-confirm-delete-record-modal";

const AppWorkOrderWorkDetails = (props, ref) => {
	useBeforeUnload();
	const { id } = useParams();
	const cancelRequest = useContext(AxiosContext).onHandleCancelRequest;
	const dispatch = useDispatch();
	const isCreate = useMemo(() => id === PAGE.CREATE, [id]);
	const [collapsedIndices, setCollapsedIndices] = useState(new Set());
	const confirmDeleteRecordRef = useRef();
	const holdOnModalRef = useRef();
	const assetId = useMemo(() => props.assetId || "", [props.assetId]);
	const status = useMemo(() => props.status, [props.status]);
	const isEditable = useMemo(() => status === STATUS.DRAFT || status === STATUS.PENDING_ASSIGN || status === STATUS.PENDING_ASSET || status === STATUS.OPEN, [status]);

	//prettier-ignore
	const initialValues = useMemo(() => ({
		workOrderReportIds: [],
		records: []
	}), []);

	const formik = useFormik({
		initialValues: initialValues,
		validationSchema: yup.object({
			records: yup.array().of(
				yup.object({
					configSparePart: yup.object({
						id: yup.string().required(ERRORS.REQUIRED)
					}),
					workScope: yup.string().required(ERRORS.REQUIRED),
					attachment: yup.mixed().nullable().test("fileSize", ERRORS.FILE_SIZE, validateFileSize)
				})
			)
		})
	});

	const memoSetFieldValue = useMemo(() => formik.setFieldValue, [formik.setFieldValue]);

	const onHandleCheckFormikDirty = useCallback(() => {
		return formik.dirty;
	}, [formik]);

	const onHandleLeavePageModal = useCallback(() => {
		holdOnModalRef.current.onHandleShow();
	}, []);

	//prettier-ignore
	const onHandleNewRecord = useCallback(async (renderProps, type) => {
		if (formik.values[type].length === 10) return;

		const fieldErrors = await renderProps.form.validateForm();
		const fields = structuredClone(formik.values[type]);

		if (fieldErrors.records) {
			renderProps.form.setTouched(setNestedObjectValues(fieldErrors, true));

			return;
		}

		if (fields.length === 0) {
			formik.setFieldValue(type, [{ newField: true, configSparePart: { id: "", referenceNo: "", name: "" }, workScope: "", workDescription: "", caption: "", workOrderAssetRecordAttachment: [] }]);
		} else {
			const field = { newField: true, configSparePart: { id: "", referenceNo: "", name: "" }, workScope: "", workDescription: "", caption: "", workOrderAssetRecordAttachment: [] };

			fields.push(field);

			formik.setFieldValue(type, fields);
		}

		setCollapsedIndices(new Set());
	}, [formik]);

	//prettier-ignore
	const onConfirmDeleteRecord = useCallback(async (type, data) => {
		if (isCreate || !data.record.id) {
			const nextFields = [...formik.values[type]].filter((_, i) => i !== data.index);

			formik.setFieldValue(type, nextFields);
		}

		if (!isCreate && data.record.id) {
			let response = null;

			try {
				await api.post.workOrder.deleteRecord({ "record-id": data.record.id });

				response = true;
			} catch (error) {
				serveLayoutRequestErrors(error);
			}

			if (response) {
				const nextFields = [...formik.values[type]].filter((_, i) => i !== data.index);

				formik.setFieldValue(type, nextFields);

				dispatch(promptLayoutAlertMessage({ message: "Record was deleted successfully!" }));
			}
		}
	}, [isCreate, formik, dispatch]);

	//prettier-ignore
	const onHandleGetRecordIds = useCallback(async (assetId) => {
		let response = null;

		try {
			response = await api.get.workOrder.recordIds({ "work-order-asset-id": assetId });
		} catch (error) {
			serveLayoutRequestErrors(error);
		}

		if (response) {
			setCollapsedIndices((prev) => {
				const newCollapsedIndices = new Set(prev);

				response.map((o, i) => newCollapsedIndices.add(i));

				return newCollapsedIndices;
			});

			memoSetFieldValue("workOrderReportIds", response);
			memoSetFieldValue("records", response.map((o) => ({ id: o })));
		}
	}, [memoSetFieldValue]);

	//prettier-ignore
	const onHandleViewRecord = useCallback(async (index) => {
		const recordsCopy = structuredClone(formik.values.records);
		const finding = recordsCopy[index];
		const workOrderReportIdsCopy = structuredClone(formik.values.workOrderReportIds);
		const recordId = workOrderReportIdsCopy[index];

		if (!Object.keys(finding).length || !recordId) return;		

		let response = null;

		try {
			response = await api.get.workOrder.viewRecord({ "record-id": recordId });
		} catch (error) {
			serveLayoutRequestErrors(error);
		}

		if (response) {
			recordsCopy[index] = response;

			const beforeRecords = response.workOrderAssetRecordAttachment.filter((record) => record.beforeAfter === "BEFORE");
			const afterRecords = response.workOrderAssetRecordAttachment.filter((record) => record.beforeAfter === "AFTER");
			const sortedRecords = [];

			beforeRecords.forEach((before) => {
				const correspondingAfter = afterRecords.find((after) => after.beforeAttachmentId === before.id);
				
				sortedRecords.push(before);
				
				if (correspondingAfter) {
					sortedRecords.push(correspondingAfter);
				}
			});

			recordsCopy[index].workOrderAssetRecordAttachment = response.workOrderAssetRecordAttachment ? sortedRecords : [];
			recordsCopy[index].workOrderAssetRecordAttachmentFiles = response.workOrderAssetRecordAttachment ? sortedRecords : [];

			formik.setFieldValue("records", recordsCopy);
		}
	}, [formik]);

	//prettier-ignore
	const onHandleUploadFiles = useCallback(async (files, recordId) => {
		let response = null;

		try {
			if (files[0] && files[2]) {
				const formData = new FormData();
				
				formData.append("woAssetRecordId", recordId);
				formData.append("beforeImage", files[0].file);
				formData.append("afterImage", files[2].file);

				await api.post.workOrder.uploadRecordAttachment(formData);
			}

			if (files[1] && files[3]) {
				const formData = new FormData();
				
				formData.append("woAssetRecordId", recordId);
				formData.append("beforeImage", files[1].file);
				formData.append("afterImage", files[3].file);
				
				await api.post.workOrder.uploadRecordAttachment(formData);
			}

			response = true;
		} catch (error) {
			serveLayoutRequestErrors(error);
		}

		if (response) {
			dispatch(promptLayoutAlertMessage({ message: "Files were added successfully!" }));
		}
	}, [dispatch]);

	//prettier-ignore
	const onHandleAddUpdate = useCallback(async (index) => {
		const fieldErrors = await formik.setFieldTouched(`records[${index}]`);

		if (fieldErrors.records?.[index]) return;

		const recordCopy = structuredClone(formik.values.records[index]);

		const payload = {
			workOrderAssetId: assetId,
			configSparePartId: recordCopy.configSparePart.id,
			workScope: recordCopy.workScope,
			caption: recordCopy.caption,
			workDescription: recordCopy.workDescription
		};

		let response = null;

		if (!recordCopy.id) {
			try {
				response = await api.post.workOrder.createRecord(payload);
			} catch (error) {
				serveLayoutRequestErrors(error);
			}

			if (response) {
				const filesList = response.workOrderAssetRecordAttachment || recordCopy.workOrderAssetRecordAttachmentFiles;

				if (filesList) {
					onHandleUploadFiles(filesList, response.id);
				}

				onHandleViewRecord(index);

				dispatch(promptLayoutAlertMessage({ message: "Record was created successfully!" }));
			}
		} else {
			try {
				payload.id = recordCopy.id;

				response = await api.post.workOrder.updateRecord(payload);
			} catch (error) {
				serveLayoutRequestErrors(error);
			}

			if (response) {
				const files = recordCopy.workOrderAssetRecordAttachmentFiles;

				if (files) {
					onHandleUploadFiles(files, response.id);
				}

				onHandleViewRecord(index);

				dispatch(promptLayoutAlertMessage({ message: "Record was updated successfully!" }));
			}
		}
	}, [assetId, dispatch, formik, onHandleUploadFiles, onHandleViewRecord]);

	// prettier-ignore
	const onHandleDeleteAttachment = useCallback(async (attachment) => {
		const attachmentId = attachment.id;

		let response = null;

		try {
			await api.post.workOrder.deleteRecordAttachment({ "attachment-id": attachmentId });

			response = true;
		} catch (error) {
			serveLayoutRequestErrors(error);
		}

		if (response) {
			dispatch(promptLayoutAlertMessage({ message: "Attachment was successfully deleted!" }));
		}
	}, [dispatch]);

	//prettier-ignore
	const onHandleSetFile = useCallback(async (name, value, recordIndex, imageIndex) => {
		const imageUrl = value ? URL.createObjectURL(value) : null;
		const attachment = formik.values.records[recordIndex].workOrderAssetRecordAttachment?.[imageIndex];
		const isNewField = formik.values.records[recordIndex].newField;

		if (!value && attachment && !isNewField) {
			onHandleDeleteAttachment(attachment)

			onHandleViewRecord(recordIndex);
		}

		formik.setFieldValue(`records[${recordIndex}].workOrderAssetRecordAttachmentFiles[${imageIndex}]`, { beforeAfter: imageIndex < 2 ? WORK_ORDER_ATTACHMENT_TYPE.BEFORE : WORK_ORDER_ATTACHMENT_TYPE.AFTER, file: value });
		formik.setFieldValue(name, imageUrl);
	}, [formik, onHandleDeleteAttachment, onHandleViewRecord]);

	//prettier-ignore
	const onHandleDownloadFile = useCallback(async (name, value, recordIndex, imageIndex) => {
		let response = null;

		const attachment = formik.values.records[recordIndex]?.workOrderAssetRecordAttachment?.[imageIndex];
		const attachmentId = attachment?.id;

		if (attachmentId) {
			try {
				await api.post.workOrder.downloadAttachment({ "attachment-id": attachmentId });

				response = true;
			} catch (error) {
				serveLayoutRequestErrors(error);
			}

			if (response) {
				const filePath = response.filePath;
				const a = document.createElement("a");
				document.body.appendChild(a);

				a.href = filePath;
				a.download = response.fileName;
				a.click();

				setTimeout(() => {
					window.URL.revokeObjectURL(filePath);
					document.body.removeChild(a);
				}, 0);
			}
		} else {
			const filePath = value;
			const a = document.createElement("a");
			document.body.appendChild(a);

			a.href = filePath;
			a.download = attachment?.fileName || "attachment";
			a.click();

			setTimeout(() => {
				window.URL.revokeObjectURL(filePath);
				document.body.removeChild(a);
			}, 0);
		}
	}, [formik.values.records]);

	//prettier-ignore
	const toggleCollapse = useCallback((index) => {
		setCollapsedIndices((prev) => {
			const newCollapsedIndices = new Set(prev);

			if (newCollapsedIndices.has(index)) {
				newCollapsedIndices.delete(index);

				onHandleViewRecord(index);
			} else {
				newCollapsedIndices.add(index);
			}

			return newCollapsedIndices;
		});
	}, [onHandleViewRecord]);

	//prettier-ignore
	const Records = useCallback((obj) => {
		return obj.records.map((o, i) => {
			const error = obj.recordsErrors?.[i];
			const touched = obj.recordsTouched?.[i];

			return (
				<div key={i} className={`work-order-work-details__wrapper ${obj.collapsedIndices.has(i) ? "work-order-work-details__wrapper--collapsed" : "work-order-work-details__wrapper--new-record"}`}>
					<div className="work-order-work-details__category">
						<p className="work-order-work-details__header-label">Record {i + 1}</p>

						<div className="work-order-work-details__header-button">
							{(o?.id || o?.newField) && isEditable && <AppButton className="work-order-work-details__delete-record-button" outline type="button" label="" icon={trashIcon} onClick={() => confirmDeleteRecordRef.current.onHandleShow({ index: i, record: o })} />}

							<AppButton className="work-order-work-details__toggle-button" outline type="button" label="" icon={obj.collapsedIndices.has(i) ? chevronDownIcon : chevronUpIcon} onClick={() => obj.toggleCollapse(i)} />
						</div>
					</div>

					{!obj.collapsedIndices.has(i) && (
						<div className="work-order-work-details__inner-wrapper">
							<div className="work-order-work-details__record-header">
								<p className="work-order-work-details__label">Details</p>

								<div className="work-order-work-details__button">
									{isEditable &&<AppButton type="button" label="Update" onClick={() => obj.onHandleAddUpdate(i)} />}
								</div>
							</div>

							<div className="work-order-work-details__row">
								<AppSelectInput required disabled={!isEditable} name={`records[${i}].configSparePart.id`} label="Spare Part Name" placeholder="Enter Spare Part Name" loadOptions={getSparePartListing} value={o?.configSparePart?.id} error={error?.configSparePart?.id} touched={touched} onChange={obj.formik.handleChange} />

								<AppInput required disabled={!isEditable} type="text" name={`records[${i}].workScope`} label="Work Scope" placeholder="Enter Work Scope" value={o?.workScope} error={error?.workScope} touched={touched} onChange={obj.formik.handleChange} />
							</div>

							<div className="work-order-work-details__row">
								<AppInput multiline disabled={!isEditable} type="textarea" name={`records[${i}].workDescription`} label="Work Description" placeholder="Enter Work Description" value={o?.workDescription} error={error?.workDescription} touched={touched} onChange={obj.formik.handleChange} />

								<AppInput multiline disabled={!isEditable} type="textarea" name={`records[${i}].caption`} label="Caption" placeholder="Enter caption" value={o?.caption} error={error?.caption} touched={touched} onChange={obj.formik.handleChange} />
							</div>

							<p className="work-order-work-details__label">Attachments</p>

							<p className="work-order-work-details__description">Please ensure that all images are in either jpg or png format with file size not exceeding 5MB. (Max 4 images only)</p>

							<div className="work-order-work-details__attachments">
								<div className="work-order-work-details__column">
									<p className="work-order-work-details__description work-order-work-details__description--attachment">Before Service</p>

									<AppInputDragAndDrop disabled={!isEditable} name={`records[${i}].workOrderAssetRecordAttachment[0].filePath`} accept="image/png, image/jpeg, image/jpg" value={o?.workOrderAssetRecordAttachment?.[0]?.filePath} error={error?.workOrderAssetRecordAttachment?.[0]?.filePath} touched={touched} onChange={(name, value) => obj.onHandleSetFile(name, value, i, 0)} onDownload={(name, value) => obj.onHandleDownloadFile(name, value)} />

									<AppInputDragAndDrop disabled={!isEditable} name={`records[${i}].workOrderAssetRecordAttachment[2].filePath`} accept="image/png, image/jpeg, image/jpg" value={o?.workOrderAssetRecordAttachment?.[2]?.filePath} error={error?.workOrderAssetRecordAttachment?.[2]?.filePath} touched={touched} onChange={(name, value) => obj.onHandleSetFile(name, value, i, 1)} onDownload={(name, value) => obj.onHandleDownloadFile(name, value)} />
								</div>

								<div className="work-order-work-details__column">
									<p className="work-order-work-details__description work-order-work-details__description--attachment">After Service</p>

									<AppInputDragAndDrop disabled={!isEditable} name={`records[${i}].workOrderAssetRecordAttachment[1].filePath`} accept="image/png, image/jpeg, image/jpg" value={o?.workOrderAssetRecordAttachment?.[1]?.filePath} error={error?.workOrderAssetRecordAttachment?.[1]?.filePath} touched={touched} onChange={(name, value) => obj.onHandleSetFile(name, value, i, 2)} onDownload={(name, value) => obj.onHandleDownloadFile(name, value)} />

									<AppInputDragAndDrop disabled={!isEditable} name={`records[${i}].workOrderAssetRecordAttachment[3].filePath`} accept="image/png, image/jpeg, image/jpg" value={o?.workOrderAssetRecordAttachment?.[3]?.filePath} error={error?.workOrderAssetRecordAttachment?.[3]?.filePath} touched={touched} onChange={(name, value) => obj.onHandleSetFile(name, value, i, 3)} onDownload={(name, value) => obj.onHandleDownloadFile(name, value)} />
								</div>
							</div>
						</div>
					)}
				</div>
			);
		});
	}, [isEditable]);

	useEffect(() => {
		if (!isCreate) onHandleGetRecordIds(assetId);
	}, [isCreate, onHandleGetRecordIds, assetId]);

	useEffect(() => {
		if (!isCreate) {
			return () => {
				cancelRequest(ENDPOINT_PATH.WORK_ORDER.RECORD_IDS);
			};
		}
	}, [isCreate, cancelRequest]);

	useImperativeHandle(ref, () => ({
		onHandleLeavePageModal: onHandleLeavePageModal,
		onHandleCheckFormikDirty: onHandleCheckFormikDirty
	}));

	return (
		<FormikProvider value={formik}>
			<div className="app-work-order-work-details">
				<div className="work-order-work-details">
					<form className="work-order-work-details__form" onSubmit={formik.handleSubmit}>
						<FieldArray
							name="records"
							render={(f) => {
								const values = f.form.values?.records;
								const errors = f.form.errors?.records;
								const touched = f.form.touched?.records;

								return (
									<div className="work-order-work-details__container">
										<div className="work-order-work-details__header">
											<p className="work-order-work-details__label">Work Details</p>

											{isEditable && <AppButton className="work-order-work-details__add-record-button" outline type="button" label="Add Work" icon={addIcon} onClick={() => onHandleNewRecord(f, "records")} />}
										</div>

										<Records formik={formik} records={values} recordsErrors={errors} recordsTouched={touched} collapsedIndices={collapsedIndices} toggleCollapse={toggleCollapse} onHandleAddUpdate={onHandleAddUpdate} onHandleSetFile={onHandleSetFile} onHandleDownloadFile={onHandleDownloadFile} />
									</div>
								);
							}}
						/>
					</form>
				</div>

				<AppWorkOrderConfirmDeleteRecordModal ref={confirmDeleteRecordRef} onConfirm={onConfirmDeleteRecord} />

				<AppWorkOrderHoldOnModal ref={holdOnModalRef} onConfirm={props.onHandleLeaveFindingDetails} />
			</div>
		</FormikProvider>
	);
};

export default memo(forwardRef(AppWorkOrderWorkDetails));
