import React, { useCallback, useEffect, useMemo, useRef, useState } from "react";

import { useSelector } from "react-redux";
import { useNavigate, useParams, useSearchParams } from "react-router-dom";

import pathnames from "routes/pathnames";

import api from "services/api";

import sanitizeObject from "common/sanitize-object";
import convertSortingQuery from "common/convert-sorting-query";
import { serveLayoutRequestErrors } from "common/serve-request-errors";
import convertPaginationTableData from "common/convert-pagination-table-data";

import ROLES from "constants/roles";

import AppStatus from "components/app-status";
import AppTable from "components/app-table/app-table";
import AppTableFilterHeader from "components/app-table-filter-header";

import chevronIcon from "assets/images/chevron-right-light-blue-icon.svg";

const AppSalesOrderDeliveryOrder = () => {
	const navigate = useNavigate();
	const profile = useSelector((state) => state.profile);
	const accessible = useMemo(() => profile?.permissions?.[ROLES.DELIVERY_ORDER], [profile]);
	const restricted = useMemo(() => !accessible?.create, [accessible]);
	const [data, setData] = useState([]);
	const [searchParams, setSearchParams] = useSearchParams();
	const memoSearchParams = useRef(setSearchParams);
	const queryParams = useRef({ page: 0, name: searchParams.get("name") || "" });
	const { id } = useParams();

	const onHandleGetList = useCallback(async () => {
		let response = null;

		try {
			const params = sanitizeObject({ ...queryParams.current, size: 10, id: id });

			response = await api.get.deliveryOrders.deliveryOrderSearch(params);

			memoSearchParams.current(params);
		} catch (error) {
			serveLayoutRequestErrors(error);
		}

		if (response) {
			const obj = convertPaginationTableData(response);

			setData(obj);
		}
	}, [id]);

	//prettier-ignore
	const onHandleSearch = useCallback((event) => {
		queryParams.current.name = event.target.value;

		onHandleGetList();
	}, [onHandleGetList]);

	//prettier-ignore
	const onHandleEditDeliveryOrder = useCallback((tableMeta) => {
		const id = data.content[tableMeta.rowIndex]?.id;

		navigate(pathnames.sales.deliveryOrdersViewEdit + id);
	}, [navigate, data]);

	//prettier-ignore
	const tableColumns = useMemo(() => [
		{
			name: "paginationNumbers",
			label: "#",
			options: {
				sort: true,
				sortThirdClickReset: true,
			},
		},
		{
			name: "referenceNo",
			label: "Delivery Order ID",
			options: {
				sort: true,
				sortThirdClickReset: true,
			},
		},
		{
			name: "title",
			label: "Title",
			options: {
				sort: true,
				sortThirdClickReset: true,
			},
		},
		{
			name: "status",
			label: "Status",
			options: {
				sort: true,
				sortThirdClickReset: true,
				customBodyRender: (value) => <AppStatus status={value} />	
			}
		},
		{
			name: "name",
			label: "Created By",
			options: {
				sort: true,
				sortThirdClickReset: true,
				customBodyRender: (value, tableMeta) => {
					return (
						<span className="table__name">{data.content[tableMeta.rowIndex]?.staffId} {data.content[tableMeta.rowIndex]?.name}</span>
					);
				},
			},
		},
		{
			name: "action",
			label: "Action",
			options: {
				sort: false,
				customBodyRender: (value, tableMeta) => {
					return (
						<button type="button" className="table__action" onClick={() => onHandleEditDeliveryOrder(tableMeta)}>
							<img src={chevronIcon} alt="action-icon" />
						</button>
					);
				}
			}
		}
	], [data.content, onHandleEditDeliveryOrder]);

	const onHandleCreateDeliveryOrder = useCallback(async () => {
		let response = null;

		const payload = {
			saleOrderId: id,
			deliveryOrderStatus: "CREATE_DELIVERY_ORDER_FIRST_TIME"
		};

		try {
			response = await api.post.deliveryOrders.createDeliveryOrder(payload);
		} catch (error) {
			serveLayoutRequestErrors(error);
		}

		if (response) {
			navigate(pathnames.sales.deliveryOrdersViewEdit + response.id);
		}
	}, [id, navigate]);

	//prettier-ignore
	const onHandleAddDeliveryOrder = useCallback(async () => {
		let response = null;

		const payload = {
			saleOrderId: id,
			deliveryOrderStatus: "CREATE_DELIVERY_ORDER_NOT_FIRST_TIME"
		};

		try {
			response = await api.post.deliveryOrders.createDeliveryOrder(payload);
		} catch (error) {
			serveLayoutRequestErrors(error);
		}

		if (response) {
			navigate(pathnames.sales.deliveryOrdersViewEdit + response.id);
		}
	}, [id, navigate]);

	const emptyState = useMemo(() => {
		if (data?.content?.length) return {};

		const node = () => (
			<tbody>
				<tr className="table__empty-state">
					<td colSpan={tableColumns.length} align="center">
						<p className="table__text">
							No Delivery Orders Created.
							<span className="table__link" onClick={onHandleCreateDeliveryOrder}>
								Create Delivery Order?
							</span>
						</p>
					</td>
				</tr>
			</tbody>
		);

		return { TableBody: node };
	}, [tableColumns, onHandleCreateDeliveryOrder, data.content]);

	//prettier-ignore
	const tableOptions = useMemo(() => ({
		count: data.totalElements,
		page: data.page,
		serverSide: true,
		onTableChange: (action, tableState) => {
			switch (action) {
				case "changePage":
					queryParams.current.page = tableState.page;

					onHandleGetList();
					break;
				case "sort":
					queryParams.current = { ...queryParams.current, sort: convertSortingQuery(tableState.sortOrder) };
					
					onHandleGetList();
					break;
				default:
					break;
			}
		}
	}), [data, onHandleGetList]);

	useEffect(() => {
		if (id) {
			onHandleGetList();
		}
	}, [id, onHandleGetList]);

	return (
		<div className="app-sales-order-delivery-order">
			<div className="sales-order-delivery-order">
				<AppTableFilterHeader searchPlaceholder="Search by Delivery Order ID or Title" searchDefaultValue={queryParams.current.value} onHandleSearch={onHandleSearch} onHandleAdd={data.content?.length ? onHandleAddDeliveryOrder : null} restricted={restricted} />

				<AppTable data={data.content || []} columns={tableColumns} options={tableOptions} components={emptyState} />
			</div>
		</div>
	);
};

export default AppSalesOrderDeliveryOrder;
