import React, { Fragment, useCallback, useEffect, useContext } from "react";

import { useFormik } from "formik";
import { useDispatch, useSelector } from "react-redux";
import { AxiosContext } from "contexts/with-interceptor-provider";

import api from "services/api";
import ENDPOINT_PATH from "constants/end-point-path";
import { promptLayoutAlertMessage } from "store/slices/layout-alert";

import getOrdinalNumber from "common/get-ordinal-number";
import { serveLayoutRequestErrors } from "common/serve-request-errors";

import AppInput from "components/app-input";
import AppMobileInput from "components/app-mobile-input";
import AppInputDragAndDrop from "components/app-input-drag-and-drop";

const AppUserInformation = () => {
	const profile = useSelector((state) => state.profile);
	const dispatch = useDispatch();
	const cancelRequest = useContext(AxiosContext).onHandleCancelRequest;

	const initialValues = {
		preSignature: null
	};

	const formik = useFormik({
		initialValues: initialValues,
		onSubmit: (values) => {
			onHandleSubmit(values);
		}
	});

	const setFieldValue = formik.setFieldValue;

	// prettier-ignore
	const onHandleSubmit = useCallback(async (file) => {
		let response = null;

		if (!file) return;

		try {
			const formData = new FormData();

			formData.append("signature", file);

			await api.post.humanResource.uploadSignature(formData);

			response = true;
		} catch (error) {
			serveLayoutRequestErrors(error);
		}

		if (response) {
			dispatch(promptLayoutAlertMessage({ message: "Signature was updated successfully!" }));
		}
	}, [dispatch]);

	// prettier-ignore
	const onHandleSetSignature = useCallback((name, value) => {
		setFieldValue(value, name);

		onHandleSubmit(value);
	}, [setFieldValue, onHandleSubmit]);

	const onHandleDeleteAttachment = useCallback(() => {
		setFieldValue("preSignature", null);
	}, [setFieldValue]);

	const onHandleViewSignature = useCallback(async () => {
		let response = null;

		try {
			response = await api.post.humanResource.viewSignature();
		} catch (error) {
			serveLayoutRequestErrors(error);
		}

		if (response) {
			setFieldValue("preSignature", response?.signaturePath)
		}
	}, [setFieldValue]);

	useEffect(() => {
		onHandleViewSignature();
	}, [onHandleViewSignature])

	useEffect(() => {
		return () => {
			cancelRequest(ENDPOINT_PATH.HUMAN_RESOURCE.EMPLOYEE_SIGNATURE_VIEW);
		};
	}, [cancelRequest]);

	return (
		<div className="app-user-information">
			<div className="user-information">
				<div className="user-information__container">
					<p className="user-information__label">Personal Information</p>

					<p className="user-information__description">If you would like to change these details, please contact your admin.</p>

					<div className="user-information__row">
						{/* prettier-ignore */}
						<AppInput disabled name="userId" type="text" label="User ID" placeholder="User ID" value={profile.userId} onChange={() => {}} />
					</div>

					<div className="user-information__row">
						{/* prettier-ignore */}
						<AppMobileInput disabled type="number" name="mobileNo" label="Mobile No." value={profile.mobileNo} prefixNo={profile.mobileNoPrefix} onChange={() => {}} />

						<AppInput disabled className="email" name="email" type="text" label="Email" placeholder="Email" value={profile.email} onChange={() => {}} />
					</div>
				</div>

				{profile.emergencyContacts?.length > 0 && (
					<div className="user-information__container user-information__container--border">
						{profile.emergencyContacts.map((o, i) => {
							const ordinalNumber = getOrdinalNumber(i + 1) + " Emergency Contact";

							return (
								<Fragment key={i}>
									<p className="user-information__label">{ordinalNumber}</p>

									<div className="user-information__row">
										{/* prettier-ignore */}
										<AppInput disabled name="emergencyContactName" type="text" label="Emergency Contact Name" placeholder="Emergency Contact Name" value={o.fullName} onChange={() => {}} />

										<AppMobileInput disabled type="number" name="emergencyContactNumber" label="Emergency Contact Number" value={o.mobileNo} prefixNo={o.mobileNoPrefix} onChange={() => {}} />
									</div>

									<div className="user-information__row">
										<AppInput disabled name="emergencyContactRelationship" type="text" label="Relationship" placeholder="Relationship" value={o.relationship} onChange={() => {}} />
									</div>
								</Fragment>
							);
						})}
					</div>
				)}

				<div className="user-information__container user-information__container--border">
					<p className="user-information__label">Signature</p>

					<div className="user-information__column">
						<AppInputDragAndDrop name="preSignature" accept="image/png, image/jpeg, image/jpg" value={formik.values?.preSignature} error={formik.errors?.preSignature} touched={formik.touched?.preSignature} onChange={(name, file) => onHandleSetSignature(name, file)} onDelete={() => onHandleDeleteAttachment()} />
					</div>
				</div>
			</div>
		</div>
	);
};

export default AppUserInformation;
