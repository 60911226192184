import services from "services";

import encodeQueryParams from "common/encode-query-params";
import stringParamFormatter from "common/string-param-formatter";

import ENDPOINT_PATH from "constants/end-point-path";

const publicToken = { token: false };
const multipart = { headers: { "Content-Type": "multipart/form-data" } };

const api = {
	get: {
		general: {
			gender: () => services.get(ENDPOINT_PATH.GENERAL.GENDER),
			idType: () => services.get(ENDPOINT_PATH.GENERAL.ID_TYPE),
			nationality: () => services.get(ENDPOINT_PATH.GENERAL.NATIONALITY),
			maritalStatus: () => services.get(ENDPOINT_PATH.GENERAL.MARITAL_STATUS),
			relationship: () => services.get(ENDPOINT_PATH.GENERAL.RELATIONSHIP),
			employeeFamilyMemberRelationship: () => services.get(ENDPOINT_PATH.GENERAL.EMPLOYEE_FAMILY_MEMBER_RELATIONSHIP),
			employeeFamilyMemberWorkingStatus: () => services.get(ENDPOINT_PATH.GENERAL.EMPLOYEE_FAMILY_MEMBER_WORKING_STATUS),
			malaysiaBank: () => services.get(ENDPOINT_PATH.GENERAL.MALAYSIA_BANK),
			weekdays: () => services.get(ENDPOINT_PATH.GENERAL.WEEK_DAYS),
			states: (params) => services.get(ENDPOINT_PATH.GENERAL.STATES + encodeQueryParams(params)),
			region: (params) => services.get(ENDPOINT_PATH.GENERAL.REGION + encodeQueryParams(params)),
			postcode: (params) => services.get(ENDPOINT_PATH.GENERAL.POSTCODE + encodeQueryParams(params)),
			userStatus: () => services.get(ENDPOINT_PATH.GENERAL.USER_STATUS),
			serviceStatus: (params) => services.get(ENDPOINT_PATH.GENERAL.SERVICE_STATUS + encodeQueryParams(params)),
			serviceType: (params) => services.get(ENDPOINT_PATH.GENERAL.SERVICE_TYPE + encodeQueryParams(params)),
			serviceInput: (params) => services.get(ENDPOINT_PATH.GENERAL.SERVICE_INPUT + encodeQueryParams(params)),
			contractType: () => services.get(ENDPOINT_PATH.GENERAL.CONTRACT_TYPE),
			contractStatus: () => services.get(ENDPOINT_PATH.GENERAL.CONTRACT_STATUS),
			contractTemplate: (params) => services.get(ENDPOINT_PATH.GENERAL.CONTRACT_TEMPLATE + encodeQueryParams(params)),
			inputType: () => services.get(ENDPOINT_PATH.GENERAL.INPUT_TYPE),
			siteStatus: () => services.get(ENDPOINT_PATH.GENERAL.SITE_STATUS),
			enquiryStatus: () => services.get(ENDPOINT_PATH.GENERAL.ENQUIRY_STATUS),
			employmentStatus: () => services.get(ENDPOINT_PATH.GENERAL.EMPLOYEMENT_STATUS),
			permissions: (params) => services.get(ENDPOINT_PATH.GENERAL.PERMISSIONS + encodeQueryParams(params), { cancelId: ENDPOINT_PATH.GENERAL.PERMISSIONS }),
			roles: () => services.get(ENDPOINT_PATH.GENERAL.ROLES),
			frequency: () => services.get(ENDPOINT_PATH.GENERAL.FREQUENCY),
			template: (params, headers) => services.get(ENDPOINT_PATH.GENERAL.TEMPLATE + encodeQueryParams(params), { ...headers, responseType: "arraybuffer", cancelId: ENDPOINT_PATH.GENERAL.TEMPLATE }),
			paymentCurrency: (params) => services.get(ENDPOINT_PATH.GENERAL.COUNTRY_CURRENCY + encodeQueryParams(params))
		},
		profile: {
			profile: () => services.get(ENDPOINT_PATH.PROFILE.PROFILE)
		},
		customerConfiguration: {
			buildingType: (params) => services.get(ENDPOINT_PATH.CUSTOMER_CONFIGURATION.BULDING_TYPE + encodeQueryParams(params), { cancelId: ENDPOINT_PATH.CUSTOMER_CONFIGURATION.BULDING_TYPE }),
			buildingTypes: (params) => services.get(ENDPOINT_PATH.CUSTOMER_CONFIGURATION.BULDING_TYPES + encodeQueryParams(params), { cancelId: ENDPOINT_PATH.CUSTOMER_CONFIGURATION.BULDING_TYPES }),
			workspace: (params) => services.get(ENDPOINT_PATH.CUSTOMER_CONFIGURATION.WORK_SPACE + encodeQueryParams(params), { cancelId: ENDPOINT_PATH.CUSTOMER_CONFIGURATION.WORK_SPACE }),
			workspaces: (params) => services.get(ENDPOINT_PATH.CUSTOMER_CONFIGURATION.WORK_SPACES + encodeQueryParams(params), { cancelId: ENDPOINT_PATH.CUSTOMER_CONFIGURATION.WORK_SPACES }),
			assetType: (params) => services.get(ENDPOINT_PATH.CUSTOMER_CONFIGURATION.ASSET_TYPE + encodeQueryParams(params), { cancelId: ENDPOINT_PATH.CUSTOMER_CONFIGURATION.ASSET_TYPE }),
			assetTypes: (params) => services.get(ENDPOINT_PATH.CUSTOMER_CONFIGURATION.ASSET_TYPES + encodeQueryParams(params), { cancelId: ENDPOINT_PATH.CUSTOMER_CONFIGURATION.ASSET_TYPES })
		},
		customerEnquiry: {
			enquiry: (params) => services.get(ENDPOINT_PATH.CUSTOMER_ENQUIRY.ENQUIRY + encodeQueryParams(params), { cancelId: ENDPOINT_PATH.CUSTOMER_ENQUIRY.ENQUIRY }),
			openEnquiries: (params) => services.get(ENDPOINT_PATH.CUSTOMER_ENQUIRY.OPEN_ENQUIRIES + encodeQueryParams(params), { cancelId: ENDPOINT_PATH.CUSTOMER_ENQUIRY.OPEN_ENQUIRIES }),
			closedEnquiries: (params) => services.get(ENDPOINT_PATH.CUSTOMER_ENQUIRY.CLOSED_ENQUIRIES + encodeQueryParams(params), { cancelId: ENDPOINT_PATH.CUSTOMER_ENQUIRY.CLOSED_ENQUIRIES }),
			configuration: (params) => services.get(ENDPOINT_PATH.CUSTOMER_ENQUIRY.CONFIGURATION + encodeQueryParams(params), { cancelId: ENDPOINT_PATH.CUSTOMER_ENQUIRY.CONFIGURATION })
		},
		assetMaintenance: {
			asset: (params) => services.get(ENDPOINT_PATH.ASSET_MAINTENANCE.ASSET + encodeQueryParams(params), { cancelId: ENDPOINT_PATH.ASSET_MAINTENANCE.ASSET }),
			assetService: (params) => services.get(stringParamFormatter(ENDPOINT_PATH.ASSET_MAINTENANCE.ASSET_SERVICE, params) + encodeQueryParams(params), { cancelId: ENDPOINT_PATH.ASSET_MAINTENANCE.ASSET_SERVICE }),
			assetServices: (params) => services.get(stringParamFormatter(ENDPOINT_PATH.ASSET_MAINTENANCE.ASSET_SERVICES, params) + encodeQueryParams(params), { cancelId: ENDPOINT_PATH.ASSET_MAINTENANCE.ASSET_SERVICES }),
			assetServicesList: (params) => services.get(stringParamFormatter(ENDPOINT_PATH.ASSET_MAINTENANCE.ASSET_SERVICES_LIST, params) + encodeQueryParams(params), { cancelId: ENDPOINT_PATH.ASSET_MAINTENANCE.ASSET_SERVICES_LIST }),
			assetExclusiveServicesSearch: (params) => services.get(stringParamFormatter(ENDPOINT_PATH.ASSET_MAINTENANCE.ASSET_EXCLUSIVE_SERVICES_SEARCH, params) + encodeQueryParams(params), { cancelId: ENDPOINT_PATH.ASSET_MAINTENANCE.ASSET_EXCLUSIVE_SERVICES_SEARCH }),
			assetExclusiveServiceChecklistSearch: (params) => services.get(stringParamFormatter(ENDPOINT_PATH.ASSET_MAINTENANCE.ASSET_EXCLUSIVE_SERVICE_CHECKLIST_SEARCH, params) + encodeQueryParams(params), { cancelId: ENDPOINT_PATH.ASSET_MAINTENANCE.ASSET_EXCLUSIVE_SERVICE_CHECKLIST_SEARCH })
		},
		customer: {
			docs: (params) => services.get(stringParamFormatter(ENDPOINT_PATH.CUSTOMER.DOCS, params), { cancelId: stringParamFormatter(ENDPOINT_PATH.CUSTOMER.DOCS, params) }),
			customer: (params) => services.get(ENDPOINT_PATH.CUSTOMER.CUSTOMER + encodeQueryParams(params), { cancelId: ENDPOINT_PATH.CUSTOMER.CUSTOMER }),
			customers: (params) => services.get(ENDPOINT_PATH.CUSTOMER.CUSTOMERS + encodeQueryParams(params), { cancelId: ENDPOINT_PATH.CUSTOMER.CUSTOMERS }),
			customersLookup: (params) => services.get(ENDPOINT_PATH.CUSTOMER.CUSTOMERS_LOOKUP + encodeQueryParams(params), { cancelId: ENDPOINT_PATH.CUSTOMER.CUSTOMERS_LOOKUP }),
			customerPic: (params) => services.get(stringParamFormatter(ENDPOINT_PATH.CUSTOMER.CUSTOMER_PIC, params) + encodeQueryParams(params.queryParams), { cancelId: stringParamFormatter(ENDPOINT_PATH.CUSTOMER.CUSTOMER_PIC, params) }),
			contract: (params) => services.get(ENDPOINT_PATH.CUSTOMER.CONTRACT + encodeQueryParams(params), { cancelId: ENDPOINT_PATH.CUSTOMER.CONTRACT }),
			contracts: (params) => services.get(ENDPOINT_PATH.CUSTOMER.CONTRACTS + encodeQueryParams(params), { cancelId: ENDPOINT_PATH.CUSTOMER.CONTRACTS }),
			contractsPic: (params) => services.get(stringParamFormatter(ENDPOINT_PATH.CUSTOMER.CONTRACTS_PIC, params) + encodeQueryParams(params.queryParams), { cancelId: stringParamFormatter(ENDPOINT_PATH.CUSTOMER.CONTRACTS_PIC, params) }),
			contractSites: (params) => services.get(stringParamFormatter(ENDPOINT_PATH.CUSTOMER.CONTRACTS_SITES, params) + encodeQueryParams(params), { cancelId: stringParamFormatter(ENDPOINT_PATH.CUSTOMER.CONTRACTS_SITES, params) }),
			exclusivePicSearch: (params) => services.get(stringParamFormatter(ENDPOINT_PATH.CUSTOMER.CONTRACTS_EXCLUSIVE_PIC, params) + encodeQueryParams(params.queryParams), { cancelId: ENDPOINT_PATH.CUSTOMER.CONTRACTS_EXCLUSIVE_PIC }),
			contractsByPicSearch: (params) => services.get(stringParamFormatter(ENDPOINT_PATH.CUSTOMER.CONTRACTS_BY_PIC, params) + encodeQueryParams(params.queryParams), { cancelId: ENDPOINT_PATH.CUSTOMER.CONTRACTS_BY_PIC })
		},
		customerSiteMaintenance: {
			site: (params) => services.get(ENDPOINT_PATH.CUSTOMER_SITE_MAINTENANCE.SITE + encodeQueryParams(params), { cancelId: ENDPOINT_PATH.CUSTOMER_SITE_MAINTENANCE.SITE }),
			assets: (params) => services.get(stringParamFormatter(ENDPOINT_PATH.CUSTOMER_SITE_MAINTENANCE.ASSETS, params) + encodeQueryParams(params), { cancelId: stringParamFormatter(ENDPOINT_PATH.CUSTOMER_SITE_MAINTENANCE.ASSETS, params) }),
			sitePic: (params) => services.get(stringParamFormatter(ENDPOINT_PATH.CUSTOMER_SITE_MAINTENANCE.SITE_PIC, params) + encodeQueryParams(params), { cancelId: stringParamFormatter(ENDPOINT_PATH.CUSTOMER_SITE_MAINTENANCE.SITE_PIC, params) }),
			exclusiveSitePic: (params) => services.get(stringParamFormatter(ENDPOINT_PATH.CUSTOMER_SITE_MAINTENANCE.EXCLUSIVE_SITE_PIC, params) + encodeQueryParams(params.queryParams), { cancelId: stringParamFormatter(ENDPOINT_PATH.CUSTOMER_SITE_MAINTENANCE.EXCLUSIVE_SITE_PIC, params) })
		},
		mobile: {
			user: (params) => services.get(ENDPOINT_PATH.MOBILE.USER + encodeQueryParams(params), { cancelId: ENDPOINT_PATH.MOBILE.USER }),
			users: (params) => services.get(ENDPOINT_PATH.MOBILE.USERS + encodeQueryParams(params), { cancelId: ENDPOINT_PATH.MOBILE.USERS })
		},
		sparePart: {
			sparePart: (params) => services.get(ENDPOINT_PATH.SPARE_PART.SPARE_PART + encodeQueryParams(params), { cancelId: ENDPOINT_PATH.SPARE_PART.SPARE_PART }),
			spareParts: (params) => services.get(ENDPOINT_PATH.SPARE_PART.SPARE_PARTS + encodeQueryParams(params), { cancelId: ENDPOINT_PATH.SPARE_PART.SPARE_PARTS }),
			docs: (params) => services.get(stringParamFormatter(ENDPOINT_PATH.SPARE_PART.DOCS, params), { cancelId: ENDPOINT_PATH.SPARE_PART.DOCS }),
			serviceConfigs: (params) => services.get(ENDPOINT_PATH.SPARE_PART.SERVICE_CONFIGS + encodeQueryParams(params), { cancelId: ENDPOINT_PATH.SPARE_PART.SERVICE_CONFIGS }),
			serviceConfigsList: (params) => services.get(ENDPOINT_PATH.SPARE_PART.SERVICE_CONFIGS_LIST + encodeQueryParams(params), { cancelId: ENDPOINT_PATH.SPARE_PART.SERVICE_CONFIGS_LIST }),
			exclusiveServiceListings: (params) => services.get(ENDPOINT_PATH.SPARE_PART.EXCLUSIVE_SERVICE_LISTINGS + encodeQueryParams(params), { cancelId: ENDPOINT_PATH.SPARE_PART.EXCLUSIVE_SERVICE_LISTINGS })
		},
		serviceListing: {
			serviceListing: (params) => services.get(ENDPOINT_PATH.SERVICE_LISTING.SERVICE_LISTING + encodeQueryParams(params), { cancelId: ENDPOINT_PATH.SERVICE_LISTING.SERVICE_LISTING }),
			serviceListings: (params) => services.get(ENDPOINT_PATH.SERVICE_LISTING.SERVICE_LISTINGS + encodeQueryParams(params), { cancelId: ENDPOINT_PATH.SERVICE_LISTING.SERVICE_LISTINGS }),
			subtask: (params) => services.get(ENDPOINT_PATH.SERVICE_LISTING.SUBTASK + encodeQueryParams(params), { cancelId: ENDPOINT_PATH.SERVICE_LISTING.SUBTASK }),
			subtasks: (params) => services.get(ENDPOINT_PATH.SERVICE_LISTING.SUBTASKS + encodeQueryParams(params), { cancelId: ENDPOINT_PATH.SERVICE_LISTING.SUBTASKS }),
			subtaskList: (params) => services.get(ENDPOINT_PATH.SERVICE_LISTING.SUBTASK_LIST + encodeQueryParams(params), { cancelId: ENDPOINT_PATH.SERVICE_LISTING.SUBTASK_LIST })
		},
		serviceChecklist: {
			serviceChecklist: (params) => services.get(ENDPOINT_PATH.SERVICE_CHECKLIST.SERVICE_CHECKLIST + encodeQueryParams(params), { cancelId: ENDPOINT_PATH.SERVICE_CHECKLIST.SERVICE_CHECKLIST }),
			serviceChecklists: (params) => services.get(ENDPOINT_PATH.SERVICE_CHECKLIST.SERVICE_CHECKLISTS + encodeQueryParams(params), { cancelId: ENDPOINT_PATH.SERVICE_CHECKLIST.SERVICE_CHECKLISTS }),
			serviceConfigs: (params) => services.get(ENDPOINT_PATH.SERVICE_CHECKLIST.SERVICE_CONFIGS + encodeQueryParams(params), { cancelId: ENDPOINT_PATH.SERVICE_CHECKLIST.SERVICE_CONFIGS }),
			serviceConfigsList: (params) => services.get(ENDPOINT_PATH.SERVICE_CHECKLIST.SERVICE_CONFIGS_LIST + encodeQueryParams(params), { cancelId: ENDPOINT_PATH.SERVICE_CHECKLIST.SERVICE_CONFIGS_LIST }),
			exclusiveServiceListings: (params) => services.get(ENDPOINT_PATH.SERVICE_CHECKLIST.EXCLUSIVE_SERVICE_LISTINGS + encodeQueryParams(params), { cancelId: ENDPOINT_PATH.SERVICE_CHECKLIST.EXCLUSIVE_SERVICE_LISTINGS })
		},
		contract: {
			contract: (params) => services.get(ENDPOINT_PATH.CONTRACT.CONTRACT + encodeQueryParams(params), { cancelId: ENDPOINT_PATH.CONTRACT.CONTRACT }),
			contracts: (params) => services.get(ENDPOINT_PATH.CONTRACT.CONTRACTS + encodeQueryParams(params), { cancelId: ENDPOINT_PATH.CONTRACT.CONTRACTS })
		},
		userAccess: {
			role: (params) => services.get(ENDPOINT_PATH.USER_ACCESS.ROLE + encodeQueryParams(params), { cancelId: ENDPOINT_PATH.USER_ACCESS.ROLE }),
			roles: (params) => services.get(ENDPOINT_PATH.USER_ACCESS.ROLES + encodeQueryParams(params), { cancelId: ENDPOINT_PATH.USER_ACCESS.ROLES }),
			user: (params) => services.get(ENDPOINT_PATH.USER_ACCESS.USER + encodeQueryParams(params), { cancelId: ENDPOINT_PATH.USER_ACCESS.USER }),
			users: (params) => services.get(ENDPOINT_PATH.USER_ACCESS.USERS + encodeQueryParams(params), { cancelId: ENDPOINT_PATH.USER_ACCESS.USERS })
		},
		input: {
			input: (params) => services.get(ENDPOINT_PATH.INPUT.INPUT + encodeQueryParams(params), { cancelId: ENDPOINT_PATH.INPUT.INPUT }),
			inputs: (params) => services.get(ENDPOINT_PATH.INPUT.INPUTS + encodeQueryParams(params), { cancelId: ENDPOINT_PATH.INPUT.INPUTS })
		},
		sparePartCategory: {
			sparePartCategory: (params) => services.get(ENDPOINT_PATH.SPARE_PART_CATEGORY.SPARE_PART_CATEGORY + encodeQueryParams(params), { cancelId: ENDPOINT_PATH.SPARE_PART_CATEGORY.SPARE_PART_CATEGORY }),
			sparePartCategories: (params) => services.get(ENDPOINT_PATH.SPARE_PART_CATEGORY.SPARE_PART_CATEGORIES + encodeQueryParams(params), { cancelId: ENDPOINT_PATH.SPARE_PART_CATEGORY.SPARE_PART_CATEGORIES })
		},
		sparePartSubCategory: {
			sparePartSubCategory: (params) => services.get(ENDPOINT_PATH.SPARE_PART_SUB_CATEGORY.SPARE_PART_SUB_CATEGORY + encodeQueryParams(params), { cancelId: ENDPOINT_PATH.SPARE_PART_SUB_CATEGORY.SPARE_PART_SUB_CATEGORY }),
			sparePartSubCategories: (params) => services.get(ENDPOINT_PATH.SPARE_PART_SUB_CATEGORY.SPARE_PART_SUB_CATEGORIES + encodeQueryParams(params), { cancelId: ENDPOINT_PATH.SPARE_PART_SUB_CATEGORY.SPARE_PART_SUB_CATEGORIES })
		},
		humanResource: {
			positions: (params) => services.get(ENDPOINT_PATH.HUMAN_RESOURCE.POSITIONS + encodeQueryParams(params), { cancelId: ENDPOINT_PATH.HUMAN_RESOURCE.POSITIONS }),
			leaveTypes: (params) => services.get(ENDPOINT_PATH.HUMAN_RESOURCE.LEAVE_TYPES + encodeQueryParams(params), { cancelId: ENDPOINT_PATH.HUMAN_RESOURCE.LEAVE_TYPES }),
			levels: (params) => services.get(ENDPOINT_PATH.HUMAN_RESOURCE.LEVELS + encodeQueryParams(params), { cancelId: ENDPOINT_PATH.HUMAN_RESOURCE.LEVELS }),
			departments: (params) => services.get(ENDPOINT_PATH.HUMAN_RESOURCE.DEPARTMENTS + encodeQueryParams(params), { cancelId: ENDPOINT_PATH.HUMAN_RESOURCE.DEPARTMENTS }),
			employees: (params) => services.get(ENDPOINT_PATH.HUMAN_RESOURCE.EMPLOYEES + encodeQueryParams(params), { cancelId: ENDPOINT_PATH.HUMAN_RESOURCE.EMPLOYEES }),
			employee: (params) => services.get(ENDPOINT_PATH.HUMAN_RESOURCE.EMPLOYEE + encodeQueryParams(params), { cancelId: ENDPOINT_PATH.HUMAN_RESOURCE.EMPLOYEE }),
			employeeHR: (params) => services.get(stringParamFormatter(ENDPOINT_PATH.HUMAN_RESOURCE.EMPLOYEE_HR, params) + encodeQueryParams(params), { cancelId: ENDPOINT_PATH.HUMAN_RESOURCE.EMPLOYEE_HR }),
			claimTypes: (params) => services.get(ENDPOINT_PATH.HUMAN_RESOURCE.CLAIM_TYPES + encodeQueryParams(params), { cancelId: ENDPOINT_PATH.HUMAN_RESOURCE.CLAIM_TYPES }),
			holidays: (params) => services.get(ENDPOINT_PATH.HUMAN_RESOURCE.HOLIDAYS + encodeQueryParams(params), { cancelId: ENDPOINT_PATH.HUMAN_RESOURCE.HOLIDAYS }),
			holidayCalendar: (params) => services.get(stringParamFormatter(ENDPOINT_PATH.HUMAN_RESOURCE.HOLIDAY_CALENDAR, params) + encodeQueryParams(params), { cancelId: ENDPOINT_PATH.HUMAN_RESOURCE.HOLIDAY_CALENDAR }),
			subordinateLeaveCalendar: (params) => services.get(stringParamFormatter(ENDPOINT_PATH.HUMAN_RESOURCE.SUBORDINATE_LEAVE_CALENDAR, params) + encodeQueryParams(params), { cancelId: ENDPOINT_PATH.HUMAN_RESOURCE.SUBORDINATE_LEAVE_CALENDAR }),
			unassignedUserId: (params) => services.get(ENDPOINT_PATH.HUMAN_RESOURCE.UNASSIGNED_USER_ID + encodeQueryParams(params), { cancelId: ENDPOINT_PATH.HUMAN_RESOURCE.UNASSIGNED_USER_ID }),
			leavePackage: (params) => services.get(ENDPOINT_PATH.HUMAN_RESOURCE.LEAVE_PACKAGE + encodeQueryParams(params), { cancelId: ENDPOINT_PATH.HUMAN_RESOURCE.LEAVE_PACKAGE }),
			claimPackage: (params) => services.get(ENDPOINT_PATH.HUMAN_RESOURCE.CLAIM_PACKAGE + encodeQueryParams(params), { cancelId: ENDPOINT_PATH.HUMAN_RESOURCE.CLAIM_PACKAGE }),
			employeeLeaveSummary: (params) => services.get(stringParamFormatter(ENDPOINT_PATH.HUMAN_RESOURCE.EMPLOYEE_LEAVE_SUMMARY, params) + encodeQueryParams(params), { cancelId: ENDPOINT_PATH.HUMAN_RESOURCE.EMPLOYEE_LEAVE_SUMMARY }),
			employeeLeaveHistory: (params) => services.get(stringParamFormatter(ENDPOINT_PATH.HUMAN_RESOURCE.EMPLOYEE_LEAVE_HISTORY, params) + encodeQueryParams(params), { cancelId: ENDPOINT_PATH.HUMAN_RESOURCE.EMPLOYEE_LEAVE_HISTORY }),
			employeePendingLeave: (params) => services.get(stringParamFormatter(ENDPOINT_PATH.HUMAN_RESOURCE.EMPLOYEE_PENDING_LEAVE, params) + encodeQueryParams(params), { cancelId: ENDPOINT_PATH.HUMAN_RESOURCE.EMPLOYEE_PENDING_LEAVE }),
			employeeClaimSummary: (params) => services.get(stringParamFormatter(ENDPOINT_PATH.HUMAN_RESOURCE.EMPLOYEE_CLAIM_SUMMARY, params) + encodeQueryParams(params), { cancelId: ENDPOINT_PATH.HUMAN_RESOURCE.EMPLOYEE_CLAIM_SUMMARY }),
			employeeClaimHistory: (params) => services.get(stringParamFormatter(ENDPOINT_PATH.HUMAN_RESOURCE.EMPLOYEE_CLAIM_HISTORY, params) + encodeQueryParams(params), { cancelId: ENDPOINT_PATH.HUMAN_RESOURCE.EMPLOYEE_CLAIM_HISTORY }),
			employeePendingClaim: (params) => services.get(stringParamFormatter(ENDPOINT_PATH.HUMAN_RESOURCE.EMPLOYEE_PENDING_CLAIM, params) + encodeQueryParams(params), { cancelId: ENDPOINT_PATH.HUMAN_RESOURCE.EMPLOYEE_PENDING_CLAIM }),
			leavePackages: (params) => services.get(ENDPOINT_PATH.HUMAN_RESOURCE.LEAVE_PACKAGES + encodeQueryParams(params), { cancelId: ENDPOINT_PATH.HUMAN_RESOURCE.LEAVE_PACKAGES }),
			claimPackages: (params) => services.get(ENDPOINT_PATH.HUMAN_RESOURCE.CLAIM_PACKAGES + encodeQueryParams(params), { cancelId: ENDPOINT_PATH.HUMAN_RESOURCE.CLAIM_PACKAGES }),
			pendingLeave: (params) => services.get(ENDPOINT_PATH.HUMAN_RESOURCE.PENDING_LEAVE + encodeQueryParams(params), { cancelId: ENDPOINT_PATH.HUMAN_RESOURCE.PENDING_LEAVE }),
			leaveHistory: (params) => services.get(ENDPOINT_PATH.HUMAN_RESOURCE.LEAVE_HISTORY + encodeQueryParams(params), { cancelId: ENDPOINT_PATH.HUMAN_RESOURCE.LEAVE_HISTORY }),
			claimHistory: (params) => services.get(ENDPOINT_PATH.HUMAN_RESOURCE.CLAIM_HISTORY + encodeQueryParams(params), { cancelId: ENDPOINT_PATH.HUMAN_RESOURCE.CLAIM_HISTORY }),
			pendingClaim: (params) => services.get(ENDPOINT_PATH.HUMAN_RESOURCE.PENDING_CLAIM + encodeQueryParams(params), { cancelId: ENDPOINT_PATH.HUMAN_RESOURCE.PENDING_CLAIM }),
			employeeDirectory: (params) => services.get(ENDPOINT_PATH.HUMAN_RESOURCE.EMPLOYEE_DIRECTORY + encodeQueryParams(params), { cancelId: ENDPOINT_PATH.HUMAN_RESOURCE.EMPLOYEE_DIRECTORY }),
			dropDownLeaveType: () => services.get(ENDPOINT_PATH.HUMAN_RESOURCE.DROP_DOWN_LEAVE_TYPE),
			dropDownClaimType: () => services.get(ENDPOINT_PATH.HUMAN_RESOURCE.DROP_DOWN_CLAIM_TYPE),
			attendanceEmployeeDetails: (params) => services.get(stringParamFormatter(ENDPOINT_PATH.HUMAN_RESOURCE.ATTENDANCE_EMPLOYEE_DETAILS, params), { cancelId: ENDPOINT_PATH.HUMAN_RESOURCE.ATTENDANCE_EMPLOYEE_DETAILS }),
			attendanceEmployeeDirectory: (params) => services.get(ENDPOINT_PATH.HUMAN_RESOURCE.ATTENDANCE_EMPLOYEE_DIRECTORY + encodeQueryParams(params), { cancelId: ENDPOINT_PATH.HUMAN_RESOURCE.ATTENDANCE_EMPLOYEE_DIRECTORY }),
			attendanceSummaryEmployee: (params) => services.get(stringParamFormatter(ENDPOINT_PATH.HUMAN_RESOURCE.ATTENDANCE_SUMMARY_EMPLOYEE, params) + encodeQueryParams(params), { cancelId: ENDPOINT_PATH.HUMAN_RESOURCE.ATTENDANCE_SUMMARY_EMPLOYEE }),
			correctiveAttendanceHistory: (params) => services.get(stringParamFormatter(ENDPOINT_PATH.HUMAN_RESOURCE.CORRECTIVE_ATTENDANCE_HISTORY, params) + encodeQueryParams(params), { cancelId: ENDPOINT_PATH.HUMAN_RESOURCE.CORRECTIVE_ATTENDANCE_HISTORY }),
			pendingCorrectiveAttendance: (params) => services.get(stringParamFormatter(ENDPOINT_PATH.HUMAN_RESOURCE.PENDING_CORRECTIVE_ATTENDANCE, params) + encodeQueryParams(params), { cancelId: ENDPOINT_PATH.HUMAN_RESOURCE.PENDING_CORRECTIVE_ATTENDANCE }),
			correctiveAttendanceHistoryGeneral: (params) => services.get(ENDPOINT_PATH.HUMAN_RESOURCE.CORRECTIVE_ATTENDANCE_HISTORY_GENERAL + encodeQueryParams(params), { cancelId: ENDPOINT_PATH.HUMAN_RESOURCE.CORRECTIVE_ATTENDANCE_HISTORY_GENERAL }),
			pendingCorrectiveAttendanceGeneral: (params) => services.get(ENDPOINT_PATH.HUMAN_RESOURCE.PENDING_CORRECTIVE_ATTENDANCE_GENERAL + encodeQueryParams(params), { cancelId: ENDPOINT_PATH.HUMAN_RESOURCE.PENDING_CORRECTIVE_ATTENDANCE_GENERAL })
		},
		workOrder: {
			workOrder: (params) => services.get(ENDPOINT_PATH.WORK_ORDER.WORK_ORDER + encodeQueryParams(params), { cancelId: ENDPOINT_PATH.WORK_ORDER.WORK_ORDER }),
			workOrders: (params) => services.get(ENDPOINT_PATH.WORK_ORDER.WORK_ORDERS + encodeQueryParams(params), { cancelId: ENDPOINT_PATH.WORK_ORDER.WORK_ORDERS }),
			unassignedWorkCompletion: (params) => services.get(ENDPOINT_PATH.WORK_ORDER.UNASSIGNED_WORK_COMPLETION + encodeQueryParams(params), { cancelId: ENDPOINT_PATH.WORK_ORDER.UNASSIGNED_WORK_COMPLETION }),
			unassignedSalesOrder: (params) => services.get(ENDPOINT_PATH.WORK_ORDER.UNASSIGNED_SALES_ORDER + encodeQueryParams(params), { cancelId: ENDPOINT_PATH.WORK_ORDER.UNASSIGNED_SALES_ORDER }),
			teamMembers: (params) => services.get(stringParamFormatter(ENDPOINT_PATH.WORK_ORDER.TEAM_MEMBERS, params) + encodeQueryParams(params), { cancelId: ENDPOINT_PATH.WORK_ORDER.TEAM_MEMBERS }),
			serviceListing: (params) => services.get(stringParamFormatter(ENDPOINT_PATH.WORK_ORDER.SERVICE_LISTING, params) + encodeQueryParams(params), { cancelId: ENDPOINT_PATH.WORK_ORDER.SERVICE_LISTING }),
			recordIds: (params) => services.get(stringParamFormatter(ENDPOINT_PATH.WORK_ORDER.RECORD_IDS, params), { cancelId: ENDPOINT_PATH.WORK_ORDER.RECORD_IDS }),
			viewRecord: (params) => services.get(stringParamFormatter(ENDPOINT_PATH.WORK_ORDER.VIEW_RECORD, params), { cancelId: ENDPOINT_PATH.WORK_ORDER.VIEW_RECORD }),
			inventory: (params) => services.get(ENDPOINT_PATH.WORK_ORDER.INVENTORY + encodeQueryParams(params), { cancelId: ENDPOINT_PATH.WORK_ORDER.INVENTORY }),
			inventories: (params) => services.get(stringParamFormatter(ENDPOINT_PATH.WORK_ORDER.INVENTORIES, params) + encodeQueryParams(params), { cancelId: ENDPOINT_PATH.WORK_ORDER.INVENTORIES })
		},
		workInspectionReport: {
			reports: (params) => services.get(ENDPOINT_PATH.WORK_INSPECTION_REPORT.REPORTS + encodeQueryParams(params), { cancelId: ENDPOINT_PATH.WORK_INSPECTION_REPORT.REPORTS }),
			report: (params) => services.get(ENDPOINT_PATH.WORK_INSPECTION_REPORT.REPORT + encodeQueryParams(params), { cancelId: ENDPOINT_PATH.WORK_INSPECTION_REPORT.REPORT }),
			reportLite: (params) => services.get(stringParamFormatter(ENDPOINT_PATH.WORK_INSPECTION_REPORT.REPORT_LITE, params) + encodeQueryParams(params), { cancelId: ENDPOINT_PATH.WORK_INSPECTION_REPORT.REPORT_LITE }),
			teamMembers: (params) => services.get(stringParamFormatter(ENDPOINT_PATH.WORK_INSPECTION_REPORT.TEAM_MEMBERS, params) + encodeQueryParams(params), { cancelId: ENDPOINT_PATH.WORK_INSPECTION_REPORT.TEAM_MEMBERS }),
			assetListingDropdown: (params) => services.get(ENDPOINT_PATH.WORK_INSPECTION_REPORT.ASSET_LISTING_DROPDOWN + encodeQueryParams(params), { cancelId: ENDPOINT_PATH.WORK_INSPECTION_REPORT.ASSET_LISTING_DROPDOWN }),
			assetFindings: (params) => services.get(stringParamFormatter(ENDPOINT_PATH.WORK_INSPECTION_REPORT.ASSET_FINDINGS, params) + encodeQueryParams(params), { cancelId: ENDPOINT_PATH.WORK_INSPECTION_REPORT.ASSET_FINDINGS }),
			assetListing: (params) => services.get(stringParamFormatter(ENDPOINT_PATH.WORK_INSPECTION_REPORT.ASSET_LISTING, params), encodeQueryParams(params), { cancelId: ENDPOINT_PATH.WORK_INSPECTION_REPORT.ASSET_LISTING }),
			viewAsset: (params) => services.get(ENDPOINT_PATH.WORK_INSPECTION_REPORT.VIEW_ASSET + encodeQueryParams(params), { cancelId: ENDPOINT_PATH.WORK_INSPECTION_REPORT.VIEW_ASSET }),
			viewFinding: (params) => services.get(stringParamFormatter(ENDPOINT_PATH.WORK_INSPECTION_REPORT.VIEW_FINDING, params) + encodeQueryParams(params), { cancelId: ENDPOINT_PATH.WORK_INSPECTION_REPORT.VIEW_FINDING }),
			workOrderReports: (params) => services.get(stringParamFormatter(ENDPOINT_PATH.WORK_INSPECTION_REPORT.WORK_ORDER_REPORTS, params) + encodeQueryParams(params), { cancelId: ENDPOINT_PATH.WORK_INSPECTION_REPORT.WORK_ORDER_REPORTS })
		},
		workCompletionReport: {
			reports: (params) => services.get(ENDPOINT_PATH.WORK_COMPLETION_REPORT.REPORTS + encodeQueryParams(params), { cancelId: ENDPOINT_PATH.WORK_COMPLETION_REPORT.REPORTS }),
			report: (params) => services.get(ENDPOINT_PATH.WORK_COMPLETION_REPORT.REPORT + encodeQueryParams(params), { cancelId: ENDPOINT_PATH.WORK_COMPLETION_REPORT.REPORT }),
			reportWithoutQuotation: (params) => services.get(ENDPOINT_PATH.WORK_COMPLETION_REPORT.REPORT_WITHOUT_QUOTATION + encodeQueryParams(params), { cancelId: ENDPOINT_PATH.WORK_COMPLETION_REPORT.REPORT_WITHOUT_QUOTATION })
		},
		inventory: {
			inventory: (params) => services.get(stringParamFormatter(ENDPOINT_PATH.INVENTORY.INVENTORY, params) + encodeQueryParams(params), { cancelId: ENDPOINT_PATH.INVENTORY.INVENTORY }),
			inventories: (params) => services.get(ENDPOINT_PATH.INVENTORY.INVENTORIES + encodeQueryParams(params), { cancelId: ENDPOINT_PATH.INVENTORY.INVENTORIES }),
			inventoryDoc: (params) => services.get(stringParamFormatter(ENDPOINT_PATH.INVENTORY.INVENTORY, params) + encodeQueryParams(params), { cancelId: ENDPOINT_PATH.INVENTORY.INVENTORY }),
			inventoryRequest: (params) => services.get(stringParamFormatter(ENDPOINT_PATH.INVENTORY.INVENTORY_REQUEST, params) + encodeQueryParams(params), { cancelId: ENDPOINT_PATH.INVENTORY.INVENTORY_REQUEST }),
			inventoryRequests: (params) => services.get(ENDPOINT_PATH.INVENTORY.INVENTORY_REQUESTS + encodeQueryParams(params), { cancelId: ENDPOINT_PATH.INVENTORY.INVENTORY_REQUESTS }),
			inventoryMovement: (params) => services.get(stringParamFormatter(ENDPOINT_PATH.INVENTORY.INVENTORY_MOVEMENT, params) + encodeQueryParams(params), { cancelId: ENDPOINT_PATH.INVENTORY.INVENTORY_MOVEMENT }),
			inventoryMovements: (params) => services.get(ENDPOINT_PATH.INVENTORY.INVENTORY_MOVEMENTS + encodeQueryParams(params), { cancelId: ENDPOINT_PATH.INVENTORY.INVENTORY_MOVEMENTS }),
			configurations: (params) => services.get(ENDPOINT_PATH.INVENTORY.CONFIGURATIONS + encodeQueryParams(params), { cancelId: ENDPOINT_PATH.INVENTORY.CONFIGURATIONS }),
			optionsLocation: (params) => services.get(stringParamFormatter(ENDPOINT_PATH.INVENTORY.OPTION_LOCATION, params), { cancelId: ENDPOINT_PATH.INVENTORY.OPTION_LOCATION }),
			optionsConfiguration: (params) => services.get(ENDPOINT_PATH.INVENTORY.OPTION_CONFIGURATION + encodeQueryParams(params), { cancelId: ENDPOINT_PATH.INVENTORY.OPTION_CONFIGURATION })
		},
		quotation: {
			quotations: (params) => services.get(ENDPOINT_PATH.QUOTATION.QUOTATIONS + encodeQueryParams(params), { cancelId: ENDPOINT_PATH.QUOTATION.QUOTATIONS }),
			quotation: (params) => services.get(stringParamFormatter(ENDPOINT_PATH.QUOTATION.QUOTATION, params), { cancelId: ENDPOINT_PATH.QUOTATION.QUOTATION }),
			quotationItem: (params) => services.get(stringParamFormatter(ENDPOINT_PATH.QUOTATION.QUOTATION_ITEM, params) + encodeQueryParams(params), { cancelId: ENDPOINT_PATH.QUOTATION.QUOTATION_ITEM })
		},
		salesOrders: {
			listings: (params) => services.get(ENDPOINT_PATH.SALES_ORDERS.LISTINGS + encodeQueryParams(params), { cancelId: ENDPOINT_PATH.SALES_ORDERS.LISTINGS }),
			salesOrder: (params) => services.get(stringParamFormatter(ENDPOINT_PATH.SALES_ORDERS.SALES_ORDER, params), { cancelId: ENDPOINT_PATH.SALES_ORDERS.SALES_ORDER }),
			itemListing: (params) => services.get(stringParamFormatter(ENDPOINT_PATH.SALES_ORDERS.SALES_ORDER_ITEM, params) + encodeQueryParams(params), { cancelId: ENDPOINT_PATH.SALES_ORDERS.SALES_ORDER_ITEM })
		},
		deliveryOrders: {
			deliveryOrder: (params) => services.get(stringParamFormatter(ENDPOINT_PATH.DELIVERY_ORDERS.DELIVERY_ORDER, params) + encodeQueryParams(params), { cancelId: ENDPOINT_PATH.DELIVERY_ORDERS.DELIVERY_ORDER }),
			deliveryOrders: (params) => services.get(ENDPOINT_PATH.DELIVERY_ORDERS.DELIVERY_ORDERS + encodeQueryParams(params), { cancelId: ENDPOINT_PATH.DELIVERY_ORDERS.DELIVERY_ORDERS }),
			itemListing: (params) => services.get(stringParamFormatter(ENDPOINT_PATH.DELIVERY_ORDERS.DELIVERY_ORDER_ITEM, params) + encodeQueryParams(params), { cancelId: ENDPOINT_PATH.DELIVERY_ORDERS.DELIVERY_ORDERS }),
			deliveryOrderSearch: (params) => services.get(stringParamFormatter(ENDPOINT_PATH.DELIVERY_ORDERS.DELIVERY_ORDER_SEARCH, params) + encodeQueryParams(params), { cancelId: ENDPOINT_PATH.DELIVERY_ORDERS.DELIVERY_ORDER_SEARCH })
		},
		invoice: {
			invoice: (params) => services.get(ENDPOINT_PATH.INVOICE.INVOICE + encodeQueryParams(params), { cancelId: ENDPOINT_PATH.INVOICE.INVOICE }),
			invoices: (params) => services.get(ENDPOINT_PATH.INVOICE.INVOICES + encodeQueryParams(params), { cancelId: ENDPOINT_PATH.INVOICE.INVOICES }),
			invoiceDoc: (params) => services.get(ENDPOINT_PATH.INVOICE.INVOICE_DOC + encodeQueryParams(params), { cancelId: ENDPOINT_PATH.INVOICE.INVOICE_DOC }),
			salesOrderSearch: (params) => services.get(stringParamFormatter(ENDPOINT_PATH.INVOICE.SALES_ORDER_SEARCH, params) + encodeQueryParams(params), { cancelId: ENDPOINT_PATH.INVOICE.SALES_ORDER_SEARCH }),
			baseItems: (params) => services.get(stringParamFormatter(ENDPOINT_PATH.INVOICE.BASE_ITEMS, params) + encodeQueryParams(params), { cancelId: ENDPOINT_PATH.INVOICE.BASE_ITEMS }),
			confirmedItems: (params) => services.get(stringParamFormatter(ENDPOINT_PATH.INVOICE.CONFIRMED_ITEMS, params) + encodeQueryParams(params), { cancelId: ENDPOINT_PATH.INVOICE.CONFIRMED_ITEMS })
		}
	},
	post: {
		authenticate: {
			login: (payload) => services.post(ENDPOINT_PATH.AUTHENTICATE.SIGN_IN, payload),
			refreshToken: (payload) => services.post(ENDPOINT_PATH.AUTHENTICATE.REFRESH_TOKEN, payload, { ...publicToken })
		},
		forgotPassword: {
			forgotPassword: (payload) => services.post(ENDPOINT_PATH.FORGOT_PASSWORD.FORGOT_PASSWORD, payload),
			verifyEmail: (payload, headers) => services.post(ENDPOINT_PATH.FORGOT_PASSWORD.VERIFY_EMAIl, payload, { ...headers, cancelId: ENDPOINT_PATH.FORGOT_PASSWORD.VERIFY_EMAIl }),
			updatePassword: (payload, headers) => services.post(ENDPOINT_PATH.FORGOT_PASSWORD.UPDATE_PASSWORD, payload, { ...headers })
		},
		profile: {
			profile: (payload) => services.post(ENDPOINT_PATH.PROFILE.CHANGE_PASSWORD, payload)
		},
		account: {
			otpCodeVerify: (payload) => services.post(ENDPOINT_PATH.ACCOUNT.OTP_CODE_VERIFY, payload),
			otpResend: () => services.post(ENDPOINT_PATH.ACCOUNT.OTP_RESEND)
		},
		customerMobileUser: {
			verifyEmail: (payload, headers) => services.post(ENDPOINT_PATH.CUSTOMER_MOBILE_USER.VERIFY_EMAIl, payload, { ...headers, cancelId: ENDPOINT_PATH.CUSTOMER_MOBILE_USER.VERIFY_EMAIl }),
			deactivateAccount: (payload, headers) => services.post(ENDPOINT_PATH.CUSTOMER_MOBILE_USER.DEACTIVATE_ACCOUNT, payload, { ...headers })
		},
		employeeMobileUser: {
			verifyEmail: (payload, headers) => services.post(ENDPOINT_PATH.EMPLOYEE_MOBILE_USER.VERIFY_EMAIl, payload, { ...headers, cancelId: ENDPOINT_PATH.EMPLOYEE_MOBILE_USER.VERIFY_EMAIl }),
			deactivateAccount: (payload, headers) => services.post(ENDPOINT_PATH.EMPLOYEE_MOBILE_USER.DEACTIVATE_ACCOUNT, payload, { ...headers })
		},
		customerConfiguration: {
			createBuildingType: (payload) => services.post(ENDPOINT_PATH.CUSTOMER_CONFIGURATION.CREATE_BUILDING_TYPE, payload),
			updateBuildingType: (payload) => services.post(ENDPOINT_PATH.CUSTOMER_CONFIGURATION.UPDATE_BUILDING_TYPE, payload),
			createWorkspace: (payload) => services.post(ENDPOINT_PATH.CUSTOMER_CONFIGURATION.CREATE_WORKSPACE, payload),
			updateWorkspace: (payload) => services.post(ENDPOINT_PATH.CUSTOMER_CONFIGURATION.UPDATE_WORKSPACE, payload),
			createAssetType: (payload) => services.post(ENDPOINT_PATH.CUSTOMER_CONFIGURATION.CREATE_ASSET_TYPE, payload),
			updateAssetType: (payload) => services.post(ENDPOINT_PATH.CUSTOMER_CONFIGURATION.UPDATE_ASSET_TYPE, payload)
		},
		assetMaintenance: {
			updateAsset: (payload) => services.post(stringParamFormatter(ENDPOINT_PATH.ASSET_MAINTENANCE.UPDATE_ASSET, payload), payload),
			deleteAsset: (params) => services.post(stringParamFormatter(ENDPOINT_PATH.ASSET_MAINTENANCE.DELETE_ASSET, params)),
			assetServicesReorder: (payload) => services.post(stringParamFormatter(ENDPOINT_PATH.ASSET_MAINTENANCE.ASSET_SERVICES_REORDER, payload), payload.checkLists),
			addAssetServices: (payload) => services.post(stringParamFormatter(ENDPOINT_PATH.ASSET_MAINTENANCE.ADD_ASSET_SERVICES, payload), payload.assetServiceIds),
			addAssetServiceChecklist: (payload) => services.post(stringParamFormatter(ENDPOINT_PATH.ASSET_MAINTENANCE.ADD_ASSET_SERVICE_CHECKLIST, payload), payload.serviceChecklistIds),
			createAssetService: (payload) => services.post(stringParamFormatter(ENDPOINT_PATH.ASSET_MAINTENANCE.CREATE_ASSET_SERVICE, payload), payload.assetServices),
			updateAssetServices: (payload) => services.post(stringParamFormatter(ENDPOINT_PATH.ASSET_MAINTENANCE.UPDATE_ASSET_SERVICES, payload), payload),
			updateAssetService: (payload) => services.post(stringParamFormatter(ENDPOINT_PATH.ASSET_MAINTENANCE.UPDATE_ASSET_SERVICE, payload), payload.assetServices),
			updateAssetServiceStatus: (payload) => services.post(stringParamFormatter(ENDPOINT_PATH.ASSET_MAINTENANCE.UPDATE_ASSET_SERVICE_STATUS, payload), payload),
			generateAssetPdf: (payload) => services.post(ENDPOINT_PATH.ASSET_MAINTENANCE.GENERATE_ASSET_PDF, payload)
		},
		customerEnquiry: {
			downloadDoc: (payload) => services.post(stringParamFormatter(ENDPOINT_PATH.CUSTOMER_ENQUIRY.DOWNLOAD_DOC, payload)),
			updateEnquiry: (payload) => services.post(ENDPOINT_PATH.CUSTOMER_ENQUIRY.UPDATE_ENQUIRY, payload),
			updateConfiguration: (payload) => services.post(ENDPOINT_PATH.CUSTOMER_ENQUIRY.CONFIGURATION, payload)
		},
		customer: {
			createCustomer: (payload) => services.post(ENDPOINT_PATH.CUSTOMER.CREATE_CUSTOMER, payload),
			updateCustomer: (payload) => services.post(ENDPOINT_PATH.CUSTOMER.UPDATE_CUSTOMER, payload),
			createCustomerPic: (payload) => services.post(ENDPOINT_PATH.CUSTOMER.CREATE_CUSTOMER_PIC, payload),
			updateCustomerPic: (payload) => services.post(ENDPOINT_PATH.CUSTOMER.UPDATE_CUSTOMER_PIC, payload),
			createUser: (payload) => services.post(ENDPOINT_PATH.CUSTOMER.CREATE_USER, payload),
			createContract: (payload) => services.post(ENDPOINT_PATH.CUSTOMER.CREATE_CONTRACT, payload),
			updateContract: (payload) => services.post(ENDPOINT_PATH.CUSTOMER.UPDATE_CONTRACT, payload),
			addAllContractPic: (payload) => services.post(ENDPOINT_PATH.CUSTOMER.ADD_ALL_CONTRACTS_PIC, payload),
			deleteContractPic: (payload) => services.post(stringParamFormatter(ENDPOINT_PATH.CUSTOMER.DELETE_CONTRACT_PIC, payload)),
			uploadDoc: (payload) => services.post(stringParamFormatter(ENDPOINT_PATH.CUSTOMER.UPLOAD_DOC, payload.queryParam), payload.files, { ...multipart }),
			deleteDoc: (payload) => services.post(stringParamFormatter(ENDPOINT_PATH.CUSTOMER.DELETE_DOC, payload)),
			downloadDoc: (payload) => services.post(stringParamFormatter(ENDPOINT_PATH.CUSTOMER.DOWNLOAD_DOC, payload))
		},
		customerSiteMaintenance: {
			createSite: (payload) => services.post(ENDPOINT_PATH.CUSTOMER_SITE_MAINTENANCE.CREATE_SITE, payload),
			updateSite: (payload) => services.post(ENDPOINT_PATH.CUSTOMER_SITE_MAINTENANCE.UPDATE_SITE, payload),
			uploadAssets: (params, payload) => services.post(stringParamFormatter(ENDPOINT_PATH.CUSTOMER_SITE_MAINTENANCE.UPLOAD_ASSETS, params), payload.files, { ...multipart }),
			addAllSitePic: (payload) => services.post(ENDPOINT_PATH.CUSTOMER_SITE_MAINTENANCE.ADD_ALL_SITE_PIC, payload),
			deleteSitePic: (params) => services.post(stringParamFormatter(ENDPOINT_PATH.CUSTOMER_SITE_MAINTENANCE.DELETE_SITE_PIC, params))
		},
		mobile: {
			approval: (payload) => services.post(ENDPOINT_PATH.MOBILE.APPROVAL, payload),
			changeUserStatus: (payload) => services.post(stringParamFormatter(ENDPOINT_PATH.MOBILE.CHANGE_USER_STATUS, payload)),
			changePassword: (payload) => services.post(ENDPOINT_PATH.MOBILE.CHANGE_PASSWORD, payload)
		},
		sparePart: {
			createSparePart: (payload) => services.post(ENDPOINT_PATH.SPARE_PART.CREATE_SPARE_PART, payload),
			updateSparePart: (payload) => services.post(ENDPOINT_PATH.SPARE_PART.UPDATE_SPARE_PART, payload),
			uploadDoc: (payload) => services.post(stringParamFormatter(ENDPOINT_PATH.SPARE_PART.UPLOAD_DOC, payload), payload.files, { ...multipart }),
			downloadDoc: (payload) => services.post(stringParamFormatter(ENDPOINT_PATH.SPARE_PART.DOWNLOAD_DOC, payload)),
			addAllServiceConfig: (payload) => services.post(ENDPOINT_PATH.SPARE_PART.SERVICE_CONFIG_ADD_ALL, payload),
			removeServiceConfig: (payload) => services.post(ENDPOINT_PATH.SPARE_PART.REMOVE_SERVICE_CONFIG, payload)
		},
		serviceListing: {
			createServiceListing: (payload) => services.post(ENDPOINT_PATH.SERVICE_LISTING.CREATE_SERVICE_LISTING, payload),
			updateServiceListing: (payload) => services.post(ENDPOINT_PATH.SERVICE_LISTING.UPDATE_SERVICE_LISTING, payload),
			exclusiveIdSearch: (payload) => services.post(ENDPOINT_PATH.SERVICE_LISTING.EXCLUSIVE_ID_SEARCH, payload),
			createServiceSubtask: (payload) => services.post(ENDPOINT_PATH.SERVICE_LISTING.CREATE_SUBTASK, payload),
			updateServiceSubtask: (payload) => services.post(ENDPOINT_PATH.SERVICE_LISTING.UPDATE_SUBTASK, payload),
			reorderServiceSubtask: (payload) => services.post(stringParamFormatter(ENDPOINT_PATH.SERVICE_LISTING.REORDER_SUBTASK, payload), payload),
			deleteServiceSubtask: (payload) => services.post(ENDPOINT_PATH.SERVICE_LISTING.DELETE_SUBTASK, payload)
		},
		serviceChecklist: {
			createServiceChecklist: (payload) => services.post(ENDPOINT_PATH.SERVICE_CHECKLIST.CREATE_SERVICE_CHECKLIST, payload),
			updateServiceChecklist: (payload) => services.post(ENDPOINT_PATH.SERVICE_CHECKLIST.UPDATE_SERVICE_CHECKLIST, payload),
			addAllServiceConfig: (payload) => services.post(ENDPOINT_PATH.SERVICE_CHECKLIST.SERVICE_CONFIG_ADD_ALL, payload),
			removeServiceConfig: (payload) => services.post(ENDPOINT_PATH.SERVICE_CHECKLIST.REMOVE_SERVICE_CONFIG, payload),
			reorderServiceConfig: (payload) => services.post(ENDPOINT_PATH.SERVICE_CHECKLIST.REORDER_SERVICE_CONFIG, payload)
		},
		contract: {
			createContract: (payload) => services.post(ENDPOINT_PATH.CONTRACT.CREATE_CONTRACT, payload),
			updateContract: (payload) => services.post(ENDPOINT_PATH.CONTRACT.UPDATE_CONTRACT, payload)
		},
		userAccess: {
			createRole: (payload) => services.post(ENDPOINT_PATH.USER_ACCESS.CREATE_ROLE, payload),
			updateRole: (payload) => services.post(ENDPOINT_PATH.USER_ACCESS.UPDATE_ROLE, payload),
			createUser: (payload) => services.post(ENDPOINT_PATH.USER_ACCESS.CREATE_USER, payload),
			updateUser: (payload) => services.post(ENDPOINT_PATH.USER_ACCESS.UPDATE_USER, payload),
			changePassword: (payload) => services.post(ENDPOINT_PATH.USER_ACCESS.CHANGE_PASSWORD, payload),
			updateAvatar: (payload) => services.post(ENDPOINT_PATH.USER_ACCESS.UPDATE_AVATAR, payload, { ...multipart })
		},
		input: {
			createInput: (payload) => services.post(ENDPOINT_PATH.INPUT.CREATE_INPUT, payload),
			updateInput: (payload) => services.post(ENDPOINT_PATH.INPUT.UPDATE_INPUT, payload)
		},
		sparePartCategory: {
			createSparePartCategory: (payload) => services.post(ENDPOINT_PATH.SPARE_PART_CATEGORY.CREATE_SPARE_PART_CATEGORY, payload),
			updateSparePartCategory: (payload) => services.post(ENDPOINT_PATH.SPARE_PART_CATEGORY.UPDATE_SPARE_PART_CATEGORY, payload)
		},
		sparePartSubCategory: {
			createSparePartSubCategory: (payload) => services.post(ENDPOINT_PATH.SPARE_PART_SUB_CATEGORY.CREATE_SPARE_PART_SUB_CATEGORY, payload),
			updateSparePartSubCategory: (payload) => services.post(ENDPOINT_PATH.SPARE_PART_SUB_CATEGORY.UPDATE_SPARE_PART_SUB_CATEGORY, payload)
		},
		humanResource: {
			createPosition: (payload) => services.post(ENDPOINT_PATH.HUMAN_RESOURCE.CREATE_POSITION, payload),
			updatePosition: (payload) => services.post(ENDPOINT_PATH.HUMAN_RESOURCE.UPDATE_POSITION, payload),
			createLeaveType: (payload) => services.post(ENDPOINT_PATH.HUMAN_RESOURCE.CREATE_LEAVE_TYPE, payload),
			updateLeaveType: (payload) => services.post(ENDPOINT_PATH.HUMAN_RESOURCE.UPDATE_LEAVE_TYPE, payload),
			createLevel: (payload) => services.post(ENDPOINT_PATH.HUMAN_RESOURCE.CREATE_LEVEL, payload),
			updateLevel: (payload) => services.post(ENDPOINT_PATH.HUMAN_RESOURCE.UPDATE_LEVEL, payload),
			createDepartment: (payload) => services.post(ENDPOINT_PATH.HUMAN_RESOURCE.CREATE_DEPARTMENT, payload),
			updateDepartment: (payload) => services.post(ENDPOINT_PATH.HUMAN_RESOURCE.UPDATE_DEPARTMENT, payload),
			createEmployee: (payload) => services.post(ENDPOINT_PATH.HUMAN_RESOURCE.CREATE_EMPLOYEE, payload),
			updateEmployee: (payload) => services.post(ENDPOINT_PATH.HUMAN_RESOURCE.UPDATE_EMPLOYEE, payload),
			createClaimType: (payload) => services.post(ENDPOINT_PATH.HUMAN_RESOURCE.CREATE_CLAIM_TYPE, payload),
			updateClaimType: (payload) => services.post(ENDPOINT_PATH.HUMAN_RESOURCE.UPDATE_CLAIM_TYPE, payload),
			createHoliday: (payload) => services.post(ENDPOINT_PATH.HUMAN_RESOURCE.CREATE_HOLIDAY, payload),
			updateHoliday: (payload) => services.post(ENDPOINT_PATH.HUMAN_RESOURCE.UPDATE_HOLIDAY, payload),
			deleteHoliday: (payload) => services.post(stringParamFormatter(ENDPOINT_PATH.HUMAN_RESOURCE.DELETE_HOLIDAY, payload)),
			createLeavePackage: (payload) => services.post(ENDPOINT_PATH.HUMAN_RESOURCE.CREATE_LEAVE_PACKAGES, payload),
			updateLeavePackage: (payload) => services.post(ENDPOINT_PATH.HUMAN_RESOURCE.UPDATE_LEAVE_PACKAGES, payload),
			createClaimPackage: (payload) => services.post(ENDPOINT_PATH.HUMAN_RESOURCE.CREATE_CLAIM_PACKAGES, payload),
			updateClaimPackage: (payload) => services.post(ENDPOINT_PATH.HUMAN_RESOURCE.UPDATE_CLAIM_PACKAGES, payload),
			downloadLeaveDoc: (payload) => services.post(stringParamFormatter(ENDPOINT_PATH.HUMAN_RESOURCE.DOWNLOAD_DOC_LEAVE, payload)),
			downloadAllLeaveDoc: (payload) => services.post(ENDPOINT_PATH.HUMAN_RESOURCE.DOWNLOAD_ALL_DOC_LEAVE, payload),
			approvePendingLeave: (payload) => services.post(ENDPOINT_PATH.HUMAN_RESOURCE.APPROVE_PENDING_LEAVE, payload),
			rejectPendingLeave: (payload) => services.post(ENDPOINT_PATH.HUMAN_RESOURCE.REJECT_PENDING_LEAVE, payload),
			approveEmployeeLeave: (payload) => services.post(stringParamFormatter(ENDPOINT_PATH.HUMAN_RESOURCE.APPROVE_EMPLOYEE_LEAVE, payload)),
			rejectEmployeeLeave: (payload) => services.post(stringParamFormatter(ENDPOINT_PATH.HUMAN_RESOURCE.REJECT_EMPLOYEE_LEAVE, payload), payload),
			downloadClaimDoc: (payload) => services.post(stringParamFormatter(ENDPOINT_PATH.HUMAN_RESOURCE.DOWNLOAD_DOC_CLAIM, payload)),
			downloadAllClaimDoc: (payload) => services.post(ENDPOINT_PATH.HUMAN_RESOURCE.DOWNLOAD_ALL_DOC_CLAIM, payload),
			approvePendingClaim: (payload) => services.post(ENDPOINT_PATH.HUMAN_RESOURCE.APPROVE_PENDING_CLAIM, payload),
			rejectPendingClaim: (payload) => services.post(ENDPOINT_PATH.HUMAN_RESOURCE.REJECT_PENDING_CLAIM, payload),
			approveEmployeeClaim: (payload) => services.post(stringParamFormatter(ENDPOINT_PATH.HUMAN_RESOURCE.APPROVE_EMPLOYEE_CLAIM, payload)),
			rejectEmployeeClaim: (payload) => services.post(stringParamFormatter(ENDPOINT_PATH.HUMAN_RESOURCE.REJECT_EMPLOYEE_CLAIM, payload), payload),
			paidEmployeeClaim: (payload) => services.post(stringParamFormatter(ENDPOINT_PATH.HUMAN_RESOURCE.PAID_EMPLOYEE_CLAIM, payload)),
			deleteLeavePackage: (params) => services.post(stringParamFormatter(ENDPOINT_PATH.HUMAN_RESOURCE.DELETE_LEAVE_PACKAGES, params)),
			deleteClaimPackage: (params) => services.post(stringParamFormatter(ENDPOINT_PATH.HUMAN_RESOURCE.DELETE_CLAIM_PACKAGES, params)),
			scheduledAttendanceEmployee: (params) => services.post(ENDPOINT_PATH.HUMAN_RESOURCE.SCHEDULED_ATTENDANCE_EMPLOYEE, params, { cancelId: ENDPOINT_PATH.HUMAN_RESOURCE.SCHEDULED_ATTENDANCE_EMPLOYEE }),
			updateScheduledAttendanceEmployee: (params) => services.post(ENDPOINT_PATH.HUMAN_RESOURCE.UPDATE_SCHEDULED_ATTENDANCE_EMPLOYEE, params),
			approveCorrectiveAttendanceStatus: (params) => services.post(ENDPOINT_PATH.HUMAN_RESOURCE.APPROVE_CORRECTIVE_ATTENDANCE_STATUS, params),
			rejectCorrectiveAttendanceStatus: (params) => services.post(ENDPOINT_PATH.HUMAN_RESOURCE.REJECT_CORRECTIVE_ATTENDANCE_STATUS, params),
			uploadSignature: (payload) => services.post(ENDPOINT_PATH.HUMAN_RESOURCE.EMPLOYEE_SIGNATURE_UPLOAD, payload, { ...multipart }),
			viewSignature: (params) => services.post(ENDPOINT_PATH.HUMAN_RESOURCE.EMPLOYEE_SIGNATURE_VIEW, params, { cancelId: ENDPOINT_PATH.HUMAN_RESOURCE.EMPLOYEE_SIGNATURE_VIEW })
		},
		workOrder: {
			generateWorkOrderPdf: (payload, headers) => services.post(stringParamFormatter(ENDPOINT_PATH.WORK_ORDER.GENERATE_WORK_ORDER_PDF, payload), payload, { ...headers, responseType: "arraybuffer", cancelId: ENDPOINT_PATH.WORK_ORDER.GENERATE_WORK_ORDER_PDF }),
			createWorkOrderReport: (payload) => services.post(ENDPOINT_PATH.WORK_ORDER.CREATE, payload),
			updateWorkOrderReport: (payload) => services.post(ENDPOINT_PATH.WORK_ORDER.UPDATE, payload),
			signatureUpload: (payload) => services.post(ENDPOINT_PATH.WORK_ORDER.SIGNATURE_UPLOAD, payload, { ...multipart }),
			addTeamMembers: (params) => services.post(ENDPOINT_PATH.WORK_ORDER.ADD_TEAM_MEMBERS, params),
			deleteTeamMember: (params) => services.post(ENDPOINT_PATH.WORK_ORDER.DELETE_TEAM_MEMBER + encodeQueryParams(params)),
			service: (params) => services.post(ENDPOINT_PATH.WORK_ORDER.SERVICE, params),
			createRecord: (payload) => services.post(ENDPOINT_PATH.WORK_ORDER.CREATE_RECORD, payload),
			updateRecord: (payload) => services.post(ENDPOINT_PATH.WORK_ORDER.UPDATE_RECORD, payload),
			uploadRecordAttachment: (payload) => services.post(ENDPOINT_PATH.WORK_ORDER.UPLOAD_RECORD_ATTACHMENT, payload, { ...multipart }),
			deleteRecord: (payload) => services.post(stringParamFormatter(ENDPOINT_PATH.WORK_ORDER.DELETE_RECORD, payload)),
			deleteRecordAttachment: (payload) => services.post(stringParamFormatter(ENDPOINT_PATH.WORK_ORDER.DELETE_RECORD_ATTACHMENT, payload)),
			downloadAttachment: (payload) => services.post(stringParamFormatter(ENDPOINT_PATH.WORK_ORDER.DOWNLOAD_ATTACHMENT, payload)),
			createInventory: (payload) => services.post(ENDPOINT_PATH.WORK_ORDER.CREATE_INVENTORY, payload),
			updateInventory: (payload) => services.post(ENDPOINT_PATH.WORK_ORDER.UPDATE_INVENTORY, payload),
			deleteInventory: (payload) => services.post(stringParamFormatter(ENDPOINT_PATH.WORK_ORDER.DELETE_INVENTORY, payload), payload)
		},
		workInspectionReport: {
			generateWorkInspectionReportPdf: (payload, headers) => services.post(stringParamFormatter(ENDPOINT_PATH.WORK_INSPECTION_REPORT.GENERATE_WORK_INSPECTION_REPORT_PDF, payload), payload, { ...headers, responseType: "arraybuffer", cancelId: ENDPOINT_PATH.WORK_INSPECTION_REPORT.GENERATE_WORK_INSPECTION_REPORT_PDF }),
			createWorkInspectionReport: (payload) => services.post(ENDPOINT_PATH.WORK_INSPECTION_REPORT.CREATE, payload),
			updateWorkInspectionReport: (payload) => services.post(ENDPOINT_PATH.WORK_INSPECTION_REPORT.UPDATE, payload),
			signatureUpload: (payload) => services.post(ENDPOINT_PATH.WORK_INSPECTION_REPORT.SIGNATURE_UPLOAD, payload, { ...multipart }),
			assets: (payload) => services.post(ENDPOINT_PATH.WORK_INSPECTION_REPORT.ASSETS, payload),
			addTeamMembers: (payload) => services.post(ENDPOINT_PATH.WORK_INSPECTION_REPORT.ADD_TEAM_MEMBER, payload),
			deleteTeamMember: (params) => services.post(ENDPOINT_PATH.WORK_INSPECTION_REPORT.DELETE_TEAM_MEMBER + encodeQueryParams(params)),
			createAssetFinding: (payload) => services.post(ENDPOINT_PATH.WORK_INSPECTION_REPORT.CREATE_ASSET_FINDING, payload),
			updateAssetFinding: (payload) => services.post(ENDPOINT_PATH.WORK_INSPECTION_REPORT.UPDATE_ASSET_FINDING, payload),
			uploadAssetFindingAttachment: (payload) => services.post(ENDPOINT_PATH.WORK_INSPECTION_REPORT.UPLOAD_ASSET_FINDING_ATTACHMENT, payload, { ...multipart }),
			deleteAssetFinding: (payload) => services.post(stringParamFormatter(ENDPOINT_PATH.WORK_INSPECTION_REPORT.DELETE_ASSET_FINDING, payload)),
			deleteFindingAttachment: (payload) => services.post(stringParamFormatter(ENDPOINT_PATH.WORK_INSPECTION_REPORT.DELETE_FINDING_ATTACHMENT, payload)),
			downloadWorkInspectionReportDoc: (payload) => services.post(stringParamFormatter(ENDPOINT_PATH.WORK_INSPECTION_REPORT.DOWNLOAD_WIR_DOC, payload)),
			autoConvert: (payload) => services.post(ENDPOINT_PATH.WORK_INSPECTION_REPORT.AUTO_CONVERT, payload)
		},
		workCompletionReport: {
			generateWorkCompletionReportPdf: (payload, headers) => services.post(stringParamFormatter(ENDPOINT_PATH.WORK_COMPLETION_REPORT.GENERATE_WORK_COMPLETION_REPORT_PDF, payload), payload, { ...headers, responseType: "arraybuffer", cancelId: ENDPOINT_PATH.WORK_COMPLETION_REPORT.GENERATE_WORK_COMPLETION_REPORT_PDF }),
			createWorkCompletionReport: (payload) => services.post(ENDPOINT_PATH.WORK_COMPLETION_REPORT.CREATE_WORK_COMPLETION_REPORT, payload),
			updateWorkCompletionReport: (payload) => services.post(ENDPOINT_PATH.WORK_COMPLETION_REPORT.UPDATE_WORK_COMPLETION_REPORT, payload),
			signatureUpload: (payload) => services.post(ENDPOINT_PATH.WORK_COMPLETION_REPORT.SIGNATURE_UPLOAD, payload, { ...multipart })
		},
		inventory: {
			createInventory: (payload) => services.post(ENDPOINT_PATH.INVENTORY.CREATE_INVENTORY, payload),
			updateInventory: (payload) => services.post(ENDPOINT_PATH.INVENTORY.UPDATE_INVENTORY, payload),
			transferInventory: (payload) => services.post(ENDPOINT_PATH.INVENTORY.TRANSFER_INVENTORY, payload),
			retireInventory: (payload) => services.post(stringParamFormatter(ENDPOINT_PATH.INVENTORY.RETIRE_INVENTORY, payload)),
			createConfiguration: (payload) => services.post(ENDPOINT_PATH.INVENTORY.CREATE_CONFIGURATION, payload),
			updateConfiguration: (payload) => services.post(ENDPOINT_PATH.INVENTORY.UPDATE_CONFIGURATION, payload),
			updateStock: (payload) => services.post(ENDPOINT_PATH.INVENTORY.UPDATE_STOCK, payload),
			uploadDoc: (payload) => services.post(stringParamFormatter(ENDPOINT_PATH.INVENTORY.UPLOAD_DOC, payload.queryParam), payload.files, { ...multipart }),
			updateRequest: (payload) => services.post(ENDPOINT_PATH.INVENTORY.UPDATE_REQUEST, payload)
		},
		quotation: {
			createQuotation: (payload) => services.post(ENDPOINT_PATH.QUOTATION.CREATE_QUOTATION, payload),
			createItem: (payload) => services.post(ENDPOINT_PATH.QUOTATION.CREATE_ITEM, payload),
			createSubItem: (payload) => services.post(ENDPOINT_PATH.QUOTATION.CREATE_SUB_ITEM, payload),
			updateQuotation: (payload) => services.post(ENDPOINT_PATH.QUOTATION.UPDATE_QUOTATION, payload),
			updateItem: (payload) => services.post(ENDPOINT_PATH.QUOTATION.UPDATE_ITEM, payload),
			updateSubItem: (payload) => services.post(ENDPOINT_PATH.QUOTATION.UPDATE_SUB_ITEM, payload),
			removeItem: (payload) => services.post(stringParamFormatter(ENDPOINT_PATH.QUOTATION.REMOVE_ITEM, payload)),
			removeSubItem: (payload) => services.post(stringParamFormatter(ENDPOINT_PATH.QUOTATION.REMOVE_SUB_ITEM, payload)),
			clone: (payload) => services.post(ENDPOINT_PATH.QUOTATION.CLONE, payload),
			close: (payload) => services.post(stringParamFormatter(ENDPOINT_PATH.QUOTATION.CLOSE, payload)),
			generateQuotationPdf: (payload, headers) => services.post(stringParamFormatter(ENDPOINT_PATH.QUOTATION.GENERATE_QUOTATION_PDF, payload), payload, { ...headers, responseType: "arraybuffer", cancelId: ENDPOINT_PATH.QUOTATION.GENERATE_QUOTATION_PDF })
		},
		salesOrders: {
			convert: (payload) => services.post(ENDPOINT_PATH.SALES_ORDERS.CONVERT, payload),
			update: (payload) => services.post(ENDPOINT_PATH.SALES_ORDERS.UPDATE, payload),
			removeItem: (payload) => services.post(stringParamFormatter(ENDPOINT_PATH.SALES_ORDERS.REMOVE_ITEM, payload)),
			removeSubItem: (payload) => services.post(stringParamFormatter(ENDPOINT_PATH.SALES_ORDERS.REMOVE_SUB_ITEM, payload)),
			createItem: (payload) => services.post(ENDPOINT_PATH.SALES_ORDERS.CREATE_ITEM, payload),
			createSubItem: (payload) => services.post(ENDPOINT_PATH.SALES_ORDERS.CREATE_SUB_ITEM, payload),
			updateItem: (payload) => services.post(ENDPOINT_PATH.SALES_ORDERS.UPDATE_ITEM, payload),
			updateSubItem: (payload) => services.post(ENDPOINT_PATH.SALES_ORDERS.UPDATE_SUB_ITEM, payload),
			salesOrderDropOff: (payload) => services.post(ENDPOINT_PATH.SALES_ORDERS.SALES_ORDER_DROP_OFF + encodeQueryParams(payload), payload),
			generateSalesOrderPdf: (payload, headers) => services.post(stringParamFormatter(ENDPOINT_PATH.SALES_ORDERS.GENERATE_SALES_ORDER_PDF, payload), payload, { ...headers, responseType: "arraybuffer", cancelId: ENDPOINT_PATH.SALES_ORDERS.GENERATE_SALES_ORDER_PDF })
		},
		deliveryOrders: {
			createDeliveryOrder: (payload) => services.post(ENDPOINT_PATH.DELIVERY_ORDERS.CREATE_DELIVERY_ORDER, payload),
			updateDeliveryOrder: (payload) => services.post(ENDPOINT_PATH.DELIVERY_ORDERS.UPDATE_DELIVERY_ORDER, payload),
			updateDeliveryOrderStatus: (payload) => services.post(ENDPOINT_PATH.DELIVERY_ORDERS.UPDATE_DELIVERY_ORDER_STATUS, payload),
			generateDeliveryOrderPdf: (payload, headers) => services.post(stringParamFormatter(ENDPOINT_PATH.DELIVERY_ORDERS.GENERATE_DELIVERY_ORDER_PDF, payload), payload, { ...headers, responseType: "arraybuffer", cancelId: ENDPOINT_PATH.DELIVERY_ORDERS.GENERATE_DELIVERY_ORDER_PDF })
		},
		invoice: {
			createInvoice: (payload) => services.post(ENDPOINT_PATH.INVOICE.CREATE_INVOICE + encodeQueryParams(payload)),
			updateInvoice: (payload) => services.post(ENDPOINT_PATH.INVOICE.UPDATE_INVOICE, payload),
			invoicePaid: (payload) => services.post(stringParamFormatter(ENDPOINT_PATH.INVOICE.INVOICE_PAID, payload) + encodeQueryParams(payload)),
			invoicePaidMultiPart: (payload) => services.post(stringParamFormatter(ENDPOINT_PATH.INVOICE.INVOICE_PAID, payload.queryParam), payload.payload, { ...multipart }),
			invoiceVoid: (payload) => services.post(ENDPOINT_PATH.INVOICE.INVOICE_VOID + encodeQueryParams(payload), payload),
			downloadPaidInvoice: (payload) => services.post(stringParamFormatter(ENDPOINT_PATH.INVOICE.DOWNLOAD_PAID_INVOICE, payload) + encodeQueryParams(payload)),
			generateInvoicePdf: (payload, headers) => services.post(stringParamFormatter(ENDPOINT_PATH.INVOICE.GENERATE_INVOICE_PDF, payload), payload, { ...headers, responseType: "arraybuffer", cancelId: ENDPOINT_PATH.INVOICE.GENERATE_INVOICE_PDF })
		}
	},
	patch: {},
	put: {}
};

export default api;
