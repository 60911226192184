const timeDifferences = (start, end) => {
	if (!start || !end) {
		return "";
	}

	const startTime = new Date(start);
	const endTime = new Date(end);
	const diffInMs = endTime - startTime;

	if (diffInMs < 0) {
		return "";
	}

	const diffInHours = Math.floor(diffInMs / (1000 * 60 * 60));
	const diffInMinutes = Math.floor((diffInMs % (1000 * 60 * 60)) / (1000 * 60));

	return `${diffInHours} hour(s) and ${diffInMinutes} minute(s)`;
};

export default timeDifferences;
