const sanitizeUserPermissions = (role) => {
	const permissions = [];

	role.permission?.forEach((permission) => {
		permissions.push(permission);

		if (permission.child?.length > 0) {
			permissions.push(...permission.child);
		}
	});

	return permissions;
};

const roleAccessible = (roles) => {
	const userPermissions = roles.reduce((allPermissions, role) => {
		const rolePermissions = sanitizeUserPermissions(role).map((o) => o.name);

		return [...allPermissions, ...rolePermissions];
	}, []);

	const uniqueModules = new Set();
	
	roles.forEach((role) => {
		role.permission?.forEach((perm) => {
			uniqueModules.add(perm.name);
		});
	});

	const permissions = {};

	uniqueModules.forEach((moduleName) => {
		permissions[moduleName] = {
			view: userPermissions.includes(moduleName + ":view"),
			create: userPermissions.includes(moduleName + ":create"),
			update: userPermissions.includes(moduleName + ":update"),
			delete: userPermissions.includes(moduleName + ":delete"),
			export: userPermissions.includes(moduleName + ":export"),
			download: userPermissions.includes(moduleName + ":download"),
			upload: userPermissions.includes(moduleName + ":upload"),
			approve: userPermissions.includes(moduleName + ":approve"),
			decline: userPermissions.includes(moduleName + ":decline"),
			paid: userPermissions.includes(moduleName + ":paid")
		};
	});

	return { permissions };
};

export default roleAccessible;
