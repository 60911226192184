import React, { forwardRef, memo, useCallback, useImperativeHandle, useMemo, useState } from "react";

import * as yup from "yup";
import { useFormik } from "formik";
import PropTypes from "prop-types";
import { Modal } from "@mui/material";
import { useSelector } from "react-redux";
import { useParams } from "react-router-dom";

import getGenderListing from "services/get-gender-listing";
import getIdTypeListing from "services/get-id-type-listing";
import getNationalityListing from "services/get-nationality-listing";

import classNames from "common/class-names";
import formatPassportString from "common/format-passport-string";

import PAGE from "constants/page";
import REGEX from "constants/regex";
import ROLES from "constants/roles";
import ERRORS from "constants/errors";
import STATUS from "constants/status";
import MASKING from "constants/masking";

import AppIcon from "components/app-icon";
import AppInput from "components/app-input";
import AppButton from "components/app-button";
import AppCheckbox from "components/app-checkbox";
import AppMobileInput from "components/app-mobile-input";
import AppSelectInput from "components/app-select-input";
import AppMaskingInput from "components/app-masking-input";

import closeIcon from "assets/images/close-icon.png";

export const AppCustomerAddPicModal = (props, ref) => {
	const { id } = useParams();
	const [visible, setVisible] = useState(false);
	const profile = useSelector((state) => state.profile);
	const accessible = useMemo(() => profile?.permissions?.[ROLES.CUSTOMER_MAINTENANCE], [profile]);
	const restricted = useMemo(() => !accessible?.update || !accessible?.create, [accessible]);
	const isCorporate = useMemo(() => props.isCorporate, [props.isCorporate]);
	const [disableLinkAppUser, setDisableLinkAppUser] = useState(false);

	const checkBoxClassName = useMemo(() => {
		return classNames({
			"app-checkbox": true,
			"app-checkbox--disabled": disableLinkAppUser
		});
	}, [disableLinkAppUser]);

	const initialValues = useMemo(() => {
		const values = {
			picName: "",
			createAppUser: false,
			email: "",
			mobileNo: "",
			mobileNoPrefix: "+60",
			departmentEmail: "",
			officeNo: "",
			officeNoPrefix: "+60",
			identificationType: "",
			identificationNumber: "",
			passport: "",
			nationality: "",
			gender: "",
			status: STATUS.ACTIVE
		};

		return values;
	}, []);

	const formik = useFormik({
		initialValues: initialValues,
		validationSchema: yup.object({
			picName: yup.string().required(ERRORS.REQUIRED),
			email: yup.string().matches(REGEX.EMAIL, ERRORS.REQUIRED).required(ERRORS.REQUIRED),
			mobileNo: yup.string().required(ERRORS.REQUIRED)
		}),
		onSubmit: (values) => {
			onHandleSubmit(values);
		}
	});

	const isEditPic = useMemo(() => formik.values.number || formik.values.id, [formik]);
	const title = useMemo(() => (isEditPic ? "Edit PIC" : "Add PIC"), [isEditPic]);
	const linkAppUser = useMemo(() => (formik.values.customerUserId ? true : formik.values.createAppUser), [formik]);
	const memoSetFormValues = useMemo(() => formik.setValues, [formik.setValues]);

	//prettier-ignore
	const onHandleShow = useCallback((values, picData) => {
		if (values) {
			const row = values.rowIndex;
			const nextValues = { ...values };

			formik.setValues(nextValues);

			setDisableLinkAppUser(!!nextValues.customerUserId);

			if (id === PAGE.CREATE) {
				memoSetFormValues({
					createAppUser: picData[row].createAppUser,
					departmentEmail: picData[row].departmentEmail,
					email: picData[row].email,
					gender: picData[row].gender,
					identificationNumber: picData[row].identificationNumber,
					identificationType: picData[row].identificationType,
					mobileNo: picData[row].mobileNo,
					mobileNoPrefix: picData[row].mobileNoPrefix,
					nationality: picData[row].nationality,
					number: picData[row].number,
					officeNo: picData[row].officeNo,
					passport: picData[row].passport,
					picName: picData[row].picName,
					status: picData[row].status,
				});
			}
		}

		setVisible(true);
	}, [formik, id, memoSetFormValues]);

	const onHandleDismiss = useCallback(() => {
		setVisible(false);

		formik.resetForm();

		setDisableLinkAppUser(false);
	}, [formik]);

	// prettier-ignore
	const onHandleSubmit = useCallback(async (values) => {
		const isCreateCustomerEditPic = values.number;

		if (id === PAGE.CREATE) {
			if (!isCreateCustomerEditPic) props.onConfirm(values);

			if (isCreateCustomerEditPic) props.onHandleEditItem(values);

			formik.setSubmitting(false);

			return onHandleDismiss();
		}

		props.onHandleAddUpdatePic(values);

		onHandleDismiss();
	}, [id, props, formik, onHandleDismiss]);

	// prettier-ignore
	const onHandleLinkAppUser = useCallback((value) => {
		formik.setFieldValue("createAppUser", value);
	}, [formik]);

	//prettier-ignore
	const onHandleChangeIdType = useCallback((event) => {
		const value = event.target.value;

		if (value !== formik.values.identificationType) {
			formik.setFieldValue("identificationNumber", "");
			formik.setFieldValue("passport", "");
		}

		formik.handleChange(event);
	}, [formik]);

	useImperativeHandle(ref, () => ({
		onHandleShow,
		onHandleDismiss
	}));

	//prettier-ignore
	const IdentificationField = useCallback(({ values, errors, touched, onChange }) => {
		const identificationType = values.identificationType;
		const isPassport = identificationType === "PASSPORT";

		if (!isPassport) {
			return <AppMaskingInput type="number" name="identificationNumber" label="NRIC No." placeholder="e.g. 901010-14-1234" disabled={!identificationType || restricted} value={values.identificationNumber} error={errors.identificationNumber} touched={touched.identificationNumber} onChange={onChange} format={MASKING.NRIC} />;
		}

		if (isPassport) {
			return <AppInput type="text" name="passport" label="Passport" placeholder="e.g. VD1289281" disabled={!identificationType || restricted} value={values.passport} error={errors.passport} touched={touched.passport} onFormat={formatPassportString} onChange={onChange} />;
		}
	}, [restricted]);

	return (
		<Modal classes={{ root: "app-customer-add-pic-modal" }} open={visible}>
			<div className="customer-add-pic-modal">
				<button type="button" className="customer-add-pic-modal__close" onClick={onHandleDismiss}>
					<AppIcon src={closeIcon} />
				</button>

				<h1 className="customer-add-pic-modal__title">{title}</h1>

				<form className="customer-add-pic-modal__form" onSubmit={formik.handleSubmit}>
					<div className="customer-add-pic-modal__row">
						<AppInput required type="text" disabled={restricted} name="picName" label="PIC Name" placeholder="Enter PIC Name" value={formik.values.picName} error={formik.errors.picName} touched={formik.touched.picName} onChange={formik.handleChange} />

						<div className="customer-add-pic-modal__link-app-user">
							<AppCheckbox className={checkBoxClassName} disabled={restricted || disableLinkAppUser} name="createAppUser" onClick={onHandleLinkAppUser} label="Link Mobile App Access" value={linkAppUser} />

							<p className="customer-add-pic-modal__link-app-user-description">
								Link this PIC with a Mobile App User with the same email address. Once linked, the PIC <span>cannot be unlinked.</span>
							</p>
						</div>
					</div>

					<div className="customer-add-pic-modal__row">
						<AppInput required type="text" disabled={restricted} name="email" label="Email" placeholder="Enter Email" value={formik.values.email} error={formik.errors.email} touched={formik.touched.email} onChange={formik.handleChange} />

						<AppMobileInput required type="number" disabled={restricted} name="mobileNo" prefixNoName="mobileNoPrefix" label="Mobile No." placeholder="eg: 12345678" value={formik.values.mobileNo} prefixNo={formik.values.mobileNoPrefix} error={formik.errors.mobileNo} touched={formik.touched.mobileNo} onChange={formik.handleChange} onChangeCode={formik.setFieldValue} />
					</div>

					{isCorporate && (
						<div className="customer-add-pic-modal__row">
							<AppInput type="text" disabled={restricted} name="departmentEmail" label="Department Email" placeholder="Enter Department Email" value={formik.values.departmentEmail} error={formik.errors.departmentEmail} touched={formik.touched.departmentEmail} onChange={formik.handleChange} />

							<AppMobileInput type="number" disabled={restricted} name="officeNo" prefixNoName="officeNoPrefix" label="Office No." placeholder="eg: 12345678" value={formik.values.officeNo} prefixNo={formik.values.officeNoPrefix} error={formik.errors.officeNo} touched={formik.touched.officeNo} onChange={formik.handleChange} onChangeCode={formik.setFieldValue} />
						</div>
					)}

					<div className="customer-add-pic-modal__row">
						<AppSelectInput searchable={false} disabled={restricted} name="identificationType" label="ID Type" placeholder="Select..." loadOptions={getIdTypeListing} value={formik.values.identificationType} error={formik.errors.identificationType} touched={formik.touched.identificationType} onChange={onHandleChangeIdType} />

						<IdentificationField values={formik.values} errors={formik.errors} touched={formik.touched} onChange={formik.handleChange} />
					</div>

					<div className="customer-add-pic-modal__row">
						<AppSelectInput disabled={restricted} name="nationality" label="Nationality" placeholder="Select..." loadOptions={getNationalityListing} value={formik.values.nationality} error={formik.errors.nationality} touched={formik.touched.nationality} onChange={formik.handleChange} />

						<AppSelectInput disabled={restricted} searchable={false} name="gender" label="Gender" placeholder="Select..." loadOptions={getGenderListing} value={formik.values.gender} error={formik.errors.gender} touched={formik.touched.gender} onChange={formik.handleChange} />
					</div>

					<div className="customer-add-pic-modal__button-container">
						<AppButton outline type="button" label="Cancel" onClick={onHandleDismiss} />

						<AppButton type="submit" label="Confirm" disabled={formik.isSubmitting || restricted} />
					</div>
				</form>
			</div>
		</Modal>
	);
};

export default memo(forwardRef(AppCustomerAddPicModal));

AppCustomerAddPicModal.propTypes = {
	ref: PropTypes.object.isRequired,
	onConfirm: PropTypes.func.isRequired
};
