import React, { forwardRef, memo, useCallback, useContext, useEffect, useImperativeHandle, useMemo, useRef, useState } from "react";

import { debounce } from "lodash";
import { useFormik } from "formik";
import PropTypes from "prop-types";
import { Modal } from "@mui/material";
import { useDispatch } from "react-redux";
import { useParams } from "react-router-dom";
import { AxiosContext } from "contexts/with-interceptor-provider";

import api from "services/api";

import { promptLayoutAlertMessage } from "store/slices/layout-alert";

import sanitizeObject from "common/sanitize-object";
import convertSortingQuery from "common/convert-sorting-query";
import { serveLayoutRequestErrors } from "common/serve-request-errors";
import convertPaginationTableData from "common/convert-pagination-table-data";

import ENDPOINT_PATH from "constants/end-point-path";

import AppIcon from "components/app-icon";
import AppInput from "components/app-input";
import AppButton from "components/app-button";
import AppCheckbox from "components/app-checkbox";
import AppTable from "components/app-table/app-table";

import closeIcon from "assets/images/close-icon.png";
import searchIcon from "assets/images/search-icon.png";

const AppCustomerSiteDetailsPicAddModal = (props, ref) => {
	const { id, contractId } = useParams();
	const dispatch = useDispatch();
	const [visible, setVisible] = useState(false);
	const [data, setData] = useState(convertPaginationTableData());
	const [isSubmiting, setIsSubmiting] = useState(false);
	const cancelRequest = useContext(AxiosContext).onHandleCancelRequest;
	const isCreate = useMemo(() => props.isCreate, [props.isCreate]);
	const queryParams = useRef({ page: 0, keyword: "", sort: "" });

	const formik = useFormik({
		initialValues: { keyword: "", picContacts: [], picContactIds: [] },

		onSubmit: (values) => {
			onHandleSubmit(values);
		}
	});

	//prettier-ignore
	const onHandleSubmit = useCallback(async (values) => {
		let response = null;
		const nextPicContacts = values.picContacts;
		const nextPicContactIds = values.picContactIds;

		setIsSubmiting(true);

		try {
			if (isCreate) {
				props.onHandleUpdatePicContacts(nextPicContacts);

				props.onHandleUpdatePicContactIds(nextPicContactIds)
			}

			if (!isCreate){
				let payload = {
					customerContractSiteId: id,
					picContactIds: nextPicContactIds,
				}

				await api.post.customerSiteMaintenance.addAllSitePic(payload);
			}

			response = true;
		} catch (error) {
			serveLayoutRequestErrors(error);
		} finally {
			setIsSubmiting(false);
		}

		if (response) {
			if (!isCreate){
				dispatch(promptLayoutAlertMessage({ message: "Site PIC was added successfully!" }));

				props.onHandleGetPicTableList(id);
			}

			setVisible(false);
		}
	}, [isCreate, props, id, dispatch]);

	//prettier-ignore
	const onHandleShow = useCallback((picContacts, picContactIds) => {
		formik.setFieldValue("picContacts", picContacts);
		
		formik.setFieldValue("picContactIds", picContactIds);

		setVisible(true);
	}, [formik]);

	const onHandleDismiss = useCallback(() => {
		setVisible(false);

		formik.resetForm();
	}, [formik]);

	//prettier-ignore
	const onHandleSelectPic = useCallback((boolean, id) => {
		let nextPicContacts = [...formik.values.picContacts];
		let nextPicContactIds = [...formik.values.picContactIds];

		if (boolean) {
			const item = data.content.find((o) => o.id === id);

			nextPicContacts = [...nextPicContacts, item];
			nextPicContactIds = [...nextPicContactIds, item.id];
		} else {
			nextPicContacts = nextPicContacts.filter((o) => o.id !== id);
			nextPicContactIds = nextPicContactIds.filter((selectedId) => selectedId !== id);
		}

		formik.setFieldValue("picContacts", nextPicContacts);
		formik.setFieldValue("picContactIds", nextPicContactIds);
	}, [formik, data]);

	//prettier-ignore
	const onHandleGetList = useCallback(async () => {
		let response = null;

		try {
			if (isCreate) {
				const params = { queryParams: sanitizeObject({ ...queryParams.current, size: 10 }), "contract-id": contractId };

				response = await api.get.customer.contractsPic(params);
			}
			
			if (!isCreate) {
				const params = { queryParams: sanitizeObject({ ...queryParams.current, size: 10, }), "customer-contract-site-id": id };

				response = await api.get.customerSiteMaintenance.exclusiveSitePic(params);
			}

		} catch (error) {
			serveLayoutRequestErrors(error);
		}

		if (response) {
			const obj = convertPaginationTableData(response);

			setData(obj);
		}
	}, [contractId, id, isCreate]);

	//prettier-ignore
	const onHandleSearch = useCallback((event) => {
		queryParams.current.keyword = event.target.value;

		onHandleGetList();
	}, [onHandleGetList]);

	const onHandleDebounceSearch = debounce(onHandleSearch, 1000);

	//prettier-ignore
	const tableColumns = useMemo(() => [
		{
			name: "paginationNumbers",
			label: "#",
			options: {
				sort: true,
				sortThirdClickReset: true,
			},
		},
		{
			name: "picName",
			label: "PIC Name",
			options: {
				sort: true,
				sortThirdClickReset: true,
			},
		},
		{
			name: "email",
			label: "Email Address",
			options: {
				sort: false,
			},
		},
		{
			name: "officeNo",
			label: "Office Number",
			options: {
				sort: false,
				customBodyRender: (value, tableMeta) => {
					if(data.content[tableMeta.rowIndex].officeNo) {
						return data.content[tableMeta.rowIndex].officeNoPrefix + value;
					}

					return "-";
				}
			},
		},
		{
			name: "departmentEmail",
			label: "Department Email",
			options: {
				sort: false,
			},
		},
		{
			name: "identificationNumber",
			label: "ID Number",
			options: {
				sort: true,
				sortThirdClickReset: true,
				customBodyRender: (value, tableMeta) => {
					if(data.content[tableMeta.rowIndex].identificationType === "PASSPORT") {
						return data.content[tableMeta.rowIndex].passport ?? "-";
					}

					return value ?? "-";
				}
			},
		},
		{
			name: "add",
			label: "Add",
			options: {
				filter: false,
				sort: false,
				customBodyRender: (value, tableMeta) => {
					const rowIndex = tableMeta.rowIndex;
					const tablePicId = data.content[rowIndex].id;
					const selected = formik.values.picContacts?.findIndex((o) => o.id === tablePicId) > -1;

					return <AppCheckbox label="" onClick={(v) => onHandleSelectPic(v, tablePicId)} value={selected} />;
				},
			},
		},
	], [data.content, formik.values.picContacts, onHandleSelectPic]);

	//prettier-ignore
	const tableOptions = useMemo(() => ({
		count: data.totalElements,
		page: data.page,
		serverSide: true,
		onTableChange: (action, tableState) => {
			switch (action) {
				case "changePage":
					queryParams.current.page = tableState.page;

					onHandleGetList();
					break;
				case "sort":
					queryParams.current = { ...queryParams.current, sort: convertSortingQuery(tableState.sortOrder) };

					onHandleGetList();
					break;
				default:
					break;
			}
		},
	}),[data.page, data.totalElements, onHandleGetList]);

	useEffect(() => {
		if (visible) onHandleGetList();
	}, [visible, onHandleGetList]);

	useEffect(() => {
		return () => {
			if (visible) {
				if (!isCreate) cancelRequest(ENDPOINT_PATH.CUSTOMER_SITE_MAINTENANCE.EXCLUSIVE_SITE_PIC);

				if (isCreate) cancelRequest(ENDPOINT_PATH.CUSTOMER.CONTRACTS_PIC);
			}
		};
	}, [cancelRequest, isCreate, visible]);

	useImperativeHandle(ref, () => ({
		onHandleShow: onHandleShow
	}));

	return (
		<Modal classes={{ root: "app-customer-site-details-pic-add-modal" }} open={visible}>
			<div className="customer-site-details-pic-add-modal">
				<button type="button" className="customer-site-details-pic-add-modal__close" onClick={onHandleDismiss}>
					<AppIcon src={closeIcon} />
				</button>

				<div className="customer-site-details-pic-add-modal__title">Add PICs</div>

				<div className="customer-site-details-pic-add-modal__description">Select PIC from the Customer</div>

				<form className="customer-site-details-pic-add-modal__form" onSubmit={formik.handleSubmit}>
					<div className="customer-site-details-pic-add-modal__header">
						<div className="customer-site-details-pic-add-modal__search-input">
							<AppInput type="text" name="keyword" placeholder="Search by PIC Name or Email Address" endIcon={searchIcon} iconPosition="end" defaultValue={queryParams.current.keyword} onChange={onHandleDebounceSearch} onBlur={() => {}} />
						</div>
					</div>

					<AppTable data={data.content} columns={tableColumns} options={tableOptions} />

					<div className="customer-site-details-pic-add-modal__button-container">
						<AppButton outline type="button" label="Cancel" onClick={onHandleDismiss} />

						<AppButton type="submit" label="Add" disabled={isSubmiting} />
					</div>
				</form>
			</div>
		</Modal>
	);
};

export default memo(forwardRef(AppCustomerSiteDetailsPicAddModal));

AppCustomerSiteDetailsPicAddModal.propTypes = {
	ref: PropTypes.object.isRequired,
	onConfirm: PropTypes.func.isRequired
};
