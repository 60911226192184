import React, { useCallback, useContext, useEffect, useMemo, useRef, useState } from "react";

import * as yup from "yup";
import dayjs from "dayjs";
import { useFormik } from "formik";
import { useNavigate, useSearchParams } from "react-router-dom";
import { AxiosContext } from "contexts/with-interceptor-provider";

// import { Table, TableBody, TableCell, TableRow } from "@mui/material";
import pathnames from "routes/pathnames";

import api from "services/api";

import classNames from "common/class-names";
import getTotalDays from "common/get-total-days";
import sanitizeObject from "common/sanitize-object";
import convertSortingQuery from "common/convert-sorting-query";
import { serveLayoutRequestErrors } from "common/serve-request-errors";
import convertPaginationTableData from "common/convert-pagination-table-data";

import STATUS from "constants/status";
import DATE_TIME from "constants/date-time";
import TYPE from "constants/work-order-type";
import ENDPOINT_PATH from "constants/end-point-path";

// import AppButton from "components/app-button";
import AppStatus from "components/app-status";
import AppCheckbox from "components/app-checkbox";
import AppInputDate from "components/app-input-date";
import AppTable from "components/app-table/app-table";
import AppTableFilterHeader from "components/app-table-filter-header";

// import sendIcon from "assets/images/send.svg";
import editIcon from "assets/images/edit-icon.png";

const PageWorkOrderAll = () => {
	const navigate = useNavigate();
	const [searchParams, setSearchParams] = useSearchParams();
	const cancelRequest = useContext(AxiosContext).onHandleCancelRequest;
	const memoSearchParams = useRef(setSearchParams);
	const [data, setData] = useState(convertPaginationTableData());
	// const [selectedPending, setSelectedPending] = useState([]);
	const defaultStatus = useMemo(() => [STATUS.DRAFT, STATUS.PENDING_ASSIGN, STATUS.PENDING_CHECKLIST, STATUS.OPEN, STATUS.IN_PROGRESS, STATUS.PENDING_VERIFY, STATUS.PENDING_ACKNOWLEDGE, STATUS.COMPLETED, STATUS.CANCELLED, STATUS.OVERDUE, STATUS.PENDING_RESCHEDULE, STATUS.RESCHEDULED, STATUS.ACKNOWLEDGED].join(","), []);
	const status = useMemo(() => {
		const currentStatuses = searchParams.get("status")?.split(",");
		const relevantStatus = currentStatuses?.every((e) => defaultStatus.split(",").includes(e));

		if (!relevantStatus) {
			return defaultStatus;
		} else {
			return searchParams.get("status");
		}
	}, [defaultStatus, searchParams]);
	const defaultType = useMemo(() => [TYPE.PREVENTIVE, TYPE.BREAKDOWN, TYPE.CORRECTIVE, TYPE.PROJECT].join(","), []);
	const type = useMemo(() => {
		const currentStatuses = searchParams.get("types")?.split(",");
		const relevantStatus = currentStatuses?.every((e) => defaultType.split(",").includes(e));

		if (!relevantStatus) {
			return defaultType;
		} else {
			return searchParams.get("types");
		}
	}, [defaultType, searchParams]);
	const queryParams = useRef({ page: 0, keyword: searchParams.get("keyword") || "", "start-date": searchParams.get("start-date") || "", "end-date": searchParams.get("end-date") || "", status: status, types: type });

	const onHandleGetList = useCallback(async () => {
		let response = null;

		try {
			const params = sanitizeObject({ ...queryParams.current, size: 10 });

			memoSearchParams.current(params);

			response = await api.get.workOrder.workOrders(params);
		} catch (error) {
			serveLayoutRequestErrors(error);
		}

		if (response) {
			const obj = convertPaginationTableData(response);

			setData(obj);
		}
	}, []);

	//prettier-ignore
	const onHandleSubmit = useCallback((values) => {
		queryParams.current.status = values.status.join(",");
		queryParams.current.types = values.type.join(",");

		if (values.startDate && dayjs(values.startDate).isValid()) {
			queryParams.current["start-date"] = dayjs(values.startDate).format(DATE_TIME.YYYY_MM_DD);
		}

		if (values.endDate && dayjs(values.endDate).isValid()) {
			queryParams.current["end-date"] = dayjs(values.endDate).format(DATE_TIME.YYYY_MM_DD);
		}

		onHandleGetList();
	}, [onHandleGetList]);

	//prettier-ignore
	const onHandleSearch = useCallback((event) => {
		queryParams.current.keyword = event.target.value;

		onHandleGetList();
	}, [onHandleGetList]);

	const validationSchema = yup.object({
		endDate: yup.date().test(function (value, { parent }) {
			if (!value) return true;

			const totalDays = getTotalDays(parent.startDate, value);

			return totalDays >= 0 && totalDays <= 90;
		})
	});

	const formik = useFormik({
		initialValues: {
			startDate: "",
			endDate: "",
			status: [STATUS.DRAFT, STATUS.PENDING_ASSIGN, STATUS.PENDING_CHECKLIST, STATUS.OPEN, STATUS.IN_PROGRESS, STATUS.PENDING_VERIFY, STATUS.PENDING_ACKNOWLEDGE, STATUS.COMPLETED, STATUS.CANCELLED, STATUS.OVERDUE, STATUS.PENDING_RESCHEDULE, STATUS.RESCHEDULED, STATUS.ACKNOWLEDGED],
			type: [TYPE.PREVENTIVE, TYPE.BREAKDOWN, TYPE.CORRECTIVE, TYPE.PROJECT]
		},
		validationSchema: validationSchema,
		onSubmit: (values) => {
			onHandleSubmit(values);
		}
	});

	//prettier-ignore
	const intructionClassName = useMemo(() => classNames({
		"work-order-all-filter__instruction": true,
		"work-order-all-filter__instruction--error": formik.errors.endDate,
	}), [formik]);

	const onHandleSetLastFilter = useCallback(() => {
		formik.setFieldValue("types", queryParams.current.types.split(","));
		formik.setFieldValue("status", queryParams.current.status.split(","));
		formik.setFieldValue("start-date", queryParams.current["start-date"]);
		formik.setFieldValue("end-date", queryParams.current["end-date"]);
	}, [formik]);

	// const onHandleSelectPending = useCallback((boolean, idNo) => {
	// 	setSelectedPending((prev) => {
	// 		let nextPending = [...prev];

	// 		if (boolean) {
	// 			nextPending.push(idNo);
	// 		} else {
	// 			nextPending = nextPending.filter((id) => id !== idNo);
	// 		}

	// 		return nextPending;
	// 	});
	// }, []);

	//prettier-ignore
	// const onHandleSelectAll = useCallback((boolean) => {
	// 	const ids = data.content.map((item) => item.id);

	// 	setSelectedPending(boolean ? ids : []);
	// }, [data]);

	//prettier-ignore
	const onHandleEdit = useCallback((workOrderType, woId) => {
		navigate(pathnames.workOrder.workOrderCreateEdit + `${workOrderType}/${woId}`);
	}, [navigate]);

	const menuFilterStatus = useMemo(() => {
		const data = [
			{ label: "Draft", name: STATUS.DRAFT },
			{ label: "Pending Assignation", name: STATUS.PENDING_ASSIGN },
			{ label: "Pending Checklist", name: STATUS.PENDING_CHECKLIST },
			{ label: "Open", name: STATUS.OPEN },
			{ label: "In-Progress", name: STATUS.IN_PROGRESS },
			{ label: "Pending Verify", name: STATUS.PENDING_VERIFY },
			{ label: "Pending Acknowledge", name: STATUS.PENDING_ACKNOWLEDGE },
			{ label: "Completed", name: STATUS.COMPLETED },
			{ label: "Cancelled", name: STATUS.CANCELLED },
			{ label: "Overdue", name: STATUS.OVERDUE },
			{ label: "Pending Rescheduled", name: STATUS.PENDING_RESCHEDULE },
			{ label: "Rescheduled", name: STATUS.RESCHEDULED },
			{ label: "Acknowledge", name: STATUS.ACKNOWLEDGED }
		];

		return data;
	}, []);

	//prettier-ignore
	const onHandleSelectStatus = useCallback((value, name) => {
		let values = [...formik.values.status];

		if (formik.values.status.length < 2 && !value) return;

		if (!value) {
			values = values.filter(o => o !== name);
		} else {
			values.push(name);
		}

		formik.setFieldValue("status", values);
	}, [formik]);

	const menuFilterType = useMemo(() => {
		const data = [
			{ label: "Preventive", name: TYPE.PREVENTIVE },
			{ label: "SCM", name: TYPE.CORRECTIVE },
			{ label: "Breakdown", name: TYPE.BREAKDOWN },
			{ label: "Project", name: TYPE.PROJECT }
		];

		return data;
	}, []);

	//prettier-ignore
	const onHandleSelectType = useCallback((value, name) => {
		let values = [...formik.values.type];

		if (formik.values.type.length < 2 && !value) return;

		if (!value) {
			values = values.filter(o => o !== name);
		} else {
			values.push(name);
		}

		formik.setFieldValue("type", values);
	}, [formik]);

	const onHandleClearFilter = useCallback(() => {
		formik.resetForm();

		queryParams.current.types = defaultType;
		queryParams.current.status = defaultStatus;
		queryParams.current["start-date"] = "";
		queryParams.current["end-date"] = "";

		onHandleGetList();
	}, [defaultStatus, defaultType, formik, onHandleGetList]);

	//prettier-ignore
	const tableColumns = useMemo(() => [
		// {
		// 	name: "checkbox",
		// 	options: {
		// 		sort: false,
		// 		customHeadRender: () => {
		// 			const selectedAll = data.content.length > 0 && selectedPending.length === data.content.length;

		// 			return (
		// 				<TableCell key="table-key" className="app-table__cell app-table__cell--header">
		// 					<AppCheckbox onClick={onHandleSelectAll} value={selectedAll} />
		// 				</TableCell>
		// 			);
		// 		},
		// 		customBodyRender: (value, tableMeta) => {
		// 			const rowIndex = tableMeta.rowIndex;
		// 			const tablePendingID = data.content[rowIndex]?.id;
		// 			const selected = selectedPending.includes(tablePendingID);

		// 			return (
		// 				<Table>
		// 					<TableBody>
		// 						<TableRow>
		// 							<TableCell className="app-table__cell">
		// 								<AppCheckbox className="checkbox" onClick={(v) => onHandleSelectPending(v, tablePendingID)} value={selected} />
		// 							</TableCell>
		// 						</TableRow>
		// 					</TableBody>
		// 				</Table>
		// 			);
		// 		},
		// 	},
		// },
		{
			name: "paginationNumbers",
			label: "#",
			options: {
				sort: false,
			},
		},	
		{
			name: "referenceNo",
			label: "Work Order ID",
			options: {
				sort: false
			},
		},
		{
			name: "workOrderType",
			label: "Type",
			options: {
				sort: true,
				sortThirdClickReset: true			
			},
		},
		{
			name: "customerDetail.customer.registeredName",
			label: "Registered Name",
			options: {
				sort: true,
				sortThirdClickReset: true,
			},
		},
		{
			name: "customerDetail.customerContractSiteName",
			label: "Site Name",
			options: {
				sort: true,
				sortThirdClickReset: true,
				customBodyRender: (value, tableMeta) => {
					const rowData = data.content[tableMeta.rowIndex];
					const siteName = rowData.customerContractSiteName;

					return siteName;
				},			
			},
		},
		{
			name: "woAsset.assetName",
			label: "Asset Name",
			options: {
				sort: true,
				sortThirdClickReset: true,			
				customBodyRender: (value, tableMeta) => {
					const rowData = data.content[tableMeta.rowIndex];

					return rowData.woAsset[0].assetName;
				}
			},
		},
		{
			name: "teamPic.personalInfo.fullName",
			label: "Team PIC",
			options: {
				sort: false,
			},
		},
		{
			name: "scheduledDate",
			label: "Scheduled Date",
			options: {
				sort: true,
				sortThirdClickReset: true,
				customBodyRender: (value) => dayjs(value).format(DATE_TIME.DD_MM_YYYY)
			},
		},
		{
			name: "targetCompletionDate",
			label: "Target Completion Date",
			options: {
				sort: false,
				customBodyRender: (value) => dayjs(value).format(DATE_TIME.DD_MM_YYYY)
			},
		},
		{
			name: "status",
			label: "Status",
			options: {
				sort: true,
				sortThirdClickReset: true,
				customBodyRender: (value) => <AppStatus status={value} />,
			},
		},
		{
			name: "edit",
			label: "Edit",
			options: {
				sort: false,
				customBodyRender: (value, tableMeta) => {
					const rowData = data.content[tableMeta.rowIndex];
					const workOrderType = rowData.workOrderType;
					const woId = rowData.id

					return (
						<button type="button" className="table__edit" onClick={() => onHandleEdit(workOrderType, woId)}>
							<img src={editIcon} alt="edit-icon" />
						</button>
					);
				},
			},
		},
	], [data, onHandleEdit]);

	//prettier-ignore
	const tableOptions = useMemo(() => ({
		count: data.totalElements,
		page: data.page,
		serverSide: true,
		enableNestedDataAccess: ".",
		onTableChange: (action, tableState) => {
			switch (action) {
				case "changePage":
					queryParams.current.page = tableState.page;

					onHandleGetList();
					break;
				case "sort":
					queryParams.current = { ...queryParams.current, sort: convertSortingQuery(tableState.sortOrder) };

					onHandleGetList();
					break;
				default:
					break;
			}
		}
	}), [data, onHandleGetList]);

	// const HeaderLinkButton = useCallback((obj) => {
	// 	if (!obj.selectedPending.length) return null;

	// 	return (
	// 		<div className="work-order-all__header-button">
	// 			<AppButton outline type="button" label="Send for Signature" icon={sendIcon} />
	// 		</div>
	// 	);
	// }, []);

	useEffect(() => {
		onHandleGetList();
	}, [onHandleGetList, cancelRequest]);

	useEffect(() => {
		return () => {
			cancelRequest(ENDPOINT_PATH.WORK_ORDER.WORK_ORDERS);
		};
	}, [cancelRequest]);

	return (
		<div className="page-work-order-all">
			<div className="work-order-all">
				<h1 className="work-order-all__title">All Work Orders</h1>

				<div className="work-order-all__header">
					<AppTableFilterHeader formik={{ ...formik }} searchPlaceholder="Search by Work Order ID or Customer Name or Assignee" searchDefaultValue={queryParams.current.keyword} onHandleSearch={onHandleSearch} onHandleClearFilter={onHandleClearFilter} onHandleSetLastFilter={onHandleSetLastFilter}>
						<div className="work-order-all-filter">
							<div className="work-order-all-filter__container">
								<div className="work-order-all-filter__inputs">
									<AppInputDate name="startDate" label="Scheduled Date" placeholder="Start Date" onChange={formik.setFieldValue} value={formik.values.startDate} />

									<AppInputDate name="endDate" placeholder="End Date" onChange={formik.setFieldValue} value={formik.values.endDate} />
								</div>

								<p className={intructionClassName}>Please select a date or a date range of up to 90 days</p>
							</div>

							<div className="work-order-all-filter__container">
								<p className="work-order-all-filter__label">Status</p>

								<div className="work-order-all-filter__checkboxes">
									{menuFilterStatus.map((o) => {
										const isActiveStatus = formik.values.status.findIndex((i) => i === o.name) > -1;

										return (
											<div className="work-order-all__checkbox" key={o.label}>
												<AppCheckbox key={o.label} onClick={(v) => onHandleSelectStatus(v, o.name)} label={o.label} value={isActiveStatus} />
											</div>
										);
									})}
								</div>
							</div>

							<div className="work-order-all-filter__container">
								<p className="work-order-all-filter__label">Type</p>

								<div className="work-order-all-filter__checkboxes">
									{menuFilterType.map((o) => {
										const isActiveType = formik.values.type.findIndex((i) => i === o.name) > -1;

										return (
											<div className="work-order-all__checkbox" key={o.label}>
												<AppCheckbox key={o.label} onClick={(v) => onHandleSelectType(v, o.name)} label={o.label} value={isActiveType} />
											</div>
										);
									})}
								</div>
							</div>
						</div>
					</AppTableFilterHeader>

					{/* <HeaderLinkButton selectedPending={selectedPending} /> */}
				</div>

				<div className="work-order-all__body">
					<AppTable data={data.content} columns={tableColumns} options={tableOptions} />
				</div>
			</div>
		</div>
	);
};

export default PageWorkOrderAll;
