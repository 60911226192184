import store from "store/store";

const getPermission = (routeRoles) => {
	const profile = store.getState()?.profile;

	const profilePermissionNames = profile.roles.reduce((permissions, role) => {
		const rolePermissions = role.permission.reduce((perms, perm) => {
			perms.push(perm.name);

			if (perm.child && perm.child.length > 0) {
				const childPermissions = perm.child.map((child) => child.name);
				
				perms.push(...childPermissions);
			}

			return perms;
		}, []);

		return [...permissions, ...rolePermissions];
	}, []);

	const permissionFound = profilePermissionNames.some((profilePermission) => routeRoles.some((routeRole) => routeRole === profilePermission));

	return permissionFound;
};

export default getPermission;
