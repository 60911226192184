import { memo, useCallback, useMemo, useState } from "react";

import { debounce } from "lodash";
import PropTypes from "prop-types";
import Menu from "@mui/material/Menu";

import AppInput from "components/app-input";
import AppButton from "components/app-button";

import addIcon from "assets/images/add-icon.png";
import filterIcon from "assets/images/filter-icon.png";
import searchIcon from "assets/images/search-icon.png";

const AppTableFilterHeader = (props) => {
	const [menuAnchor, setMenuAnchor] = useState(null);
	const formik = useMemo(() => props.formik, [props.formik]);
	const restricted = useMemo(() => props.restricted, [props.restricted]);
	const onHandleAdd = useMemo(() => props.onHandleAdd, [props.onHandleAdd]);
	const onHandleSearch = useMemo(() => props.onHandleSearch, [props.onHandleSearch]);
	const searchPlaceholder = useMemo(() => props.searchPlaceholder || "Search...", [props.searchPlaceholder]);
	const searchDefaultValue = useMemo(() => props.searchDefaultValue, [props.searchDefaultValue]);
	const onHandleClearFilter = useMemo(() => props.onHandleClearFilter, [props.onHandleClearFilter]);
	const onHandleSetLastFilter = useMemo(() => props.onHandleSetLastFilter, [props.onHandleSetLastFilter]);

	//prettier-ignore
	const onHandleTrimSearch = useCallback((event) => {
		event.target.value = event.target.value.trim();

		onHandleSearch(event);
	}, [onHandleSearch]);

	const onHandleDebounceSearch = debounce(onHandleTrimSearch, 1000);

	const onHandleCloseMenu = useCallback(() => {
		setMenuAnchor(null);

		onHandleSetLastFilter();
	}, [onHandleSetLastFilter]);

	const onToggleFilterMenu = useCallback((event) => {
		setMenuAnchor(event.currentTarget);
	}, []);

	return (
		<div className="app-table-filter-header">
			<div className="table-filter-header">
				<AppInput type="text" name="search" placeholder={searchPlaceholder} endIcon={searchIcon} iconPosition="end" defaultValue={searchDefaultValue} onChange={onHandleDebounceSearch} onBlur={() => {}} />

				{props.children && (
					<div className="table-filter-header__filter-button">
						<AppButton outline type="button" label="Filter" icon={filterIcon} onClick={onToggleFilterMenu} />
					</div>
				)}
			</div>

			{onHandleAdd && !restricted && (
				<div className="table-filter-header__create-button">
					<AppButton type="button" label="Add" icon={addIcon} onClick={onHandleAdd} />
				</div>
			)}

			<Menu classes={{ root: "app-table-filter-menu" }} anchorEl={menuAnchor} open={!!menuAnchor} onClose={onHandleCloseMenu} anchorOrigin={{ vertical: "bottom", horizontal: "right" }} transformOrigin={{ vertical: "top", horizontal: "right" }}>
				<div className="filter-menu">
					<div className="filter-menu__body">{props.children}</div>

					<div className="filter-menu__footer">
						{/* prettier-ignore */}
						<button type="button" className="filter-menu__button" onClick={onHandleClearFilter}>Reset</button>

						{/* prettier-ignore */}
						<div className="filter-menu__buttons">
							<button type="button" className="filter-menu__button filter-menu__button--cancel" onClick={onHandleCloseMenu}>Cancel</button>

							<button type="button" className="filter-menu__button filter-menu__button--apply" onClick={formik?.handleSubmit}>Apply</button>
						</div>
					</div>
				</div>
			</Menu>
		</div>
	);
};

AppTableFilterHeader.propTypes = {
	formik: PropTypes.object,
	restricted: PropTypes.bool,
	onHandleAdd: PropTypes.func,
	onHandleSearch: PropTypes.func.isRequired,
	searchPlaceholder: PropTypes.string,
	searchDefaultValue: PropTypes.string,
	onHandleClearFilter: PropTypes.func,
	onHandleSetLastFilter: PropTypes.func,
	children: PropTypes.node
};

export default memo(AppTableFilterHeader);
