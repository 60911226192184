import dayjs from "dayjs";
import utc from "dayjs/plugin/utc";
import timezone from "dayjs/plugin/timezone";

dayjs.extend(utc);
dayjs.extend(timezone);

const combineDateTime = (date, time) => {
	if (!date || !time) return null;

	const year = dayjs(date).year();
	const month = dayjs(date).month();
	const day = dayjs(date).date();

	const hour = dayjs(time).hour();
	const minute = dayjs(time).minute();
	const second = dayjs(time).second();

	const combined = dayjs().year(year).month(month).date(day).hour(hour).minute(minute).second(second).local();

	return combined.utc().format("YYYY-MM-DDTHH:mm:ss.SSS[Z]");
};

export default combineDateTime;
