import api from "services/api";

import sanitizeObject from "common/sanitize-object";
import { serveLayoutRequestErrors } from "common/serve-request-errors";

const getCustomerPicListing = async (payload) => {
	let response = null;

	let errorMessage = null;

	try {
		const params = { "customer-id": payload.id, queryParams: sanitizeObject({ size: 10, page: payload.page || 0, id: payload.id, keyword: payload.keyword || "" }) };

		const res = await api.get.customer.customerPic(params);

		const content = res.content.map(({ picName, ...o }) => ({ label: picName, value: picName, ...o }));

		response = { ...res, content };
	} catch (error) {
		serveLayoutRequestErrors(error, (message) => {
			errorMessage = message;
		});
	}

	if (response) return response;

	throw errorMessage;
};

export default getCustomerPicListing;
