import { createContext, memo, useCallback, useEffect, useMemo, useRef, useState } from "react";

import COMMON from "common";
import services from "services";
import { useDispatch } from "react-redux";

import pathnames from "routes/pathnames";

import api from "services/api";

import STATUS_CODE from "constants/status-code";
import sanitizeError from "common/sanitize-error";
import serveRequestErrors from "common/serve-request-errors";

const sharedStyles = "margin: 5px 0; padding: 8px; border-radius: 20px; color: #FFFFFF;";
const initStyles = `${sharedStyles} background: #673AB7;`;
const ejectStyles = `${sharedStyles} background: #ffb127;`;
const reqDataStyles = `${sharedStyles} background: #F8EA8C;`;
const reqParamsStyles = `${sharedStyles} background: #ff9514;`;
const respSuccessStyles = `${sharedStyles} background: #32ac97;`;
const errorStyles = `${sharedStyles} background: #F79489;`;
const debug = process.env.NODE_ENV === "development";

const interceptorDebug = (title, styles, data = null) => {
	if (!debug) return;
	console.log(`%c ${title}`, styles);

	if (data) console.log(data);
	console.log("");
};

export const AxiosContext = createContext();

const InterceptorProvider = ({ children, routes }) => {
	const retryRefreshToken = useRef(0);
	const controller = useRef({});
	const dispatch = useDispatch();
	const [initialed, setInitialed] = useState(false);
	const requestInterceptor = useRef(null);
	const responseInterceptor = useRef(null);

	const onHandleGetToken = () => {
		let token = null;
		const localToken = localStorage.getItem(COMMON.AUTH_TOKEN);
		const sessionToken = sessionStorage.getItem(COMMON.AUTH_TOKEN);

		if (localToken) {
			token = localToken;
		} else {
			token = sessionToken;
		}

		return token;
	};

	//prettier-ignore
	const onHandleForceLogout = useCallback(async (error, path = pathnames.login.login) => {
		localStorage.clear();

		sessionStorage.clear();

		routes.navigate(path);

		dispatch({ type: COMMON.REDUX_ACTION.LOGOUT });

		serveRequestErrors(error);
	}, [routes, dispatch]);

	const onHandleRequest = useCallback((config) => {
		switch (config.method) {
			case "get":
				interceptorDebug(`GET REQUESTING 🚀 ${config.url}`, reqParamsStyles, config.params);
				break;
			default:
				interceptorDebug(`POST REQUESTING 🚀 ${config.url}`, reqDataStyles, config.data);
				break;
		}

		let token = onHandleGetToken();
		let signal = {};

		if (config.cancelId) {
			controller.current[config.cancelId] = new AbortController();
			signal = { signal: controller.current[config.cancelId].signal };
		}

		if (token && config.token !== false) config.headers.Authorization = "Bearer " + token;

		return { ...config, ...signal };
	}, []);

	//prettier-ignore
	const onHandleRequestError = useCallback((error) => {
		interceptorDebug("REQUESTING ERROR 👎", errorStyles, sanitizeError(error));
		return Promise.reject(error);
	}, []);

	const onHandleResponse = useCallback((response) => {
		interceptorDebug(`RESPONSE SUCCESS: 🌟 ${response.config.baseURL + response.config.url}`, respSuccessStyles, response.data);
		return response.data;
	}, []);

	//prettier-ignore
	const onResponseError = useCallback(async (error) => {
		interceptorDebug(`RESPONSE ERROR 🥲 ${error.config.baseURL + error.config.url}`, errorStyles, error);
		const errorResponse = error?.response;
		const statusCode = errorResponse?.status;
		const errorCode = errorResponse?.data?.errorCode;
		const unauthorized = statusCode === 403;

		if (retryRefreshToken.current >= 3) {
			retryRefreshToken.current = 0;
			onHandleForceLogout(error);
			return Promise.reject(new Error("403"));
		}

		switch (errorCode) {
			case STATUS_CODE.TOKEN_EXPIRED:
				retryRefreshToken.current += 1;
				const stayLogged = localStorage.getItem(COMMON.AUTH_TOKEN);
				const refreshToken = stayLogged ? localStorage.getItem(COMMON.REFRESH_TOKEN) : sessionStorage.getItem(COMMON.REFRESH_TOKEN);
				const response = await api.post.authenticate.refreshToken({ refreshToken });

				if (stayLogged) {
					localStorage.setItem(COMMON.AUTH_TOKEN, response.accessToken);
					localStorage.setItem(COMMON.REFRESH_TOKEN, response.refreshToken);
				} else {
					sessionStorage.setItem(COMMON.AUTH_TOKEN, response.accessToken);
					sessionStorage.setItem(COMMON.REFRESH_TOKEN, response.refreshToken);
				}

				error.config.headers.Authorization = "Bearer " + response.accessToken;

				retryRefreshToken.current = 0;

				return services.request(error.config);
			case STATUS_CODE.INVALID_TOKEN:
			case STATUS_CODE.DB_ERROR_ACCOUNT_LOCKED:
				onHandleForceLogout(error, pathnames.accountSuspended);

				return Promise.reject(new Error("403"));
			default:
				if (unauthorized) {
					onHandleForceLogout(error);
					return Promise.reject(new Error("403"));
				}

				return Promise.reject(error);
		}
	}, [onHandleForceLogout]);

	const onHandleCancelRequest = useCallback((id) => {
		if (controller.current[id]) controller.current[id]?.abort();
	}, []);

	const onHandleEjectAxiosInterceptor = useCallback(() => {
		if (requestInterceptor.current !== null) {
			interceptorDebug("Ejected Request Axios Interceptor! 👋", ejectStyles);
			services.interceptors.request.eject(requestInterceptor.current);
		}
		if (responseInterceptor.current !== null) {
			interceptorDebug("Ejected Response Axios Interceptor! 👋", ejectStyles);
			services.interceptors.response.eject(responseInterceptor.current);
		}
	}, []);

	useEffect(() => {
		interceptorDebug(`Init Axios Interceptor! 🎉`, initStyles);
		requestInterceptor.current = services.interceptors.request.use(onHandleRequest, onHandleRequestError, { synchronous: true });
		responseInterceptor.current = services.interceptors.response.use(onHandleResponse, onResponseError, { synchronous: true });
		setInitialed(true);
	}, [onHandleRequest, onHandleRequestError, onHandleResponse, onResponseError]);

	useEffect(() => {
		return () => {
			onHandleEjectAxiosInterceptor();
		};
	}, [onHandleEjectAxiosInterceptor]);

	const contexs = useMemo(() => ({ onHandleCancelRequest }), [onHandleCancelRequest]);

	if (!initialed) return null;

	return <AxiosContext.Provider value={contexs}>{children}</AxiosContext.Provider>;
};

export default memo(InterceptorProvider);
