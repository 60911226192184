import React, { useCallback, useContext, useEffect, useMemo, useRef, useState } from "react";

import { useSelector } from "react-redux";
import { useParams } from "react-router-dom";
import { AxiosContext } from "contexts/with-interceptor-provider";

import api from "services/api";

import { promptLayoutAlertMessage } from "store/slices/layout-alert";

import sanitizeObject from "common/sanitize-object";
import convertSortingQuery from "common/convert-sorting-query";
import convertPaginationTableData from "common/convert-pagination-table-data";

import PAGE from "constants/page";
import ROLES from "constants/roles";
import ENDPOINT_PATH from "constants/end-point-path";

import AppTable from "components/app-table/app-table";
import AppTableFilterHeader from "components/app-table-filter-header";
import AppAddCustomerCreateEditContractPicModal from "components/pages/customer/app-add-customer-create-edit-contract-pic-modal";
import AppDeleteCustomerCreateEditContractPicModal from "components/pages/customer/app-delete-customer-create-edit-contract-pic-modal";

import trashIcon from "assets/images/trash-icon.png";

const AppCustomerAddContractPicTable = (props) => {
	const { id } = useParams();
	const addContractPic = useRef();
	const deleteContractPic = useRef();
	const selectedItem = useRef();
	const profile = useSelector((state) => state.profile);
	const accessible = useMemo(() => profile?.permissions?.[ROLES.CUSTOMER_CONTRACT_PIC], [profile]);
	const restricted = useMemo(() => !accessible?.update || !accessible?.create, [accessible]);
	const cancelRequest = useContext(AxiosContext).onHandleCancelRequest;
	const isCreate = useMemo(() => id === PAGE.CREATE, [id]);
	const isClone = useMemo(() => props.isClone, [props.isClone]);
	const picContacts = useMemo(() => props.picContacts, [props.picContacts]);
	const picContactIds = useMemo(() => props.picContactIds, [props.picContactIds]);
	const customerId = useMemo(() => props.customerId, [props.customerId]);
	const queryParams = useRef({ page: 0, keyword: "", sort: "" });
	const [data, setData] = useState(convertPaginationTableData());

	const onHandleAddContractPicModal = useCallback(() => {
		addContractPic.current.onHandleShow(picContacts, picContactIds);
	}, [picContactIds, picContacts]);

	const onHandleGetExclusiveList = useCallback(() => {
		addContractPic.current.onHandleGetExclusiveList(id);
	}, [id]);

	//prettier-ignore
	const onHandleConfirmRemovePicModal = useCallback((event, tableMeta) => {
		selectedItem.current = { ...data.content[tableMeta.rowIndex], rowIndex: tableMeta.rowIndex };

		deleteContractPic.current.onHandleShow(selectedItem.current);
	}, [data]);

	const onHandleGetLocalData = useCallback(() => {
		let nextData = structuredClone(picContacts);

		const lowerCaseSearch = queryParams.current.keyword?.toLowerCase();

		if (queryParams.current.keyword.length) {
			nextData = nextData.filter((o) => {
				return o.picName.toLowerCase().includes(lowerCaseSearch) || o.email.toLowerCase().includes(lowerCaseSearch);
			});
		}

		setData({ content: nextData });
	}, [picContacts]);

	//prettier-ignore
	const onHandleGetList = useCallback(async (contractId) => {
		let response = null;

		try {
			const params = sanitizeObject({
				"contract-id": contractId,
				queryParams: { ...queryParams.current, size: 10 }
			});

			response = await api.get.customer.contractsPic(params);
		} catch (error) {
			promptLayoutAlertMessage(error);
		}

		if (response) {
			const obj = convertPaginationTableData(response);

			setData(obj);
		}
	}, []);

	//prettier-ignore
	const onHandleSearch = useCallback((event) => {
		queryParams.current.keyword = event.target.value;

		if (isCreate) {
			onHandleGetLocalData();
		} else {
			onHandleGetList(id);
		}
	}, [id, isCreate, onHandleGetList, onHandleGetLocalData]);

	//prettier-ignore
	const tableColumns = useMemo(() => [
		{
			name: "paginationNumbers",
			label: "#",
			options: {
				sort: true,
				sortThirdClickReset: true,
				customBodyRender: (value, tableMeta) => {
					return tableMeta.rowIndex + 1;
				}
			}
		},
		{
			name: "picName",
			label: "PIC Name",
			options: {
				sort: true,
				sortThirdClickReset: true
			}
		},
		{
			name: "email",
			label: "Email Address",
			options: {
				sort: false
			}
		},
		{
			name: "officeNo",
			label: "Office Number",
			options: {
				sort: false,
				customBodyRender: (value, tableMeta) => {
					if (data.content[tableMeta.rowIndex].officeNo) {
						return data.content[tableMeta.rowIndex].officeNoPrefix + value;
					}

					return "-";
				}
			}
		},
		{
			name: "departmentEmail",
			label: "Department Email",
			options: {
				sort: false,
				customBodyRender: (value, tableMeta) => value || "-"
			}
		},
		{
			name: "identificationNumber",
			label: "ID Number",
			options: {
				sort: true,
				sortThirdClickReset: true,
				customBodyRender: (value, tableMeta) => {
					if(data.content[tableMeta.rowIndex].identificationType === "PASSPORT") {
						return data.content[tableMeta.rowIndex].passport ?? "-";
					}

					return value ?? "-";
				}
			},
		},
		{
			name: "remove",
			label: "Remove",
			options: {
				sort: false,
				customBodyRender: (value, tableMeta) => {
					return (
						<button type="button" className="table__action" onClick={(event) => onHandleConfirmRemovePicModal(event, tableMeta)}>
							<img src={trashIcon} alt="trash-icon" />
						</button>
					);
				}
			}
		}
	], [data, onHandleConfirmRemovePicModal]);

	const emptyState = useMemo(() => {
		if (data.content.length) return {};

		const node = () => (
			<tbody>
				<tr className="table__empty-state">
					<td colSpan={tableColumns.length} align="center">
						<p className="table__text" onClick={onHandleAddContractPicModal}>
							No PICs Added. <span className="table__link">Add PIC from Customer List?</span>
						</p>
					</td>
				</tr>
			</tbody>
		);

		return { TableBody: node };
	}, [data.content.length, tableColumns.length, onHandleAddContractPicModal]);

	//prettier-ignore
	const tableOptions = useMemo(() => ({
		count: data.totalElements,
		page: data.page,
		serverSide: true,
		onTableChange: (action, tableState) => {
			switch (action) {
				case "changePage":
					queryParams.current.page = tableState.page;

					onHandleGetList(id);
					break;
				case "sort":
					queryParams.current = { ...queryParams.current, sort: convertSortingQuery(tableState.sortOrder) };

					onHandleGetList(id);
					break;
				default:
					break;
			}
		}
	}), [id, data, onHandleGetList]);

	useEffect(() => {
		if (isCreate || isClone) {
			const picContactsCopy = structuredClone(picContacts);

			setData({ content: picContactsCopy });
		} else {
			onHandleGetList(id);
		}
	}, [id, isClone, isCreate, onHandleGetList, picContacts]);

	useEffect(() => {
		return () => {
			if (!isCreate) cancelRequest(ENDPOINT_PATH.CUSTOMER.CONTRACTS_PIC);
		};
	}, [cancelRequest, isCreate]);

	return (
		<div className="app-customer-add-contract-pic-table">
			<div className="customer-add-contract-pic-table">
				{/* prettier-ignore */}
				<AppTableFilterHeader searchPlaceholder="Search by PIC Name" searchDefaultValue={queryParams.current.value} onHandleSearch={onHandleSearch} restricted={restricted} onHandleAdd={onHandleAddContractPicModal} />

				<AppTable data={data.content} columns={tableColumns} components={emptyState} options={tableOptions} />
			</div>

			{/* prettier-ignore */}
			<AppAddCustomerCreateEditContractPicModal ref={addContractPic} isClone={isClone} customerId={customerId} localPicContactOptions={props.localPicContactOptions} onHandleUpdatePicContacts={props.onHandleUpdatePicContacts} onHandleUpdatePicContactIds={props.onHandleUpdatePicContactIds} onHandleRefreshPicTable={onHandleGetList} />

			{/* prettier-ignore */}
			<AppDeleteCustomerCreateEditContractPicModal ref={deleteContractPic} isClone={isClone} onHandleRemovePicContact={props.onHandleRemovePicContact} onHandleRefreshPicTable={onHandleGetList} onHandleRefreshAddPicModal={onHandleGetExclusiveList} />
		</div>
	);
};

export default AppCustomerAddContractPicTable;
