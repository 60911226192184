import { forwardRef, memo, useCallback, useContext, useEffect, useImperativeHandle, useMemo, useRef, useState } from "react";

import { useFormik } from "formik";
import PropTypes from "prop-types";
import { Modal } from "@mui/material";
import { useParams } from "react-router-dom";
import { useDispatch, useSelector } from "react-redux";
import { AxiosContext } from "contexts/with-interceptor-provider";

import api from "services/api";

import { promptLayoutAlertMessage } from "store/slices/layout-alert";

import sanitizeObject from "common/sanitize-object";
import convertSortingQuery from "common/convert-sorting-query";
import { serveLayoutRequestErrors } from "common/serve-request-errors";
import convertPaginationTableData from "common/convert-pagination-table-data";

import PAGE from "constants/page";
import ROLES from "constants/roles";
import ENDPOINT_PATH from "constants/end-point-path";

import AppButton from "components/app-button";
import AppCheckbox from "components/app-checkbox";
import AppTable from "components/app-table/app-table";
import AppTableFilterHeader from "components/app-table-filter-header";

const AppAddCustomerCreateEditContractPicModal = (props, ref) => {
	const dispatch = useDispatch();
	const { customerId, id } = useParams();
	const profile = useSelector((state) => state.profile);
	const cancelRequest = useContext(AxiosContext).onHandleCancelRequest;
	const isCreate = useMemo(() => id === PAGE.CREATE, [id]);
	const isClone = useMemo(() => props.isClone, [props.isClone]);
	const accessible = useMemo(() => profile?.permissions?.[ROLES.CUSTOMER_CONTRACT_PIC], [profile]);
	const restricted = useMemo(() => !accessible?.update || !accessible?.create, [accessible]);
	const localPicContactOptions = useMemo(() => props.localPicContactOptions?.map((o, i) => ({ ...o, paginationNumbers: i + 1 })), [props.localPicContactOptions]);
	const queryParams = useRef({ page: 0, keyword: "", sort: "" });
	const [visible, setVisible] = useState(false);
	const [data, setData] = useState({ content: localPicContactOptions });

	const formik = useFormik({
		initialValues: { keyword: "", picContacts: [], picContactIds: [] },
		onSubmit: (values) => {
			onHandleSubmit(values);
		}
	});

	const onHandleGetExclusiveList = useCallback(async () => {
		let response = null;

		try {
			if (isCreate || isClone) {
				const params = {
					"customer-id": customerId,
					queryParams: sanitizeObject({ ...queryParams.current, size: 10 })
				};

				response = await api.get.customer.customerPic(params);
			} else {
				const params = {
					"contract-id": id,
					queryParams: sanitizeObject({ ...queryParams.current, size: 10 })
				};

				response = await api.get.customer.exclusivePicSearch(params);
			}
		} catch (error) {
			serveLayoutRequestErrors(error);
		}

		if (response) {
			const obj = convertPaginationTableData(response);

			setData(obj);
		}
	}, [customerId, id, isClone, isCreate]);

	// prettier-ignore
	const onHandleSubmit = useCallback(async (values) => {
		const nextPicContacts = values.picContacts;
		const nextPicContactIds = values.picContactIds;

		if (isCreate || isClone) {
			props.onHandleUpdatePicContacts(nextPicContacts);

			props.onHandleUpdatePicContactIds(nextPicContactIds);
		} else {
			let response = null;

			try {
				const payload = {
					customerContractId: id,
					picContactIds: values.picContactIds
				};

				await api.post.customer.addAllContractPic(payload);

				response = true;
			} catch (error) {
				serveLayoutRequestErrors(error);
			}

			if (response) {
				props.onHandleRefreshPicTable(id);

				onHandleGetExclusiveList();

				dispatch(promptLayoutAlertMessage({ message: "PIC Contacts were added successfully!" }));
			}
		}

		setVisible(false);
	}, [id, dispatch, isClone, isCreate, onHandleGetExclusiveList, props]);

	//prettier-ignore
	const onHandleSearch = useCallback((event) => {
		queryParams.current.keyword = event.target.value;

		onHandleGetExclusiveList();
	}, [onHandleGetExclusiveList]);

	// prettier-ignore
	const onHandleShow = useCallback((picContacts, picContactIds) => {
		formik.setFieldValue("picContacts", picContacts);

		formik.setFieldValue("picContactIds", picContactIds);

		setVisible(true);
	}, [formik]);

	const onHandleDismiss = useCallback(() => {
		setVisible(false);

		formik.resetForm();
	}, [formik]);

	//prettier-ignore
	const onHandleSelectPic = useCallback((boolean, id) => {
		let nextPicContacts = [...formik.values.picContacts];
		let nextPicContactIds = [...formik.values.picContactIds];

		if (boolean) {
			const item = data.content.find((o) => o.id === id);

			nextPicContacts = [...nextPicContacts, item];
			nextPicContactIds = [...nextPicContactIds, item.id];
		} else {
			nextPicContacts = nextPicContacts.filter((o) => o.id !== id);
			nextPicContactIds = nextPicContactIds.filter((selectedId) => selectedId !== id);
		}

		formik.setFieldValue("picContacts", nextPicContacts);
		formik.setFieldValue("picContactIds", nextPicContactIds);
	}, [formik, data]);

	//prettier-ignore
	const tableColumns = useMemo(() => [
		{
			name: "paginationNumbers",
			label: "#",
			options: {
				sort: true,
				sortThirdClickReset: true
			}
		},
		{
			name: "picName",
			label: "PIC Name",
			options: {
				sort: true,
				sortThirdClickReset: true
			}
		},
		{
			name: "email",
			label: "Email Address",
			options: {
				sort: false
			}
		},
		{
			name: "officeNo",
			label: "Office Number",
			options: {
				sort: false,
				customBodyRender: (value, tableMeta) => {
					if (data.content[tableMeta.rowIndex].officeNo) {
						return data.content[tableMeta.rowIndex].officeNoPrefix + value;
					}

					return "-";
				}
			}
		},
		{
			name: "departmentEmail",
			label: "Department Email",
			options: {
				sort: false,
				customBodyRender: (value, tableMeta) => value || "-"
			}
		},
		{
			name: "identificationNumber",
			label: "ID Number",
			options: {
				sort: true,
				sortThirdClickReset: true,
				customBodyRender: (value, tableMeta) => {
					if(data.content[tableMeta.rowIndex].identificationType === "PASSPORT") {
						return data.content[tableMeta.rowIndex].passport ?? "-";
					}

					return value ?? "-";
				}
			},
		},
		{
			name: "add",
			label: "Add",
			options: {
				sort: false,
				customBodyRender: (value, tableMeta) => {
					const rowIndex = tableMeta.rowIndex;
					const tablePicId = data.content[rowIndex].id;
					const selected = formik.values.picContacts.findIndex((o) => o.id === tablePicId) > -1;

					return <AppCheckbox label="" onClick={(v) => onHandleSelectPic(v, tablePicId)} value={selected} />;
				}
			}
		}
	], [data, formik.values.picContacts, onHandleSelectPic]);

	// prettier-ignore
	const tableOptions = useMemo(() => ({
		count: data.totalElements,
		page: data.page,
		serverSide: true,
		onTableChange: (action, tableState) => {
			switch (action) {
				case "changePage":
					queryParams.current.page = tableState.page;

					props.onHandleGetPicContacts(id);
					break;
				case "sort":
					queryParams.current = { ...queryParams.current, sort: convertSortingQuery(tableState.sortOrder) };

					props.onHandleGetPicContacts(id);
					break;
				default:
					break;
			}
		}
	}), [id, data, props]);

	useEffect(() => {
		onHandleGetExclusiveList();
	}, [onHandleGetExclusiveList]);

	useEffect(() => {
		return () => {
			if (isCreate || isClone) {
				cancelRequest(ENDPOINT_PATH.CUSTOMER.CUSTOMER_PIC);
			} else {
				cancelRequest(ENDPOINT_PATH.CUSTOMER.CONTRACTS_EXCLUSIVE_PIC);
			}
		};
	}, [cancelRequest, isClone, isCreate]);

	useImperativeHandle(ref, () => ({
		onHandleShow: onHandleShow,
		onHandleGetExclusiveList: onHandleGetExclusiveList
	}));

	return (
		<Modal classes={{ root: "app-add-customer-create-edit-contract-pic-modal" }} open={visible}>
			<div className="add-customer-create-edit-contract-pic-modal">
				<div className="add-customer-create-edit-contract-pic-modal__title">Add PICs</div>

				<div className="add-customer-create-edit-contract-pic-modal__description">Select PIC from the Customer</div>

				<form className="add-customer-create-edit-contract-pic-modal__form" onSubmit={formik.handleSubmit}>
					<AppTableFilterHeader searchPlaceholder="Search by PIC Name" searchDefaultValue={queryParams.current.value} onHandleSearch={onHandleSearch} />

					<AppTable data={data.content} columns={tableColumns} options={tableOptions} />

					<div className="add-customer-create-edit-contract-pic-modal__button-container">
						<AppButton outline type="button" label="Cancel" onClick={onHandleDismiss} />

						<AppButton type="submit" disabled={restricted} label="Add" />
					</div>
				</form>
			</div>
		</Modal>
	);
};

export default memo(forwardRef(AppAddCustomerCreateEditContractPicModal));

AppAddCustomerCreateEditContractPicModal.propTypes = {
	ref: PropTypes.object.isRequired,
	onConfirm: PropTypes.func.isRequired
};
