import React, { useCallback, useContext, useEffect, useMemo, useRef, useState } from "react";

import { useFormik } from "formik";
import { useParams } from "react-router-dom";
import { AxiosContext } from "contexts/with-interceptor-provider";

import api from "services/api";

import sanitizeObject from "common/sanitize-object";
import convertSortingQuery from "common/convert-sorting-query";
import { serveLayoutRequestErrors } from "common/serve-request-errors";
import convertPaginationTableData from "common/convert-pagination-table-data";

import PAGE from "constants/page";
import ENDPOINT_PATH from "constants/end-point-path";

import AppTable from "components/app-table/app-table";
import AppTableFilterHeader from "components/app-table-filter-header";
import AppCustomerSiteDetailsPicAddModal from "components/pages/customer/app-customer-site-details-pic-add-modal";
import AppCustomerSiteDetailsPicDeleteModal from "components/pages/customer/app-customer-site-details-pic-delete-modal";

import trashIcon from "assets/images/trash-icon.png";

const AppCustomerSiteDetailPicTable = (props) => {
	const { id } = useParams();
	const cancelRequest = useContext(AxiosContext).onHandleCancelRequest;
	const addSitePic = useRef();
	const deleteContractPic = useRef();
	const selectedItem = useRef();
	const isCreate = useMemo(() => id === PAGE.CREATE, [id]);
	const restricted = useMemo(() => props.restricted, [props.restricted]);
	const localData = useMemo(() => props.localData?.map((o, i) => ({ ...o, number: i + 1 })), [props.localData]);
	const picContacts = useMemo(() => props.picContacts, [props.picContacts]);
	const picContactIds = useMemo(() => props.picContactIds, [props.picContactIds]);
	const queryParams = useRef({ page: 0, keyword: "", sort: "" });
	const [data, setData] = useState(convertPaginationTableData());

	const formik = useFormik({ initialValues: { keyword: "", localData, picContacts, picContactIds } });

	const onHandleAddSitePicModal = useCallback(() => {
		addSitePic.current.onHandleShow(picContacts, picContactIds);
	}, [picContacts, picContactIds]);

	// prettier-ignore
	const onHandleConfirmDeletePic = useCallback((event, tableMeta) => {
		if (isCreate) selectedItem.current = { picId: picContacts[tableMeta.rowIndex].id, siteId: id, rowIndex: picContacts.rowIndex };

		if (!isCreate) selectedItem.current = { picId: data.content[tableMeta.rowIndex].id, siteId: id, rowIndex: picContacts.rowIndex };

		deleteContractPic.current.onHandleShow(selectedItem.current);
	}, [picContacts, id, data, isCreate]);

	//prettier-ignore
	const onHandleGetList = useCallback(async (contractId) => {
		let response = null;

		try {
			const params = sanitizeObject({ ...queryParams.current, size: 10, "customer-contract-site-id": contractId });

			response = await api.get.customerSiteMaintenance.sitePic(params);
		} catch (error) {
			serveLayoutRequestErrors(error);
		}

		if (response) {
			const obj = convertPaginationTableData(response);

			setData(obj);
		}
	}, []);

	//prettier-ignore
	const onHandleSearch = useCallback((event) => {
		if (isCreate) {
			let nextTableData = structuredClone(picContacts);
			
			formik.setFieldValue("keyword", event.target.value);
			
			const lowerCaseSearch = event.target.value.toLowerCase();
			
			if (event.target.value.length) {
				nextTableData = nextTableData.filter((o) => {
					return o.picName.toLowerCase().includes(lowerCaseSearch) || o.email.toLowerCase().includes(lowerCaseSearch);
				});
			}
			
			setData({ content: nextTableData });
		}
		
		if (!isCreate) {
			queryParams.current["keyword"] = event.target.value;
			
			onHandleGetList(id);
		}
	}, [id, formik, isCreate, picContacts, onHandleGetList]);

	// prettier-ignore
	const tableColumns = useMemo(() => [
		{
			name: "paginationNumbers",
			label: "#",
			options: {
				sort: true,
				sortThirdClickReset: true,
			},
		},
		{
			name: "picName",
			label: "PIC Name",
			options: {
				sort: true,
				sortThirdClickReset: true,
			},
		},
		{
			name: "mobileNo",
			label: "Mobile Number",
			options: {
				sort: true,
				sortThirdClickReset: true,
				customBodyRender: (value, tableMeta) => {
					if(data.content[tableMeta.rowIndex]) {
						const mobileNoPrefix = data.content[tableMeta.rowIndex].mobileNoPrefix;

						return mobileNoPrefix + value;
					}

					return "-";
				}
			},
		},
		{
			name: "email",
			label: "Email Address",
			options: {
				sort: true,
				sortThirdClickReset: true,
			},
		},
		{
			name: "officeNo",
			label: "Office Number",
			options: {
				sort: true,
				sortThirdClickReset: true,
				customBodyRender: (value, tableMeta) => {
					if(data.content[tableMeta.rowIndex]) {
						const officeNoPrefix = data.content[tableMeta.rowIndex].officeNoPrefix;
						
						return officeNoPrefix + value;
					}

					return "-";
				}
			},
		},
		{
			name: "departmentEmail",
			label: "Department Email",
			options: {
				sort: true,
				sortThirdClickReset: true,
			},
		},
		{
			name: "identificationNumber",
			label: "ID No.",
			options: {
				sort: true,
				sortThirdClickReset: true,
				customBodyRender: (value, tableMeta) => {
					if(data.content[tableMeta.rowIndex].identificationType === "PASSPORT") {
						return data.content[tableMeta.rowIndex].passport ?? "-";
					}

					return value ?? "-";
				}
			},
		},
		{
			name: "remove",
			label: "Remove",
			options: {
				filter: false,
				sort: false,
				customBodyRender: (value, tableMeta) => {
					if (restricted || data.content.length <= 1) return;

					return (
						<button type="button" className="table__action" onClick={(event) => onHandleConfirmDeletePic(event, tableMeta)}>
							<img src={trashIcon} alt="trash-icon" />
						</button>
					);
				},
			},
		},
	], [data.content, restricted, onHandleConfirmDeletePic]);

	//prettier-ignore
	const tableOptions = useMemo(() => ({
		count: data.totalElements,
		page: data.page,
		serverSide: true,
		onTableChange: (action, tableState) => {
			switch (action) {
				case "changePage":
					queryParams.current.page = tableState.page;

					onHandleGetList(id);
					break;
				case "sort":
					queryParams.current = { ...queryParams.current, sort: convertSortingQuery(tableState.sortOrder) };

					onHandleGetList(id);
					break;
				default:
					break;
			}
		}
	}), [data, onHandleGetList, id]);

	const emptyState = useMemo(() => {
		if (data.content.length) return {};

		const node = () => (
			<tbody>
				<tr className="table__empty-state">
					<td colSpan={tableColumns.length} align="center">
						<p className="table__text" disabled={restricted} onClick={onHandleAddSitePicModal}>
							No Site PIC Added <span className="table__link">Add Site PIC?</span>
						</p>
					</td>
				</tr>
			</tbody>
		);

		return { TableBody: node };
	}, [data, tableColumns.length, restricted, onHandleAddSitePicModal]);

	useEffect(() => {
		if (!isCreate) onHandleGetList(id);

		if (isCreate) {
			setData({ content: picContacts });
		}
	}, [id, isCreate, picContacts, onHandleGetList]);

	useEffect(() => {
		return () => {
			if (!isCreate) cancelRequest(ENDPOINT_PATH.CUSTOMER.CONTRACTS_SITES);
		};
	}, [cancelRequest, isCreate]);

	return (
		<div className="app-customer-site-details-pic-table">
			<div className="customer-site-details-pic-table">
				{/* prettier-ignore */}
				<AppTableFilterHeader searchPlaceholder="Search by PIC Name or Email Address" searchDefaultValue={queryParams.current.value} onHandleSearch={onHandleSearch} restricted={restricted} onHandleAdd={onHandleAddSitePicModal} />

				<AppTable data={data.content} columns={tableColumns} components={emptyState} options={tableOptions} />
			</div>

			{/* prettier-ignore */}
			<AppCustomerSiteDetailsPicAddModal ref={addSitePic} isCreate={isCreate} contractId={props.contractId} onHandleUpdatePicContacts={props.onHandleUpdatePicContacts} onHandleUpdatePicContactIds={props.onHandleUpdatePicContactIds} onHandleGetPicTableList={onHandleGetList} />

			<AppCustomerSiteDetailsPicDeleteModal ref={deleteContractPic} isCreate={isCreate} onHandleRemovePicContact={props.onHandleRemovePicContact} onHandleGetPicTableList={onHandleGetList} />
		</div>
	);
};

export default AppCustomerSiteDetailPicTable;
