import React, { forwardRef, memo, useCallback, useImperativeHandle, useMemo, useState } from "react";

import PropTypes from "prop-types";
import { Modal } from "@mui/material";
import { useParams } from "react-router-dom";

import api from "services/api";

import reorderArrayElement from "common/reorder-array-element";
import { serveLayoutRequestErrors } from "common/serve-request-errors";

import AppIcon from "components/app-icon";
import AppButton from "components/app-button";
import AppTable from "components/app-table/app-table";
import AppSelectInput from "components/app-select-input";

import closeIcon from "assets/images/close-icon.png";

const AppCustomerEditAssetReorderModal = (props, ref) => {
	const { id } = useParams();
	const [visible, setVisible] = useState(false);
	const [services, setServices] = useState([]);
	const seqOptions = useMemo(() => services.map((_, i) => ({ label: i + 1, value: i + 1 })), [services]);

	const onHandleSubmit = useCallback(() => {
		props.onConfirm(services);

		setVisible(false);
	}, [props, services]);

	const onHandleShow = useCallback(async () => {
		let response = null;

		try {
			const params = { "asset-id": id };

			response = await api.get.assetMaintenance.assetServicesList(params);
		} catch (error) {
			serveLayoutRequestErrors(error);
		}

		setServices(response);

		setVisible(true);
	}, [id]);

	const onHandleDismiss = useCallback(() => {
		setServices([]);

		setVisible(false);
	}, []);

	//prettier-ignore
	const onHandleReorderSeq = useCallback((event) => {
		const name = event.target.name;
		const value = event.target.value - 1;
		let nextServices = structuredClone(services);

		reorderArrayElement(nextServices, name, value);

		nextServices = nextServices.map((o, i) => {
			o.seq = i + 1;

			return o;
		});

		setServices(nextServices);
	}, [services]);

	// prettier-ignore
	const tableColumns = useMemo(() => [
		{
			name: "serviceNumber",
			label: "#",
			options: {
				sort: true,
				sortThirdClickReset: true,
				customBodyRender: (value, tableMeta) => {
					const { rowIndex } = tableMeta;

					return (
						<div className="customer-edit-asset-reorder-modal__table-row-service-number">
							<AppSelectInput type="text" searchable={false} name={rowIndex.toString()} placeholder="" options={seqOptions} value={rowIndex + 1} onChange={onHandleReorderSeq} />
						</div>
					);
				}
			}
		},
		{
			name: "serviceAction",
			label: "Service Action",
			options: {
				sort: false
			}
		}
	], [seqOptions, onHandleReorderSeq]);

	// prettier-ignore
	const tableOptions = useMemo(() => ({
		rowsPerPage: services.length,
		customFooter: () => null
	}), [services.length]);

	useImperativeHandle(ref, () => ({
		onHandleShow,
		onHandleDismiss
	}));

	return (
		<Modal classes={{ root: "app-customer-edit-asset-reorder-modal" }} open={visible}>
			<div className="customer-edit-asset-reorder-modal">
				<button type="button" className="customer-edit-asset-reorder-modal__close" onClick={onHandleDismiss}>
					<AppIcon src={closeIcon} />
				</button>

				<h1 className="customer-edit-asset-reorder-modal__title">Reorder Service Lines</h1>

				<div className="customer-edit-asset-reorder-modal__form">
					<AppTable data={services} columns={tableColumns} options={tableOptions} />

					<div className="customer-edit-asset-reorder-modal__button-container">
						<AppButton outline type="button" label="Cancel" onClick={onHandleDismiss} />

						<AppButton type="submit" label="Update" onClick={onHandleSubmit} />
					</div>
				</div>
			</div>
		</Modal>
	);
};

export default memo(forwardRef(AppCustomerEditAssetReorderModal));

AppCustomerEditAssetReorderModal.propTypes = {
	onConfirm: PropTypes.func.isRequired
};
