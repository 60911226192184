import { Fragment, forwardRef, memo, useCallback, useImperativeHandle, useMemo, useRef } from "react";

import * as yup from "yup";
import dayjs from "dayjs";
import { useDispatch, useSelector } from "react-redux";
import { useNavigate, useParams } from "react-router-dom";
import { FieldArray, FormikProvider, setNestedObjectValues, useFormik } from "formik";

import pathnames from "routes/pathnames";

import api from "services/api";
import getEmployeeFamilyMemberRelationshipListing from "services/get-employee-family-member-relationship-listing";
import getEmployeeFamilyMemberWorkingStatusListing from "services/get-employee-family-member-working-status-listing";

import { promptLayoutAlertMessage } from "store/slices/layout-alert";

import getOrdinalNumber from "common/get-ordinal-number";
import { serveLayoutRequestErrors } from "common/serve-request-errors";

import PAGE from "constants/page";
import ROLES from "constants/roles";
import ERRORS from "constants/errors";

import AppInput from "components/app-input";
import AppButton from "components/app-button";
import AppInputDate from "components/app-input-date";
import AppSelectInput from "components/app-select-input";

import addIcon from "assets/images/add-blue-icon.png";
import deleteIcon from "assets/images/trash-icon.png";

const AppFamilyInfo = (props, ref) => {
	const { id } = useParams();
	const dispatch = useDispatch();
	const navigate = useNavigate();
	const isCreate = useMemo(() => id === PAGE.CREATE, [id]);
	const submitLabel = useMemo(() => (isCreate ? "Add" : "Update"), [isCreate]);
	const defaultField = useMemo(() => ({ fullName: "", relationship: "", workingStatus: "", dateOfBirth: "" }), []);
	const maximumFamily = useMemo(() => 10, []);
	const createEmployeeSubmit = useRef(false);
	const profile = useSelector((state) => state.profile);
	const accessible = useMemo(() => profile?.permissions?.[ROLES.EMPLOYEES], [profile]);
	const restricted = useMemo(() => !accessible?.update || !accessible?.create, [accessible]);

	const initialValues = useMemo(() => {
		let data = {
			familyInfo: [defaultField]
		};

		if (props.family) {
			data.familyInfo = props.family.familyInfo;
		}

		return data;
	}, [defaultField, props.family]);

	const formik = useFormik({
		enableReinitialize: true,
		initialValues: initialValues,
		validationSchema: yup.object({
			familyInfo: yup.array().of(
				yup.object({
					fullName: yup.string().required(ERRORS.REQUIRED),
					relationship: yup.string().required(ERRORS.REQUIRED),
					workingStatus: yup.string().required(ERRORS.REQUIRED),
					dateOfBirth: yup.date().required(ERRORS.REQUIRED)
				})
			)
		}),
		onSubmit: (values) => {
			if (!createEmployeeSubmit.current) props.onSubmit(values);
			else onHandleSubmit(values);
		}
	});

	const onHandleFormSubmit = () => {
		createEmployeeSubmit.current = !Object.keys(formik.errors).length;

		formik.handleSubmit();
	};

	//prettier-ignore
	const onHandleSubmit = useCallback(async (values) => {
		let response = null;

		const filteredContacts = props.personal.emergencyContact.filter(contact => (contact.fullName.trim() !== '' && contact.mobileNo.trim() !== '' && contact.relationship.trim() !== ''));

		try {
			const payload = {
				personalInfo: {
					fullName: props.personal.fullName,
					identificationType: props.personal.identificationType,
					identificationNumber: props.personal.identificationNumber,
					passport: props.personal.passport,
					dateOfBirth: dayjs(props.personal.dateOfBirth).toISOString(),
					gender: props.personal.gender,
					nationality: props.personal.nationality,
					maritalStatus: props.personal.maritalStatus,
				},
				personalInfoContact: {
					primaryMobileNo: props.personal.primaryMobileNo,
					primaryMobileNoPrefix: props.personal.primaryMobileNoPrefix,
					secondaryMobileNo: props.personal.secondaryMobileNo,
					secondaryMobileNoPrefix: props.personal.secondaryMobileNoPrefix,
					email: props.personal.email,
					sameAsCorrespondenceAddress: props.personal.sameAsCorrespondenceAddress ? "Y" : "N",
					correspondenceAddress1: props.personal.correspondenceAddress1,
					correspondenceAddress2: props.personal.correspondenceAddress2,
					correspondenceRegion: props.personal.correspondenceRegion,
					correspondencePostcode: props.personal.correspondencePostcode,
					correspondenceState: props.personal.correspondenceState,
					residentialAddress1: props.personal.sameAsCorrespondenceAddress ? props.personal.correspondenceAddress1 : props.personal.residentialAddress1,
					residentialAddress2: props.personal.sameAsCorrespondenceAddress ? props.personal.correspondenceAddress2 : props.personal.residentialAddress2,
					residentialRegion: props.personal.sameAsCorrespondenceAddress ? props.personal.correspondenceRegion : props.personal.residentialRegion,
					residentialPostcode: props.personal.sameAsCorrespondenceAddress ? props.personal.correspondencePostcode : props.personal.residentialPostcode,
					residentialState: props.personal.sameAsCorrespondenceAddress ? props.personal.correspondenceState :  props.personal.residentialState,
				},
				personalInfoEmergencyContact: filteredContacts,
				familyInfo: values.familyInfo.map((o) => ({ ...o, dateOfBirth: dayjs(o.dateOfBirth).toISOString() })),
				schedule: {
					startWorkHour: dayjs(props.employee.startWorkHour).toISOString(),
					endWorkHour: dayjs(props.employee.endWorkHour).toISOString(),
					startWorkWeek: props.employee.startWorkWeek,
					endWorkWeek: props.employee.endWorkWeek,
				},
				employeeDetail: {
					workEmail: props.employee.workEmail,
					employmentStatus: props.employee.employmentStatus,
					reportingManagerId: props.employee.reportingManagerId,
					claimBenefitPackageId: props.employee.claimBenefitPackageId,
					leaveBenefitPackageId: props.employee.leaveBenefitPackageId,
					supervisorId: props.employee.supervisorId,
					positionId: props.employee.positionId,
					departmentId: props.employee.departmentId,
					jobLevelId: props.employee.jobLevelId,
					packageId: props.employee.packageId,
					dateJoined: dayjs(props.employee.dateJoined).toISOString(),
					probationEndDate: dayjs(props.employee.probationEndDate).toISOString(),
					lastEmploymentDate: props.employee.lastEmploymentDate ? dayjs(props.employee.lastEmploymentDate).toISOString() : "",
				},
				employeeContribution: {
					epfNo: props.employee.epfNo,
					incomeTaxNo: props.employee.incomeTaxNo,
					socsoNo: props.employee.socsoNo,
				},
				employeeBank: {
					bankName: props.employee.bankName,
					bankAccount: props.employee.bankAccount,
					swiftCode: props.employee.swiftCode,
				},
				remark: props.employee.remark,
			};

			if(isCreate) {
				await api.post.humanResource.createEmployee(payload);
			}

			if(!isCreate) {
				payload.id = id;

				await api.post.humanResource.updateEmployee(payload);
			}

			response = true;
		} catch (error) {
			formik.setSubmitting(false);

			serveLayoutRequestErrors(error);
		} finally {
			createEmployeeSubmit.current = false;
		}

		if (response) {
			if(isCreate) {
				dispatch(promptLayoutAlertMessage({ message: "User has added successful" }));
			}
			
			if(!isCreate) {
				dispatch(promptLayoutAlertMessage({ message: "User has updated successful" }));
			}

			navigate(pathnames.humanResources.employeeMaintenance);
		}
	}, [isCreate, id, formik, props, dispatch, navigate]);

	//prettier-ignore
	const onHandleAddFamilyMember = useCallback(async (obj) => {
		if (obj.form.values.familyInfo.length >= maximumFamily) return;

        const fieldErrors = await obj.form.validateForm();

        if(fieldErrors.familyInfo) {
            obj.form.setTouched(setNestedObjectValues(fieldErrors, true));

            return;
        }

		obj.push(defaultField);
	}, [defaultField, maximumFamily]);

	const onHandleCancel = useCallback(() => {
		navigate(pathnames.humanResources.employeeMaintenance);
	}, [navigate]);

	useImperativeHandle(ref, () => ({
		onHandleSubmit: formik.handleSubmit
	}));

	return (
		<FormikProvider value={formik}>
			<div className="app-family-info">
				<div className="family-info">
					<form className="family-info__form" onSubmit={formik.handleSubmit}>
						<div className="family-info__container">
							<FieldArray
								name="familyInfo"
								render={(f) => {
									const values = f.form.values?.familyInfo;
									const errors = f.form.errors?.familyInfo;
									const touched = f.form.touched?.familyInfo;
									const familyInfo = f.form.values.familyInfo;

									return (
										<Fragment>
											<div className="family-info__header">
												<div className="family-info__label">Family Members</div>

												<AppButton disabled={restricted} type="button" outline icon={addIcon} label="Add Member" onClick={() => onHandleAddFamilyMember(f)} />
											</div>

											{familyInfo.map((o, i) => {
												const ordinalNumber = getOrdinalNumber(i + 1) + " Member";

												return (
													<Fragment key={i}>
														<div className="family-info__label">
															{ordinalNumber}
															{familyInfo.length > 1 && (
																<span className="family-info__delete" onClick={() => f.remove(i)}>
																	Delete <img src={deleteIcon} alt="delete" />
																</span>
															)}
														</div>

														<div className="family-info__row">
															{/* prettier-ignore */}
															<AppInput required disabled={restricted} type="text" name={`familyInfo.${i}.fullName`} label="Full Name" placeholder="Enter Full Name" touched={touched?.[i]?.fullName} error={errors?.[i]?.fullName} value={values[i].fullName} onChange={formik.handleChange} />

															{/* prettier-ignore */}
															<AppSelectInput required searchable={false} disabled={restricted} type="text" name={`familyInfo.${i}.relationship`} label="Relationship" placeholder="Relationship" loadOptions={getEmployeeFamilyMemberRelationshipListing} value={values[i].relationship} error={errors?.[i]?.relationship} touched={touched?.[i]?.relationship} onChange={formik.handleChange} />
														</div>

														<div className="family-info__row">
															{/* prettier-ignore */}
															<AppSelectInput required searchable={false} disabled={restricted} type="text" name={`familyInfo.${i}.workingStatus`} label="Working Status" placeholder="Select..." loadOptions={getEmployeeFamilyMemberWorkingStatusListing} value={values[i].workingStatus} error={errors?.[i]?.workingStatus} touched={touched?.[i]?.workingStatus} onChange={formik.handleChange} />

															{/* prettier-ignore */}
															<AppInputDate required disabled={restricted} name={`familyInfo.${i}.dateOfBirth`} label="Date of Birth"  placeholder="DD/MM/YYYY" value={values[i].dateOfBirth} error={errors?.[i]?.dateOfBirth} touched={touched?.[i]?.dateOfBirth} onChange={formik.setFieldValue} />
														</div>
													</Fragment>
												);
											})}
										</Fragment>
									);
								}}
							/>
						</div>
						<div className="family-info__button-container">
							<AppButton outline type="button" onClick={onHandleCancel} label="Cancel" />

							<AppButton type="button" label={submitLabel} disabled={formik.isSubmitting || restricted} onClick={onHandleFormSubmit} />
						</div>
					</form>
				</div>
			</div>
		</FormikProvider>
	);
};

export default memo(forwardRef(AppFamilyInfo));
