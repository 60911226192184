import React, { forwardRef, memo, useCallback, useImperativeHandle, useMemo, useRef, useState } from "react";

import * as yup from "yup";
import PropTypes from "prop-types";
import { Modal } from "@mui/material";
import { useParams } from "react-router-dom";
import { useDispatch, useSelector } from "react-redux";
import { FieldArray, FormikProvider, setNestedObjectValues, useFormik } from "formik";

import api from "services/api";
import getServiceTypeListing from "services/get-service-type-listing";
import getServiceInputListing from "services/get-service-input-listing";
import getServiceStatusListing from "services/get-service-status-listing";

import { promptLayoutAlertMessage } from "store/slices/layout-alert";

import classNames from "common/class-names";
import reorderArrayElement from "common/reorder-array-element";
import { serveLayoutRequestErrors } from "common/serve-request-errors";

import PAGE from "constants/page";
import ROLES from "constants/roles";
import ERRORS from "constants/errors";
import STATUS from "constants/status";

import AppInput from "components/app-input";
import AppButton from "components/app-button";
import AppSelectInput from "components/app-select-input";

import trashIcon from "assets/images/trash-icon.png";
import addBlueIcon from "assets/images/add-blue-icon.png";
import addIconDisabled from "assets/images/add-icon-disabled.png";

const AppCustomerEditAssetCreateEditServiceModal = (props, ref) => {
	const { id } = useParams();
	const dispatch = useDispatch();
	const [seq, setSeq] = useState(0);
	const [visible, setVisible] = useState(false);
	const firstTimeLoad = useRef(true);
	const profile = useSelector((state) => state.profile);
	const accessible = useMemo(() => profile?.permissions?.[ROLES.INPUT_CONFIG], [profile]);
	const restricted = useMemo(() => !accessible?.update || !accessible?.create, [accessible]);

	const initialValues = useMemo(() => {
		const values = { serviceId: PAGE.CREATE, inputId: "", configInputId: "", serviceType: "", serviceAction: "", remark: "", seq: "", options: [] };

		return values;
	}, []);

	const formik = useFormik({
		initialValues: initialValues,
		validationSchema: yup.object({
			configInputId: yup.string().required(ERRORS.REQUIRED),
			serviceType: yup.string().required(ERRORS.REQUIRED),
			serviceAction: yup.string().required(ERRORS.REQUIRED),
			options: yup.array().of(
				yup.object().shape({
					subtaskAction: yup.string().required(ERRORS.REQUIRED),
					configInputId: yup.string().required(ERRORS.REQUIRED),
					seq: yup.number().required(ERRORS.REQUIRED)
				})
			)
		}),
		onSubmit: (values) => {
			onHandleSubmit(values);
		}
	});

	const memoSetFormValues = useMemo(() => formik.setValues, [formik.setValues]);

	// prettier-ignore
	const optionSequenceList = useMemo(() => formik.values.options.length ? formik.values.options.map((o, i) => { return { label: i + 1, value: i + 1 }; }) : [{ label: 1, value: 0 }], [formik.values.options]);

	const isCreate = useMemo(() => formik.values.serviceId === PAGE.CREATE, [formik.values.serviceId]);

	const submitLabel = useMemo(() => (isCreate ? "Add" : "Update"), [isCreate]);

	// prettier-ignore
	const addButtonClassNames = useMemo(() => classNames({
		"input-create-edit-modal__add": true,
		"input-create-edit-modal__add--disabled": restricted
	}), [restricted]);

	//prettier-ignore
	const onHandleGetDetails = useCallback(async (serviceId) => {
		let response = null;
		let params = { "asset-id": id, "service-id": serviceId };

		try {
			response = await api.get.assetMaintenance.assetService(params);
		} catch (error) {
			serveLayoutRequestErrors(error);
		}

		if (response) {
			memoSetFormValues({
				serviceId: response.id || PAGE.CREATE,
				serviceType: response?.serviceType || "",
				inputId: response?.input?.id || "",
				configInputId: response?.input?.configInputId || "",
				serviceAction: response?.serviceAction || "",
				remark: response?.remark || "",
				seq: response?.seq || "",
				options: response?.subtasks ? response.subtasks.map((o) => ({ id: o.id, seq: o.seq, subtaskAction: o.subtaskAction, inputId: o.input.id, configInputId: o.input.configInputId })) : []
			});
		}
	}, [id, memoSetFormValues]);

	// prettier-ignore
	const onHandleSubmit = useCallback(async (values) => {
		let response = null;

		try {
			const payload = { "asset-id": id, "service-id": values.serviceId, assetServices: { serviceType: values.serviceType, serviceAction: values.serviceAction, remark: values.remark, seq: seq, subtasks: values.options } };

			if (isCreate) {
				payload.assetServices.assetServiceInput = { configInputId: values.configInputId };

				response = await api.post.assetMaintenance.createAssetService(payload);
			}

			if (!isCreate) {
				let nextSubtasks = [];

				nextSubtasks = payload.assetServices.subtasks.map((o) => {
					o.assetServiceSubtaskInputId = o.inputId;
					
					const { inputId, ...cleanedObj } = o;

					return cleanedObj;
				}, []);

				payload.assetServices.subtasks = [...nextSubtasks];
				payload.assetServices.id = values.serviceId;
				payload.assetServices.status = STATUS.IN_USE;
				payload.assetServices.assetServiceInput = {
					configInputId: values.configInputId,
					assetInputId: values.inputId
				};

				response = await api.post.assetMaintenance.updateAssetService(payload);
			}
		} catch (error) {
			serveLayoutRequestErrors(error);
		} finally {
			formik.setSubmitting(false);
		}

		if (response) {
			setVisible(false);

			formik.resetForm();

			if (isCreate) {
				dispatch(promptLayoutAlertMessage({ message: "Service was added successfully!" }));
			}

			if (!isCreate) {
				dispatch(promptLayoutAlertMessage({ message: "Service was updated successfully!" }));
			}

			props.onHandleGetList();
		}
	}, [id, formik, seq, isCreate, props, dispatch]);

	// prettier-ignore
	const onHandleShow = useCallback((obj, serviceLineData) => {
		if (obj) {
			onHandleGetDetails(obj.id);
		}

		if (serviceLineData) {
			setSeq(serviceLineData.content.length);
		}

		setVisible(true);
	}, [onHandleGetDetails]);

	const onHandleDismiss = useCallback(() => {
		setVisible(false);

		firstTimeLoad.current = true;

		formik.resetForm();
	}, [formik]);

	// prettier-ignore
	const onHandleOptionSequence = useCallback((event) => {
		const name = event.target.name.match(/\d/)[0];
		const value = event.target.value - 1;

		const currentValues = structuredClone(formik.values);
		let nextOptions = structuredClone(formik.values.options);

		reorderArrayElement(nextOptions, name, value);

		nextOptions = nextOptions.map((o, i) => {
			o.seq = i + 1;

			return o;
		});

		memoSetFormValues({ ...currentValues, options: nextOptions });
	}, [formik.values, memoSetFormValues]);

	// prettier-ignore
	const onHandleAddOption = useCallback(async (renderProps) => {
		const fieldErrors = await renderProps.form.validateForm();

		if (fieldErrors.options) {
			renderProps.form.setTouched(setNestedObjectValues(fieldErrors, true));

			return;
		}

		const nextOption = { subtaskAction: "", configInputId: "", seq: formik.values.options.length + 1 };

		renderProps.push(nextOption);
	}, [formik]);

	const buttonIcon = useMemo(() => {
		if (restricted) {
			return addIconDisabled;
		} else {
			return addBlueIcon;
		}
	}, [restricted]);

	const InputOptions = useCallback((obj) => {
		let orderedOptions = obj.options;

		if (firstTimeLoad.current && orderedOptions.length) {
			orderedOptions = orderedOptions.sort((a, b) => a.seq - b.seq);

			firstTimeLoad.current = false;
		}

		return (
			<div className="customer-edit-asset-create-edit-service-modal__options">
				{orderedOptions.map((o, i) => {
					const firstOption = i === 0;
					const error = obj.optionsErrors?.[i];
					const touched = obj.optionsTouched?.[i];
					const sequenceLabel = firstOption ? "#" : "";
					const inputLabel = firstOption ? "Input" : "";
					const optionLabel = firstOption ? "Sub Task Action" : "";
					const trashClassNames = classNames({ "customer-edit-asset-create-edit-service-modal__delete": true, "customer-edit-asset-create-edit-service-modal__delete--first": firstOption, "customer-edit-asset-create-edit-service-modal__delete--disabled": obj.restricted });

					return (
						<div className="customer-edit-asset-create-edit-service-modal__option" key={i}>
							<AppSelectInput className="app-select-input app-select-input--order" searchable={false} type="number" name={`options[${i}].seq`} label={sequenceLabel} placeholder="" options={obj.optionSequenceList} value={i + 1} onChange={(e) => obj.onHandleOptionSequence(e)} />

							<AppInput required type="text" name={`options[${i}].subtaskAction`} label={optionLabel} placeholder="Enter Option Name" value={o.subtaskAction} error={error?.subtaskAction} touched={touched} onChange={obj.formik.handleChange} className="custom-app-input" />

							<AppSelectInput className="app-select-input app-select-input--input" required pagination searchable={false} type="text" name={`options[${i}].configInputId`} label={inputLabel} placeholder="Enter Input" loadOptions={getServiceInputListing} value={o.configInputId} error={error?.configInputId} touched={touched} onChange={obj.formik.handleChange} />

							{obj.options.length > 1 && (
								<button type="button" className={trashClassNames} disabled={obj.restricted} onClick={() => obj.remove(i)}>
									<img src={trashIcon} alt="trash-icon" />
								</button>
							)}
						</div>
					);
				})}
			</div>
		);
	}, []);

	useImperativeHandle(ref, () => ({
		onHandleShow: onHandleShow,
		onHandleDismiss: onHandleDismiss
	}));

	return (
		<Modal classes={{ root: "app-customer-edit-asset-create-edit-service-modal" }} open={visible}>
			<FormikProvider value={formik}>
				<div className="customer-edit-asset-create-edit-service-modal">
					<h1 className="customer-edit-asset-create-edit-service-modal__title">New Service</h1>

					<FieldArray
						name="options"
						render={(f) => {
							const values = f.form.values?.options;
							const errors = f.form.errors?.options;
							const touched = f.form.touched?.options;

							return (
								<form className="customer-edit-asset-create-edit-service-modal__form" onSubmit={formik.handleSubmit}>
									<div className="customer-edit-asset-create-edit-service-modal__wrapper">
										<div className="customer-edit-asset-create-edit-service-modal__row">
											{/* prettier-ignore */}
											<AppSelectInput required name="serviceType" label="Service Type" placeholder="Select..." loadOptions={getServiceTypeListing} value={formik.values.serviceType} error={formik.errors.serviceType} touched={formik.touched.serviceType} onChange={formik.handleChange} />

											{/* prettier-ignore */}
											<AppSelectInput disabled name="status" label="Status" placeholder="Select..." loadOptions={getServiceStatusListing} value={STATUS.IN_USE} onChange={formik.handleChange} />
										</div>

										<div className="customer-edit-asset-create-edit-service-modal__row">
											{/* prettier-ignore */}
											<AppSelectInput required pagination name="configInputId" label="Input" placeholder="Select..." loadOptions={getServiceInputListing} value={formik.values.configInputId} error={formik.errors.configInputId} touched={formik.touched.configInputId} onChange={formik.handleChange} />
										</div>

										{/* prettier-ignore */}
										<AppInput required type="text" name="serviceAction" label="Service Action" placeholder="Enter Service Action" value={formik.values.serviceAction} error={formik.errors.serviceAction} touched={formik.touched.serviceAction} onChange={formik.handleChange} />

										{/* prettier-ignore */}
										<AppInput multiline type="textarea" name="remark" label="Service Remarks" placeholder="Enter Service Remarks" value={formik.values.remark} error={formik.errors.remark} touched={formik.touched.remark} onChange={formik.handleChange} />

										{/* prettier-ignore */}
										<InputOptions restricted={restricted} optionSequenceList={optionSequenceList} formik={formik} options={values} optionsErrors={errors} optionsTouched={touched} remove={f.remove} onHandleOptionSequence={onHandleOptionSequence} />
									</div>

									<div className="customer-edit-asset-create-edit-service-modal__add-service-button">
										<div className={addButtonClassNames}>
											<AppButton outline type="button" label="Add" icon={buttonIcon} disabled={restricted} onClick={() => onHandleAddOption(f)} />
										</div>
									</div>

									<div className="customer-edit-asset-create-edit-service-modal__button-container">
										<AppButton outline type="button" label="Cancel" onClick={onHandleDismiss} />

										<AppButton type="submit" label={submitLabel} disabled={formik.isSubmitting || restricted} />
									</div>
								</form>
							);
						}}
					/>
				</div>
			</FormikProvider>
		</Modal>
	);
};

export default memo(forwardRef(AppCustomerEditAssetCreateEditServiceModal));

AppCustomerEditAssetCreateEditServiceModal.propTypes = {
	ref: PropTypes.object
};
